import React, { useState, useEffect, useLayoutEffect } from 'react';
import { Text, Button, Avatar, Divider, Card, ListMenu, SingleValue, Tags } from '../../common';
import { Dropdown, Radio } from '../../components/form';
import styled from '@emotion/styled';
import GreenDropdown from '../../assets/images/green-dropdown-thin.svg';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_SENDINVITATION } from '../../constants/invite-recruit/SendInviteConstant';
import { REQUEST_SEND_REJECT } from '../../constants/invite-recruit/SendRejectionConstant';
import actions from '../../actions';
import { CONFIRM_AND_ASSIGN } from '../../constants/invite-recruit/ConfirmAndAssignConstants';
import { CONFIRM_RESPONDENT } from '../../constants/invite-recruit/ConfirmRespondentConstants';
import { REASSIGN_PROJECT_GROUP } from '../../constants/invite-recruit/ReassignProjectGroupsConstants';
import { useParams } from 'react-router-dom';
import { REQUEST_RECRUITSGROUPDATA } from '../../constants/invite-recruit/RecruitsGroupConstant';
import { REQUEST_INVITEE_DATA } from '../../constants/invite-recruit/GetInviteeConstants';
import ViewImage from '../../views/analysis/ViewImage';
import ViewAudio from '../../views/analysis/ViewAudio';
import ViewVideo from '../../views/analysis/ViewVideo';

const ButtonBase = styled(Button)`
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  color: ${props => props.color};
`;

const DivContainer = styled.div`
  padding: 1.8rem 2.75rem 0rem 2.75rem;
`;

const ProfileContainer = styled.div`
  max-height: 440.26px;
  transition: 3s;
  .scrollable {
    overflow-y: auto;
    height: 220px;
    min-height: 255px;
    // max-height: 435px;
    scrollbar-width: thin;
  }
`;

const QuestionsWrapp = styled.div`
  max-height: 408.84px;
  transition: 3s;
  .scrollable {
    overflow-y: auto;
    max-height: 400px;
    scrollbar-width: thin;
  }
`;

const SeeButton = styled(Button)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  line-height: 1;

  .reverse {
    transform: rotate(-180deg);
  }
`;

const SeeLess = ({ title, width, className }) => (
  <React.Fragment>
    {title} <img src={GreenDropdown} className={`reverse ${className}`} width={width} />
  </React.Fragment>
);

const SeeMore = ({ title, width, className }) => (
  <React.Fragment>
    {title} <img src={GreenDropdown} className={className} width={width} />
  </React.Fragment>
);

export function ProfileBuilder({
  stage,
  appliedStage,
  RecruitsGroupwiseData,
  groupIdChange,
  projectgroup,
  SendInviteData,
  ConfirmAssignData,
  RejectedData,
  ReassignprojectgroupData
}) {
  const { criteriaAndBudget } = useSelector(state => state.ProjectReducer);
  const [seeMore, setSeeMore] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [getCheckedState, SetCheckedState] = useState([]);
  const [userObj, setUserObj] = useState();
  const [groupTags, setGroupTags] = useState();
  const [projectGroupId, setProjectGroupId] = useState();
  const dispatch = useDispatch();
  const [reassignData, setReassignData] = useState([]);
  let tags = [];
  const rejectSend = useSelector(state => state.SendRejectReducer.RejectedData);
  const userId = useSelector(state => state.LoginReducer.userId);
  const assignResponse = useSelector(state => state.ConfirmAndAssignReducer.ConfirmAssignData);
  const reassignResponse = useSelector(state => state.ReassignProjectGroupsReducer.ReassignprojectgroupData);
  const [isVisible, setIsVisible] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);
  const [reAssignVisible, setReAssignVisible] = useState(false);
  const [isListEmpty, setIsListEmpty] = useState(true);

  const { projectId } = useParams();

  const ReferallCodeDropdownOptions = useSelector(state => state.RecruitsGroupReducer?.masterReferralCodes || []);
  const screenerResponseData = useSelector(state => state.RecruitScreener);

  const UserOptions = projectgroup.map(item => {
    return {
      label: item.title,
      group: item.groupTag,
      id: item.id
    };
  });

  const CodeDropdown = ReferallCodeDropdownOptions.map(code => {
    return {
      label: code,
      value: code,
      id: code
    };
  });

  const [userList, setUserlist] = useState(UserOptions);

  const [referallCodeList, setReferallCodeList] = useState(CodeDropdown);

  const [reassignGroupDefaultValue, setReassignGroupDefaultValue] = useState(UserOptions[0]);
  const [updatedMessage, setupdatedMessage] = useState('');

  const [updatedReferralCodes, setUpdatedReferralCodes] = useState(
    Array.isArray(RecruitsGroupwiseData)
      ? RecruitsGroupwiseData.reduce((acc, item) => {
          acc[item.user_ID] = item.referralCode;
          return acc;
        }, {})
      : {}
  );

  const updatedOptions = UserOptions?.map(item => {
    item.content = item.label;
    item.label = (
      <div className="d-flex flex-column">
        <Text type="4" fontSize="14px" className="d-flex flex-column">
          {item.group}
          <Text span fontSize="10px" fontWeight="400">
            {item.label}
          </Text>
        </Text>
      </div>
    );

    return item;
  });

  const updatedDropdown = CodeDropdown?.map(item => {
    item.content = item.label;
    item.label = (
      <div className="d-flex flex-column">
        <Text span fontSize="10px" fontWeight="400">
          {item.label}
        </Text>
      </div>
    );

    return item;
  });
  useEffect(() => {}, [screenerResponseData]);

  useEffect(() => {
    setUserlist(updatedOptions);
    setReferallCodeList(updatedDropdown);

    projectgroup.map(item => {
      let newTag = [];
      newTag.push(item.project_ID);
      newTag.push(item.id);
      newTag.push(item.classificationName);
      newTag.push(item.centerName);
      newTag.push(item.genderDescription);
      if (item.ageGroupLowerLimit != null || item.ageGroupUpperLimit != null) {
        newTag.push(item.ageGroupLowerLimit + ' - ' + item.ageGroupUpperLimit);
      }
      newTag.push(item.title);
      item.selectedOption.map(item1 => {
        newTag.push(item1.choiceName);
      });
      tags.push(newTag);
    });
    const [, groupId] = tags[0];
    groupIdChange(groupId);
    setProjectGroupId(String(groupId));
    setGroupTags(tags[0]);
  }, []);

  const sendInvite = event => {
    const itemList = Object.keys(isChecked).map(key => {
      if (isChecked[key] === true) {
        return key;
      }
    });
    let List = '';
    List = itemList.filter(elm => elm);
    if (stage == 1) {
      stage = 'invite';
    } else if (stage == 2) {
      stage = 'applied_all';
    } else if (stage == 3) {
      stage = 'confirmed';
    } else if (stage == 4) {
      stage = 'rejected';
    } else {
      stage = 'invite';
    }

    dispatch({
      type: REQUEST_SENDINVITATION,
      payload: {
        user_ID: userId,
        projectGroup_ID: projectGroupId,
        project_ID: projectId,
        respondentUser_ID: List
      }
    });
    const message = List.length + ' Invites Sent';
    setTimeout(
      /*Here it is in settimeout because it takes sometime to update values and the fetch*/
      () => (SendInviteData == 'success' ? setupdatedMessage(message) : setupdatedMessage(SendInviteData)),
      400
    );
    setTimeout(
      /*Here it is in settimeout because it takes sometime to update values and the fetch*/
      () =>
        dispatch({
          type: REQUEST_INVITEE_DATA,
          payload: {
            user_ID: userId,
            projectGroup_ID: projectGroupId,
            project_ID: projectId,
            type: 'invite'
          }
        }),
      500
    );
    setIsChecked(false);
  };

  const handleChange = event => {
    setIsChecked({ ...isChecked, [event.target.value]: event.target.checked });
    console.log(isChecked);
  };

  const confirmAndAssignGroup = event => {
    let changestage;
    if (appliedStage == 1) {
      changestage = 'applied_all';
    } else if (appliedStage == 2) {
      changestage = 'applied_invited';
    } else if (appliedStage == 3) {
      changestage = 'applied_directly';
    }

    const itemList = Object.keys(isChecked).map(key => {
      if (isChecked[key] === true) {
        return key;
      }
    });

    let List = '';
    List = itemList.filter(elm => elm);

    if (stage === 2 && appliedStage && isChecked) {
      dispatch({
        type: CONFIRM_AND_ASSIGN,
        payload: {
          user_ID: userId,
          projectGroup_ID: projectGroupId,
          respondentUser_ID: List,
          project_ID: projectId,
          referralCode: List.reduce((acc, userId) => {
            acc.push({ [userId]: updatedReferralCodes[userId] });
            return acc;
          }, [])
        }
      });
      let grouparrayname = '';
      let grouptitle = '';
      const groupname = UserOptions?.map(item => {
        if (item.id == projectGroupId) {
          grouparrayname = item.group;
          grouptitle = item.content;
          return item;
        }
      });
      if (List.length != 0) {
        setIsListEmpty(false);
      } else setIsListEmpty(true);
      const message = List.length + ' Respondents Confirmed' + '</br>' + grouparrayname + ' ' + grouptitle;
      setTimeout(
        /*Here it is in settimeout because it takes sometime to update values and the fetch*/
        () => (ConfirmAssignData == 'success' ? setupdatedMessage(message) : setupdatedMessage(ConfirmAssignData)),
        400
      );

      setTimeout(
        /*Here it is in settimeout because it takes sometime to update values and the fetch*/
        () =>
          dispatch({
            type: REQUEST_RECRUITSGROUPDATA,
            payload: {
              user_ID: userId,
              projectGroup_ID: projectGroupId,
              project_ID: projectId,
              type: changestage
            }
          }),
        500
      );
      setIsChecked({});
    } else if (stage === 4) {
      stage = 'rejected';
      dispatch({
        type: CONFIRM_AND_ASSIGN,
        payload: {
          user_ID: userId,
          projectGroup_ID: projectGroupId,
          respondentUser_ID: List,
          project_ID: projectId,
          referralCode: List.reduce((acc, userId) => {
            acc.push({ [userId]: updatedReferralCodes[userId] });
            return acc;
          }, [])
        }
      });

      let grouparrayname = '';
      let grouptitle = '';
      const groupname = UserOptions?.map(item => {
        if (item.id == projectGroupId) {
          grouparrayname = item.group;
          grouptitle = item.content;
          return item;
        }
      });
      if (List.length != 0) {
        setIsListEmpty(false);
      } else setIsListEmpty(true);
      const message = List.length + ' Respondents Confirmed ' + '</br>' + grouparrayname + ' ' + grouptitle;
      setTimeout(
        /*Here it is in settimeout because it takes sometime to update values and the fetch*/
        () => (ConfirmAssignData == 'success' ? setupdatedMessage(message) : setupdatedMessage(ConfirmAssignData)),
        400
      );

      setTimeout(
        /*Here it is in settimeout because it takes sometime to update values and the fetch*/
        () =>
          dispatch({
            type: REQUEST_RECRUITSGROUPDATA,
            payload: {
              user_ID: userId,
              projectGroup_ID: projectGroupId,
              project_ID: projectId,
              type: stage
            }
          }),
        500
      );
      setIsChecked(false);
    }
  };
  const fadeOut = () => {
    setIsVisible(true);
    setTimeout(() => {
      setIsVisible(false);
      dispatch({ type: 'RESET_CONFIRM_AND_ASSIGN_RESPONSE' });
    }, 2000);
  };
  const RejectfadeOut = () => {
    setRejectVisible(true);
    setTimeout(() => {
      setRejectVisible(false);
    }, 2000);
  };
  const ReassignfadeOut = () => {
    setReAssignVisible(true);
    setTimeout(() => {
      setReAssignVisible(false);
    }, 2000);
  };
  const rejectRespondent = event => {
    let changestage;
    if (appliedStage == 1) {
      changestage = 'applied_all';
    } else if (appliedStage == 2) {
      changestage = 'applied_invited';
    } else if (appliedStage == 3) {
      changestage = 'applied_directly';
    }

    const itemList = Object.keys(isChecked).map(key => {
      if (isChecked[key] === true) {
        return key;
      }
    });
    let List = '';
    List = itemList.filter(elm => elm);
    if (stage === 2 && appliedStage) {
      dispatch({
        type: REQUEST_SEND_REJECT,
        payload: {
          user_ID: userId,
          respondentUser_ID: List,
          project_ID: projectId
        }
      });
      let grouparrayname = '';
      let grouptitle = '';
      const groupname = UserOptions?.map(item => {
        if (item.id == projectGroupId) {
          grouparrayname = item.group;
          grouptitle = item.content;
          return item;
        }
      });

      const message = List.length + ' Respondents Accepted and Assigned ' + '</br>' + grouparrayname + ' ' + grouptitle;
      setTimeout(
        /*Here it is in settimeout because it takes sometime to update values and the fetch*/
        () => (RejectedData == 'success' ? setupdatedMessage(message) : setupdatedMessage(RejectedData)),
        400
      );

      setTimeout(
        /*Here it is in settimeout because it takes sometime to update values and the fetch*/
        () =>
          dispatch({
            type: REQUEST_RECRUITSGROUPDATA,
            payload: {
              user_ID: userId,
              projectGroup_ID: projectGroupId,
              project_ID: projectId,
              type: changestage
            }
          }),
        500
      );
      setIsChecked(false);
    } else {
      if (stage == 1) {
        stage = 'invite';
      } else if (stage == 2) {
        stage = 'applied_all';
      } else if (stage == 3) {
        stage = 'confirmed';
      } else if (stage == 4) {
        stage = 'rejected';
      } else {
        stage = 'invite';
      }

      dispatch({
        type: REQUEST_SEND_REJECT,
        payload: {
          user_ID: userId,
          respondentUser_ID: List,
          project_ID: projectId
        }
      });
      let grouparrayname = '';
      let grouptitle = '';
      const groupname = UserOptions?.map(item => {
        if (item.id == projectGroupId) {
          grouparrayname = item.group;
          grouptitle = item.content;
          return item;
        }
      });

      const message = List.length + ' Respondents Accepted and Assigned ' + '</br>' + grouparrayname + ' ' + grouptitle;
      setTimeout(
        /*Here it is in settimeout because it takes sometime to update values and the fetch*/
        () => (RejectedData == 'success' ? setupdatedMessage(message) : setupdatedMessage(RejectedData)),
        400
      );

      setTimeout(
        /*Here it is in settimeout because it takes sometime to update values and the fetch*/
        () =>
          dispatch({
            type: REQUEST_RECRUITSGROUPDATA,
            payload: {
              user_ID: userId,
              projectGroup_ID: projectGroupId,
              project_ID: projectId,
              type: stage
            }
          }),
        500
      );
      setIsChecked(false);
    }
  };

  const reassignGroup = event => {
    const itemList = Object.keys(isChecked).map(key => {
      if (isChecked[key] === true) {
        return key;
      }
    });
    let List = '';
    List = itemList.filter(elm => elm);
    let reassignObj = reassignData.reduce((acc, val) => {
      let [userId, groupId] = Object.entries(val)[0];
      if (List.includes(userId)) {
        acc[userId] = String(groupId);
      }
      return acc;
    }, {});

    let UserList = '';
    UserList = Object.keys(reassignObj);
    let UserGroup = Object.values(reassignObj);

    if (!UserGroup || UserGroup.length === 0) {
      UserGroup = Array(List.length).fill(null);
    }

    if (stage == 1) {
      stage = 'invite';
    } else if (stage == 2) {
      stage = 'applied_all';
    } else if (stage == 3) {
      stage = 'confirmed';
    } else if (stage == 4) {
      stage = 'rejected';
    } else {
      stage = 'invite';
    }

    const payloadUserGroup = itemList.map(userId => {
      const groupId = reassignObj[userId];
      const referralCode = updatedReferralCodes[userId];

      // Set projectGroup_ID to null if UserGroup is empty
      if (!groupId && referralCode) {
        return null;
      }

      return groupId || null;
    });

    const payloadReferralCode = itemList.map(userId => {
      return updatedReferralCodes[userId] || null; // Set to null if no referral code
    });

    dispatch({
      type: REASSIGN_PROJECT_GROUP,
      payload: {
        user_ID: userId,
        respondentUser_ID: List,
        projectGroup_ID: payloadUserGroup,
        project_ID: projectId,
        referralCode: payloadReferralCode
      }
    });
    let grouparrayname = '';
    let grouptitle = '';
    const groupname = UserOptions?.map(item => {
      if (item.id == UserGroup[0]) {
        grouparrayname = item.group;
        grouptitle = item.content;
        return item;
      }
    });

    const message = UserList.length + ' Respondents Reassigned ' + '</br>' + grouparrayname + ' ' + grouptitle;
    setTimeout(
      /*Here it is in settimeout because it takes sometime to update values and the fetch*/
      () =>
        ReassignprojectgroupData == 'success'
          ? setupdatedMessage(message)
          : setupdatedMessage(ReassignprojectgroupData),
      400
    );

    setTimeout(
      /*Here it is in settimeout because it takes sometime to update values and the fetch*/
      () =>
        dispatch({
          type: REQUEST_RECRUITSGROUPDATA,
          payload: {
            user_ID: userId,
            projectGroup_ID: projectGroupId,
            project_ID: projectId,
            type: stage
          }
        }),
      500
    );
    setIsChecked(false);
  };

  const checkAnswer = (answers, matchedAnswer) => {
    let val = false;
    answers.forEach(item => {
      if (item.choiceName === matchedAnswer.choiceName) {
        val = true;
      }
    });
    return val;
  };

  const getQuestionChoice = (data, answers) => {
    return (
      <div className="d-flex justify-content-start ">
        {data.map((val, index) => (
          <div className="d-flex flex-row justify-content-start mt-3 mr-3" key={index}>
            <Radio fontSize="14px" checked={checkAnswer(answers, val)}>
              {val.choiceName}
            </Radio>
          </div>
        ))}
      </div>
    );
  };

  const LikerTQues = ({ val }) => {
    return (
      <div
        className="d-flex flex-column justify-content-start mt-3 mr-3"
        style={{
          background: 'rgb(242, 244, 244)',
          marginBottom: '2%',
          padding: '1%',
          marginTop: '1%',
          borderRadius: '5px',
          display: 'inline-block',
          maxWidth: '100%'
        }}
      >
        {val.questionAnswer_array?.choiceName_array && val.questionAnswer_array.choiceName_array.length > 0 ? (
          val.questionAnswer_array.choiceName_array.map((answer, index) => (
            <Text type="4" fontSize="12px" fontWeight="600" key={index}>
              {answer.choiceName} : {answer.questionLikertScaleName}
            </Text>
          ))
        ) : (
          <Text>No Answer Found!</Text>
        )}
      </div>
    );
  };

  const Img = ({ val }) => {
    return val.questionAnswer_array?.length > 0 && val.questionAnswer_array[0]?.preAssingedImageUrl ? (
      <ViewImage imgLink={val.questionAnswer_array[0]?.preAssingedImageUrl} />
    ) : (
      <div
        style={{
          background: 'rgb(242, 244, 244)',
          marginBottom: '2%',
          padding: '1%',
          marginTop: '1%',
          borderRadius: '5px'
        }}
      >
        <Text>No Image Found!</Text>
      </div>
    );
  };

  const Audio = ({ val }) => {
    return val?.questionAnswer_array[0]?.preAssingedImageUrl ? (
      <ViewAudio imgLink={val.questionAnswer_array[0]?.preAssingedImageUrl} />
    ) : (
      <div
        style={{
          background: 'rgb(242, 244, 244)',
          marginBottom: '2%',
          padding: '1%',
          marginTop: '1%',
          borderRadius: '5px'
        }}
      >
        <Text>No Audio Found!</Text>
      </div>
    );
  };

  const Video = ({ val }) => {
    return val?.questionAnswer_array[0]?.preAssingedImageUrl ? (
      <ViewVideo imgLink={val.questionAnswer_array[0]?.preAssingedImageUrl} proportion={3} />
    ) : (
      <div
        style={{
          background: 'rgb(242, 244, 244)',
          marginBottom: '2%',
          padding: '1%',
          marginTop: '1%',
          borderRadius: '5px'
        }}
      >
        <Text>No Video Found!</Text>
      </div>
    );
  };

  const [seeMore5, setSeeMore5] = useState(false);
  const [seeMore6, setSeeMore6] = useState(false);

  const toggleSeeMore5 = () => {
    setSeeMore5(!seeMore5);
  };

  const toggleSeeMore6 = () => {
    setSeeMore6(!seeMore6);
  };

  const renderAnswerText = val => {
    const answerText = val.questionAnswer_array?.length > 0 && val.questionAnswer_array[0]?.answerText;
    const maxLength = 250;

    if (!answerText) {
      return <Text>No Answer Found!</Text>;
    }

    if (
      (val.questionType_ID === 5 && seeMore5) ||
      (val.questionType_ID === 6 && seeMore6) ||
      answerText.length <= maxLength
    ) {
      return answerText;
    } else {
      return answerText.slice(0, maxLength) + '...';
    }
  };

  const LongAnswer = ({ val }) => {
    return (
      <div
        style={{
          background: 'rgb(242, 244, 244)',
          marginBottom: '2%',
          padding: '1%',
          marginTop: '1%',
          borderRadius: '5px',
          maxHeight: '200px',
          overflowY: 'auto',
          //  display: 'inline-block',
          maxWidth: '100%',
          scrollbarWidth: 'none',
          '-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        {renderAnswerText(val)}
        {val.questionAnswer_array?.choiceName_array?.length > 0 &&
          val.questionAnswer_array?.choiceName_array[0]?.answerText?.length > 250 && (
            <SeeButton
              style={{ display: 'inline-block' }}
              className="mt-0"
              simple
              color="var(--primaryColor)"
              fontSize="0.875rem"
              fontWeight="500"
              onClick={toggleSeeMore5}
            >
              {seeMore5 ? 'See Less' : 'See More'}
            </SeeButton>
          )}
      </div>
    );
  };

  const ShortAnswer = ({ val }) => {
    return (
      <div
        style={{
          background: 'rgb(242, 244, 244)',
          marginBottom: '2%',
          padding: '1%',
          marginTop: '1%',
          borderRadius: '5px',
          maxHeight: '200px',
          overflowY: 'auto',
          //   display: 'inline-block',
          maxWidth: '100%',
          scrollbarWidth: 'none',
          '-webkit-scrollbar': {
            display: 'none'
          }
        }}
      >
        {renderAnswerText(val)}

        {val.questionAnswer_array?.length > 0 && val.questionAnswer_array[0]?.answerText?.length > 250 && (
          <SeeButton
            style={{ display: 'inline-block' }}
            className="mt-0"
            simple
            color="var(--primaryColor)"
            fontSize="0.875rem"
            fontWeight="500"
            onClick={toggleSeeMore6}
          >
            {seeMore6 ? 'See Less' : 'See More'}
          </SeeButton>
        )}
      </div>
    );
  };

  const SingleSeven = ({ data, answers }) => {
    return (
      <div className="d-flex justify-content-start  ">
        {data.map((val, index) => (
          <div className="d-flex flex-row justify-content-start mt-3 mr-3 flex-wrap " key={index}>
            <Radio fontSize="12px" cursor={'default'} checked={checkAnswer(answers, val)}>
              {val.choiceName}
            </Radio>
          </div>
        ))}
      </div>
    );
  };

  const ValueType = ({ val }) => {
    return (
      <div
        style={{
          background: 'rgb(242, 244, 244)',
          marginBottom: '2%',
          padding: '1%',
          marginTop: '1%',
          borderRadius: '5px'
        }}
      >
        <Text>
          {' '}
          {(val.questionAnswer_array?.length > 0 && val.questionAnswer_array[0]?.value) || 'No Answer Found!'}
        </Text>
      </div>
    );
  };

  const SliderQues = ({ val }) => {
    const ansText = val.questionAnswer_array.choiceName_array[0]?.answerText ;
    return (
      <div
        style={{
          background: 'rgb(242, 244, 244)',
          marginBottom: '2%',
          padding: '1%',
          marginTop: '1%',
          borderRadius: '5px'
        }}
      >
        <Text>
          {' '}
          {(ansText != null ? ansText : 'No Answer Found!')}
        </Text>
      </div>
    )
  }

  const RenderQuestion = ({ val }) => {
    switch (val.questionType_ID) {
      case 3:
        return <SliderQues val={val} />;
      case 4:
        return <LikerTQues val={val} />;

      case 5:
        return <LongAnswer val={val} />;

      case 6:
        return <ShortAnswer val={val} />;

      case 7:
        return <SingleSeven data={val.choiceName_array} answers={val.questionAnswer_array.choiceName_array} />;
      // case 1:
      //   return <QuestionTypeOne val={val} />;
      // case 2:
      //   return <QuestionTypeTwo val={val} />;
      case 8:
        return <Img val={val} />;

      case 11:
        return <ValueType val={val} />;

      case 12:
        return <Audio val={val} />;

      case 13:
        return <Video val={val} />;

      default:
        return getQuestionChoice(val.choiceName_array, val.questionAnswer_array.choiceName_array);
    }
  };

  const questionsData = data => {
    return (
      <>
        {data?.map(
          (val, index) => (
            console.log('VALUE', val),
            (
              <QuestionsWrapp key={index}>
                <div className="container">
                  <div className="row">
                    <div className="col-md-12 p-0">
                      <Card padding="1rem" className="mt-3 card-border" withBorder="true">
                        <div className="pl-4">
                          <Text type="4" fontSize="12px" fontWeight="700">
                            {val.questionName}
                          </Text>
                          <RenderQuestion val={val} />
                        </div>
                      </Card>
                    </div>
                  </div>
                </div>
              </QuestionsWrapp>
            )
          )
        )}
      </>
    );
  };
  const getRecruitScreenerData = ({ data }) => {
    return (
      <>
        {data?.map((val, index) => (
          <Card className="card-border mt-3" key={index}>
            <div className={`section-container`}>
              <div className="row">
                <div className="col-md-1 p-0">
                  <Text type="5" fontSize="12px">
                    {index + 1}
                  </Text>
                </div>
                <div className="col-md-3">
                  <Text type="4" fontSize="12px">
                    {val.sectionName}
                  </Text>
                </div>
                <div className="col-md-8">
                  <Text type="5" fontSize="10px" color="var(--darkGrey)">
                    {/* {val.sectionDescription} */}
                  </Text>
                </div>
              </div>
            </div>
            {questionsData(val.questionName_array)}
          </Card>
        ))}
      </>
    );
  };
  const getGroupTags = e => {
    projectgroup
      .filter(val => val.groupTag === e.group)
      .map(item => {
        let newTag = [];
        newTag.push(item.project_ID);
        newTag.push(item.id);
        newTag.push(item.classificationName);
        newTag.push(item.centerName);
        newTag.push(item.genderDescription);
        if (item.ageGroupLowerLimit != null || item.ageGroupUpperLimit != null) {
          newTag.push(item.ageGroupLowerLimit + ' - ' + item.ageGroupUpperLimit);
        }
        newTag.push(item.title);
        item.selectedOption.map(item1 => {
          newTag.push(item1.choiceName);
        });
        tags.push(newTag);
      });
    const [, groupId] = tags[0];
    groupIdChange(groupId);
    setProjectGroupId(String(groupId));
    setGroupTags(tags[0]);
  };

  const onGroupChange = e => {
    getGroupTags(e);
  };

  const onRespondentGroupChange = (e, userId) => {
    const data = {};
    data[userId] = e.id;
    setReassignData([...reassignData, data]);
  };

  // const onRespondentReferralcodeChange = (selectedOption, userId) => {
  //   setUpdatedReferralCodes(prevState => ({
  //     ...prevState,
  //     [userId]: selectedOption.value,
  //   }));
  // };

  const onRespondentReferralcodeChange = (selectedOption, userId) => {
    setUpdatedReferralCodes(prevState => ({
      ...prevState,
      [userId]: selectedOption.value
    }));
  };

  const getIndividualScreenerResponse = obj => {
    //console.log("Obj", obj)
    const obj_id = obj.user_ID;
    const userId1 = userId;
    const projectId1 = projectId;
    const screenerData = { user_ID: userId1, project_ID: projectId1, respondentUser_ID: obj_id };
    dispatch(actions.RecruitScreener.requestRecruitScreener(screenerData));
    setUserObj(obj);
    setSeeMore(!seeMore);
  };

  useEffect(() => {}, [reassignGroupDefaultValue]);

  return (
    <React.Fragment>
      <DivContainer>
        <div className="container">
          <div className="row">
            <div className="col-md-4 p-0 d-flex flex-column justify-content-center">
              <Text fontSize="12px" fontWeight="400" color="var(--grey20)">
                Profile Builder
              </Text>
              {projectgroup && (
                <Dropdown
                  placeholder="Select Groups"
                  Menu={ListMenu}
                  options={userList}
                  SingleValue={SingleValue}
                  defaultValue={userList[0]}
                  isSearchable={true}
                  onChange={e => {
                    onGroupChange(e);
                    setReassignGroupDefaultValue(e);
                  }}
                />
              )}
            </div>
            <div className="col-md-8">
              <div className="d-flex  flex-wrap justify-content-start">
                {groupTags ? (
                  groupTags.map((val, index) => {
                    if (val && index != 0 && index != 1)
                      return (
                        <div key={`keys-${index}`}>
                          <Tags>{val}</Tags>
                        </div>
                      );
                  })
                ) : (
                  <></>
                )}
              </div>
            </div>
            {/* <div className="col-md-1 p-0 d-flex justify-content-end align-items-center">
							<ButtonBase
								size="mid"
								fontSize="10px"
								fontWeight="500"
								width="67px"
								height="33px"
								padding="0"
							>
								View
							</ButtonBase>
						</div> */}
          </div>
        </div>
      </DivContainer>
      <hr />
      <div className="container pb-3 pr-0">
        <ProfileContainer>
          {RecruitsGroupwiseData != undefined && RecruitsGroupwiseData.length < 2 ? (
            <div className="scrollable">
              {RecruitsGroupwiseData != undefined &&
                RecruitsGroupwiseData.length > 0 &&
                RecruitsGroupwiseData?.map((obj, oIndex) => (
                  <div className="container" key={obj.user_ID}>
                    <div className="row p-0 d-flex align-items-center">
                      <div className="col-md-1 col-md-5percent p-0 ">
                        <SelectBox
                          id={obj.user_ID}
                          value={obj.user_ID}
                          checked={isChecked}
                          onChange={e => handleChange(e)}
                        />
                      </div>
                      <div className={`col-md-2 p-0 flex-fill`} style={{ maxWidth: '15% !important' }}>
                        <div className="d-flex">
                          <div className="d-flex  align-items-center">
                            <Avatar name={obj.name} width="35" height="35" />
                            <div className="f-flex flex-column">
                              <Text type="4" fontSize="14px" className="pl-2">
                                {obj.name}
                              </Text>
                              {stage === 2 && appliedStage === 1 && (
                                <Text type="4" fontSize="8px" className="pl-2">
                                  {appliedStage === 1 && obj.is_invited == 0 ? 'Direct Applicant' : 'Invited Applicant'}
                                </Text>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-md-2 ${stage > 1 &&
                          'col-md-10percent'} p-0 d-flex align-items-center justify-content-between m-auto`}
                      >
                        <Divider margin="0" orientation="vertical" height="30px" width="1px" />
                        <Text type="4" fontSize={stage === 3 ? '12px' : '14px'}>
                          {obj.classificationName}
                        </Text>
                        <Divider margin="0" orientation="vertical" height="30px" width="1px" />
                      </div>
                      <div
                        className={`col-md-2 ${stage === 3 &&
                          'col-md-12percent'} p-0 d-flex align-items-center  justify-content-${
                          stage === 3 ? 'around' : 'between'
                        }  m-auto`}
                      >
                        <Text type="4" fontSize={stage === 3 ? '12px' : '14px'}>
                          {obj.centerName}
                        </Text>
                        <Divider margin="0" orientation="vertical" height="30px" width="1px" />
                      </div>
                      <div
                        className={`col-md-2  ${stage === 3 &&
                          'col-md-12percent'} p-0 d-flex align-items-center  justify-content-${
                          stage === 3 ? 'around' : 'between pl-2'
                        } m-auto`}
                      >
                        <div className="w-80 pl-2 d-flex justify-content-center">
                          <Text type="4" fontSize={stage === 3 ? '12px' : '14px'}>
                            {obj.genderDescription}
                          </Text>
                        </div>

                        <Divider margin="0" orientation="vertical" height="30px" width="1px" />
                      </div>
                      <div className="col-md-1 col-md-5percent p-0 d-flex align-items-center  justify-content-between m-auto">
                        <Text type="4" fontSize={stage === 3 ? '12px' : '14px'}>
                          {obj.age}
                        </Text>
                        {stage > 1 && <Divider margin="0" orientation="vertical" height="30px" width="1px" />}
                      </div>

                      {stage === 3 && (
                        // <div className="col-md-2  p-0 d-flex  align-items-center  justify-content-between">
                        //   <div className="d-flex">
                        //     <Text type="4" fontSize="14px" color="var(--middleGreen)">
                        //       G1
                        //     </Text>
                        //     <SeeButton
                        //       simple
                        //       color="var(--primaryColor)"
                        //       fontSize="10px"
                        //       fontWeight="500"
                        //       // onClick={() => setSeeMore(!seeMore)}
                        //       className="text-left m-0 p-0"
                        //     >
                        //       {SeeMore({
                        //         title: 'Intending buyer Sony Liv',
                        //         width: '10px',
                        //         className: 'm-0'
                        //       })}
                        //     </SeeButton>
                        //   </div>
                        // </div>\
                        <div>
                          <Dropdown
                            placeholder="Select Groups"
                            Menu={ListMenu}
                            options={userList}
                            SingleValue={SingleValue}
                            //value={reassignGroupDefaultValue}
                            defaultValue={reassignGroupDefaultValue}
                            isSearchable={true}
                            onChange={e => onRespondentGroupChange(e, obj.user_ID)}
                          />
                          {(stage === 2 || stage === 4) && (
                            <Divider margin="0" orientation="vertical" height="30px" width="1px" />
                          )}
                        </div>
                      )}

                      {stage > 1 && (
                        <Dropdown
                          defaultValue={obj.referralCode || { label: 'Not Entered', value: null }}
                          Menu={ListMenu}
                          options={CodeDropdown.value}
                          SingleValue={SingleValue}
                          isSearchable={false}
                          onChange={selectedOption => onRespondentReferralcodeChange(selectedOption, obj.user_ID)}
                        />
                      )}


                      {stage > 1 && screenerResponseData && (
                        <div className={`col-md-2 fit-content pl-0 d-flex ${stage === 3 ? 'ml-auto' : 'mr-auto '}`}>
                          {stage === 3 && <Divider margin="1" orientation="vertical" height="30px" width="1px" />}
                          <SeeButton
                            simple
                            color="var(--primaryColor)"
                            fontSize="12px"
                            fontWeight="700"
                            onClick={() => {
                              getIndividualScreenerResponse(obj);
                            }}
                            className="m-0 "
                          >
                            {seeMore && obj.user_ID == userObj.user_ID && screenerResponseData
                              ? SeeLess({ title: 'Screener', width: '10px', className: 'ml-1' })
                              : SeeMore({ title: 'Screener', width: '12px', className: 'ml-1' })}
                          </SeeButton>
                        </div>
                      )}
                    </div>
                    {seeMore &&
                      obj.user_ID == userObj.user_ID &&
                      screenerResponseData &&
                      getRecruitScreenerData(screenerResponseData)}
                    <hr />
                  </div>
                ))}
            </div>
          ) : (
            <div className="scrollable">
              {RecruitsGroupwiseData != undefined &&
                RecruitsGroupwiseData.length > 0 &&
                RecruitsGroupwiseData?.map((obj, oIndex) => (
                  <div className="container" key={obj.user_ID}>
                    <div className="row p-0 d-flex align-items-center">
                      <div className="col-md-1 col-md-5percent p-0 ">
                        <SelectBox
                          id={obj.user_ID}
                          value={obj.user_ID}
                          checked={isChecked}
                          onChange={e => handleChange(e)}
                        />
                      </div>
                      <div className={`col-md-2 p-0 flex-fill`}>
                        <div className="d-flex">
                          <div className="d-flex  align-items-center">
                            <Avatar name={obj.name} width="35" height="35" />
                            <div className="f-flex flex-column">
                              <Text type="4" fontSize="14px" className="pl-2">
                                {obj.name}
                              </Text>
                              {stage === 2 && appliedStage === 1 && (
                                <Text type="4" fontSize="8px" className="pl-2">
                                  {appliedStage === 1 && obj.is_invited == 0 ? 'Direct Applicant' : 'Invited Applicant'}
                                </Text>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        className={`col-md-2 ${stage > 1 &&
                          'col-md-10percent'} p-0 d-flex align-items-center justify-content-between m-auto`}
                      >
                        <Divider margin="0" orientation="vertical" height="30px" width="1px" />
                        <Text type="4" fontSize={stage === 3 ? '12px' : '14px'}>
                          {obj.classificationName}
                        </Text>
                        <Divider margin="0" orientation="vertical" height="30px" width="1px" />
                      </div>
                      <div
                        className={`col-md-1 ${stage === 3 &&
                          'col-md-12percent'} p-0 d-flex align-items-center  justify-content-${
                          stage === 3 ? 'around' : 'between'
                        }  m-auto`}
                      >
                        <Text type="4" fontSize={stage === 3 ? '12px' : '14px'}>
                          {obj.centerName}
                        </Text>
                        <Divider margin="0" orientation="vertical" height="30px" width="1px" />
                      </div>
                      <div
                        className={`col-md-1  ${stage === 3 &&
                          'col-md-8percent'} p-0 d-flex align-items-center  justify-content-${
                          stage === 3 ? 'around' : 'between pl-2'
                        } m-auto`}
                      >
                        <div className="w-80  d-flex justify-content-center">
                          <Text type="4" fontSize={stage === 3 ? '12px' : '14px'}>
                            {obj.genderDescription}
                          </Text>
                        </div>
                        <Divider margin="0" orientation="vertical" height="30px" width="1px" />
                      </div>
                      <div className="col-md-1 col-md-5percent p-0 d-flex align-items-center  justify-content-between m-auto">
                        <Text type="4" fontSize={stage === 3 ? '12px' : '14px'}>
                          {obj.age}
                        </Text>
                        {stage > 1 && <Divider margin="0" orientation="vertical" height="30px" width="1px" />}
                      </div>
                      {stage === 3 && (
                        // <div className="col-md-2  p-0 d-flex  align-items-center  justify-content-between">
                        //   <div className="d-flex">
                        //     <Text type="4" fontSize="14px" color="var(--middleGreen)">
                        //       G1
                        //     </Text>
                        //     <SeeButton
                        //       simple
                        //       color="var(--primaryColor)"
                        //       fontSize="10px"
                        //       fontWeight="500"
                        //       // onClick={() => setSeeMore(!seeMore)}
                        //       className="text-left m-0 p-0"
                        //     >
                        //       {SeeMore({
                        //         title: 'Intending buyer Sony Liv',
                        //         width: '10px',
                        //         className: 'm-0'
                        //       })}
                        //     </SeeButton>
                        //   </div>
                        // </div>
                        <div>
                          <Dropdown
                            placeholder="Select Groups"
                            Menu={ListMenu}
                            options={userList}
                            SingleValue={SingleValue}
                            //  value={reassignGroupDefaultValue}
                            defaultValue={reassignGroupDefaultValue}
                            isSearchable={true}
                            onChange={e => onRespondentGroupChange(e, obj.user_ID)}
                          />
                          {(stage === 2 || stage === 4) && (
                            <Divider margin="0" orientation="vertical" height="30px" width="1px" />
                          )}
                        </div>
                      )}

                      {stage > 1 && (
                        <Dropdown
                          placeholder={obj.referralCode ? obj.referralCode : 'Not Entered'}
                          Menu={ListMenu}
                          options={referallCodeList}
                          SingleValue={SingleValue}
                          //value={reassignGroupDefaultValue}
                          defaultValue={obj.referralCode}
                          isSearchable={false}
                          onChange={selectedOption => onRespondentReferralcodeChange(selectedOption, obj.user_ID)}
                        />
                      )}

                      {stage > 1 && screenerResponseData && (
                        <div className={`col-md-2 fit-content pl-0 d-flex ${stage === 3 ? 'ml-auto' : 'mr-auto '}`}>
                          {stage === 3 && <Divider margin="1" orientation="vertical" height="30px" width="1px" />}
                          <SeeButton
                            simple
                            color="var(--primaryColor)"
                            fontSize="12px"
                            fontWeight="700"
                            onClick={() => {
                              getIndividualScreenerResponse(obj);
                            }}
                            className="m-0 "
                          >
                            {seeMore && obj.user_ID == userObj.user_ID && screenerResponseData
                              ? SeeLess({ title: 'Screener', width: '10px', className: 'ml-1' })
                              : SeeMore({ title: 'Screener', width: '12px', className: 'ml-1' })}
                          </SeeButton>
                        </div>
                      )}
                    </div>
                    {seeMore &&
                      obj.user_ID == userObj.user_ID &&
                      screenerResponseData &&
                      getRecruitScreenerData(screenerResponseData)}
                    <hr />
                  </div>
                ))}
            </div>
          )}
        </ProfileContainer>
        <div className="d-flex justify-content-center pt-4 pb-3">
          {stage === 1 && (
            <ButtonBase
              size="mid"
              fontSize="12px"
              fontWeight="500"
              width="127px"
              height="33px"
              padding="0"
              className="mt-4"
              onClick={e => sendInvite(e)}
            >
              Send Invites
            </ButtonBase>
          )}
          {(stage === 2 || stage === 4) && (
            <div className="d-flex">
              <ButtonBase
                size="mid"
                fontSize="12px"
                fontWeight="500"
                width="208px"
                height="33px"
                padding="0"
                className="mt-4 mr-4"
                onClick={() => {
                  confirmAndAssignGroup(), fadeOut();
                }}
              >
                Confirm & Assign Groups
              </ButtonBase>
              <ButtonBase
                size="mid"
                fontSize="12px"
                fontWeight="500"
                width="127px"
                height="33px"
                padding="0"
                className="mt-4"
                onClick={() => {
                  rejectRespondent(), RejectfadeOut();
                }}
                fill={false}
              >
                Reject
              </ButtonBase>
            </div>
          )}
          {stage === 3 && (
            <ButtonBase
              size="mid"
              fontSize="12px"
              fontWeight="500"
              width="127px"
              height="33px"
              padding="0"
              className="mt-4"
              onClick={() => {
                reassignGroup(), ReassignfadeOut();
              }}
            >
              Reassign Groups
            </ButtonBase>
          )}
          {console.log('assignResponse', assignResponse)}
        </div>
        <Text className="d-flex justify-content-center pt-4 pb-3">
          <Text type="2">
            {isVisible && isListEmpty ? (
              <p style={{ color: 'red' }}>Please select Respondent</p>
            ) : isVisible && assignResponse?.StatusCode == '1' ? (
              <p style={{ color: 'green' }}>Confirmed and Assigned</p>
            ) : isVisible && !isListEmpty && assignResponse?.StatusCode == '-1' ? (
              <p style={{ color: 'red' }}>{assignResponse?.StatusDesc}</p>
            ) : null}
          </Text>
          <Text type="2">
            {rejectVisible && rejectSend == '-1' ? (
              <p style={{ color: 'red' }}>Please select Respondent</p>
            ) : rejectVisible && rejectSend == '1' ? (
              <p style={{ color: 'green' }}>Rejected</p>
            ) : null}
          </Text>
          <Text type="2">
            {reAssignVisible && reassignResponse == '-1' ? (
              <p style={{ color: 'red' }}>Please select Respondent</p>
            ) : reAssignVisible && reassignResponse == '1' ? (
              <p style={{ color: 'green', marginLeft: '50rem' }}>Reassigned</p>
            ) : null}
          </Text>
        </Text>
      </div>
    </React.Fragment>
  );
}

export function SelectBox({ checked, value, id, onChange }) {
  return (
    <label className="CheckContainer">
      <input
        type="checkbox"
        checked={checked[value] !== null ? checked[value] : false}
        value={value}
        id={id}
        onChange={onChange}
      />
      <span className="checkmark" />
    </label>
  );
}
