import mixpanel from 'mixpanel-browser';

class mixPanel {
    constructor(){
        this.init = () => mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN, {debug:true});
    }

    track(event,prop){
        mixpanel.track(event, prop)
    }
    identify(data){
        mixpanel.identify(data)
    }
    register(data){
        mixpanel.register(data);
    }
}

export default new mixPanel();

