import React, { useCallback, useState, useEffect } from 'react';
import ProfileSettingWrapper, { Input } from './ProfileSettingWrapper';
import profile_pic from '../../images/profile_pic.png';
import styled from '@emotion/styled';
import { Button, Icon, Text } from '../../common';
import Cropper from 'react-easy-crop';
import getCroppedImg from './cropImage';
// import ImageCrop from '../../components/common/ImageCrop';
import { REQUEST_GETPROFILEDATA } from '../../constants/profile/GetProfileData';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_EDITPROFILEDATA } from '../../constants/profile/EditProfileData';
import avatar from '../../images/researcher.png';

const Image = styled.img`
	height: 200px;
	width: 200px;
	border-radius: 100px;
`;
const CustomLabel = styled.label`
	font-weight: 500;
	font-size: 16px;
	line-height: 19px;
	/* identical to box height */

	/* Colour/Sap Green/Text */

	color: var(--sapGreen);
	padding: 0;
	margin-bottom: 0;
	padding-bottom: 0;

	border-bottom: 1px solid var(--sapGreen);
	&:hover {
		color: var(--sapGreen);
	}
`;

const StyledButton = styled(Button)`
	width: 140px;
`;
const initialState = {
	full_name: 'Lisa M.',
	phone: '+91  9988776655',
	email: 'lisa@yourworkemail.com',
};
function ProfileScreen() {
	const [values, setValues] = useState(initialState);
	const [image_crop, setImage_crop] = useState(profile_pic);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState(1.5);
	const [croppedImage, setCroppedImage] = useState(null);
	const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
	const [GetProfileData,setGetProfileData] = useState([]);
	const [IsFlag,setIsFlag] = useState(0);
	const userId = useSelector(state => state.LoginReducer.userId);
	const dispatch = useDispatch();
	useEffect(() => {
		dispatch({
		  type: REQUEST_GETPROFILEDATA,
		  payload: {
			user_ID: userId
		  }
		});
	  }, []);

	const getGetProfileData = useSelector(state => state.GetProfileReducer.GetProfileData?.userProfileData[0]);
	useEffect(() => {
		setGetProfileData(getGetProfileData);
	},[getGetProfileData])
 
	const onCropComplete = useCallback(
		async (croppedArea, croppedAreaPixels) => {
			try {
				const croppedImage = await getCroppedImg(
					image_crop,
					croppedAreaPixels
				);

				setCroppedImage(croppedImage);
			} catch (e) {
				console.error(e);
			}
		},
		[croppedImage]
	);

	const showCroppedImage = useCallback(async () => {
		try {
			const croppedImage = await getCroppedImg(
				image_crop,
				croppedAreaPixels
			);
			console.log('donee', { croppedImage });
			setCroppedImage(croppedImage);
		} catch (e) {
			console.error(e);
		}
	}, [croppedAreaPixels]);

	const onImageChange = (event) => {
		if (event.target.files && event.target.files[0]) {
			setImage_crop(URL.createObjectURL(event.target.files[0]));
		}
	};

	const handleConfim = async (evt) => {
		let imageName = evt.target.name;
		let file = await fetch(croppedImage).then(r => r.blob()).then(blobFile => new File([blobFile], imageName, { type: "image/png" }));
		// console.log(blob)
		// var file = new File([blob], imageName);
		// console.log("file",file)
		setGetProfileData({
			...GetProfileData,
			[imageName]: croppedImage,
			"file": file
		});
	};

	const handleChnage = (evt) => {
		const value = evt.target.value;
		setGetProfileData({
			...GetProfileData,
			[evt.target.name]: value,
		});
	};

	const applyProfileChanges = () =>{
		// console.log("GetProfileData",GetProfileData);
		const formData = new FormData();
		formData.append('user_ID', GetProfileData.user_ID);
		formData.append('email', GetProfileData.email);
		formData.append('mobile', GetProfileData.mobile);
		formData.append('name', GetProfileData.name);
		formData.append('profilePicture', GetProfileData.file);
		// console.log("formData",formData);
		dispatch({
			type: REQUEST_EDITPROFILEDATA,
			payload: formData
		});
		setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
			() => dispatch({
				type: REQUEST_GETPROFILEDATA,
				payload: {
				  user_ID: userId
				}
			  }), 
			500
		  );
		
	}

	const discardProfileChanges = () =>{
		dispatch({
			type: REQUEST_GETPROFILEDATA,
			payload: {
			  user_ID: userId
			}
		  });
		  setIsFlag(1);
	}

	console.log(GetProfileData)
	return (
		<ProfileSettingWrapper>
			<div
				class='modal fade '
				id='exampleModal'
				tabindex='-1'
				role='dialog'
				aria-labelledby='exampleModalLabel'
				aria-hidden='false'
			>
				<div
					class='modal-dialog modal-lg modal-dialog-centered'
					role='document'
					style={{
						width: '620px',
					}}
				>
					<div class='modal-content' style={{ height: '300px' }}>
						<div class='modal-body'>
							<button
								type='button'
								class='close'
								data-dismiss='modal'
								aria-label='Close'
							>
								<Icon>close</Icon>
							</button>

							<div
								className='d-flex align-items-center px-3 py-4'
								style={{ position: 'relative' }}
							>
								<div
									style={{
										width: '200px',
										height: '230px',
										// background: 'red',
										position: 'relative',
									}}
									className='mr-5'
								>
									<div
										style={{
											width: '200px',
											height: '230px',
										}}
									>
										<Cropper
											cropShape='round'
											showGrid={false}
											image={image_crop}
											crop={crop}
											minZoom={0}
											zoom={zoom}
											aspect={1}
											onCropChange={setCrop}
											onCropComplete={
												onCropComplete
											}
											onZoomChange={setZoom}
											restrictPosition={false}
											cropSize={{
												width: 170,
												height: 170,
											}}
										/>
									</div>
								</div>
								<div>
									<Text
										fontSize='18px'
										fontWeight='500'
										color='var(--deepBlue)'
										className='mb-4'
									>
										Crop Profile Picture
									</Text>
									<div className='d-flex'>
										<StyledButton
											fontWeight='700'
											fontSize='13px'
											size='small'
											buttonColor='var(--sapGreen)'
											name='profileImagePath'
											onClick={handleConfim}
											data-dismiss='modal'
										>
											Confirm
										</StyledButton>
										<StyledButton
											variant='outlined'
											fontWeight='700'
											fontSize='13px'
											size='small'
											buttonColor='var(--sapGreen)'
											className='ml-2'
											data-dismiss='modal'
										>
											Cancel
										</StyledButton>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='row align-items-center'>
				<div className='col-md-6 '>
					<div className='d-flex align-items-center'>
						<Image src={GetProfileData?.presignedUrl != null ? GetProfileData?.presignedUrl : avatar} />

						<div className='mx-4'>
							<CustomLabel
								for='file-upload'
								class='custom-file-upload'
								data-toggle='modal'
								data-target='#exampleModal'
							>
								Change Profile Picture
							</CustomLabel>
							<input
								id='file-upload'
								type='file'
								style={{ display: 'none' }}
								onChange={onImageChange}
							/>

							<div className='d-flex align-items-center mt-2'>
								<Text
									fontSize='11px'
									fontWeight='300'
									color='var(--deepBlue)'
									className='mr-2'
								>
									Jpeg, Png, Jpg
								</Text>
								<Text
									fontSize='11px'
									fontWeight='300'
									color='var(--deepBlue)'
								>
									Maximum file size 5MB
								</Text>
							</div>
						</div>
					</div>
				</div>
				<div className='col-md-6'>
					<Input
						labelText='Full Name'
						name='name'
						value={GetProfileData?.name}
						onChange={handleChnage}
						setIsFlag={setIsFlag}
						IsFlag={IsFlag}
					/>
				</div>
			</div>
			<div className='row align-items-center mt-5'>
				<div className='col-md-6'>
					<Input
						labelText='Phone Number'
						name='mobile'
						value={GetProfileData?.mobile}
						showCharacters={false}
						style={{ paddingRight: '2rem' }}
						onChange={handleChnage}
						setIsFlag={setIsFlag}
						IsFlag={IsFlag}
					/>
				</div>
				<div className='col-md-6'>
					<Input
						showCharacters={false}
						labelText='Email'
						name='email'
						value={GetProfileData?.email}
						onChange={handleChnage}
						setIsFlag={setIsFlag}
						IsFlag={IsFlag}
					/>
				</div>
			</div>
			<div className='d-flex mt-5'>
				<Button
					fontSize='13px'
					fontWeight='700'
					size='small'
					buttonColor='var(--sapGreen)'
					className='mr-4'
					onClick={applyProfileChanges}
				>
					Apply Changes
				</Button>
				<Button
					fontSize='13px'
					fontWeight='700'
					size='small'
					buttonColor='var(--sapGreen)'
					variant='outlined'
					onClick={discardProfileChanges}
				>
					Discard Changes
				</Button>
			</div>
		</ProfileSettingWrapper>
	);
}

export default ProfileScreen;
