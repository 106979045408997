import React, { useEffect, useState,useSelector,useRef } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from '@emotion/styled';
import { Button, Heading, Card, Text } from '../../common';
import { FormGroup, Label, InputText, ButtonRadio } from '../../components/form';
import sign_in_background from '../../assets/images/sign_in_background.jpg';
import {REQUEST_VERIFY_OTP} from '../../constants/otpVerify/otpVerifyconstants';
import { createBrowserHistory } from 'history';
import {REQUEST_OTP} from '../../constants/otp/OtpConstants';

const SignInSection = styled.div`
  height: 100vh;
  overflow: hidden;
  position: relative;
  padding: 1rem;

  .container-fluid {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;

const BackgroundImage = styled.img`
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
`;

const SignInCard = styled(Card)`
  width: 580px;
  border: 1px solid var(--borderColor);
  padding: 1.8rem 2.75rem;
  box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.1);
`;

const StyledLabel = styled.label`
  font-size: 0.875rem;
  font-weight: 500;
  color: #898989;
`;

const SmallLinkButton = styled(Button)`
  font-size: 13px;
  padding-right: 0;
  font-weight: 500;
  color: var(--labelColor);
`;

const CodeContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 30px;
`;




const codeStyle = {
  borderRadius: "5px",
  fontSize: "30px",
  height: "60px",
  width: "50px",
  border: "1px solid #eee",
  margin: "1%",
  textAlign: "center",
  fontWeight: "300",
  MozAppearance: "textfield",
  WebkitAppearance: "none",
  marginRight: "5%"
}

export default function VerifyCode() {
  const [usertype, setUsertype] = useState('researcher');

  const [email, setEmail] = useState('');

  const [password, setPassword] = useState('');

  const [code, setCode] = useState();
  const [code1, setCode1] = useState();
  const [code2, setCode2] = useState();
  const [code3, setCode3] = useState();
  const [code4, setCode4] = useState();
  const [code5, setCode5] = useState();
  const [code6, setCode6] = useState();
  const [getStatus,setStatus] = useState();
  const [getCodeInvalid,setCodeInvalid] = useState(false);
  const [getCodeExpired,setCodeExpired]  = useState(false);
  const [CheckUser,setCheckUser] = useState(false);
  const [ValidateOTPError,setValidateOTPError] = useState(false);
  const history = createBrowserHistory({ forceRefresh: true });

  const inputRef1 = useRef(null);
  const inputRef2 = useRef(null);
  const inputRef3 = useRef(null);
  const inputRef4 = useRef(null);
  const inputRef5 = useRef(null);
  const inputRef6 = useRef(null);
  const allowkey=[8,46];
  function handleKeyDown(event,curr) {
    const allowedKeys = [8, 46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57,96,97,98,99,100,101,102,103,104,105,106];
    const allowkey=[8,46];
   if(allowkey.includes(event.keyCode)){
    
    
    curr.current.focus()
   
    console.log('hello')
    if(curr===inputRef6){
      inputRef6.current.value = '';
      inputRef5.current.focus()
    }
   }
    if (!allowedKeys.includes(event.keyCode)) {
      event.preventDefault();
    }
  }

  const handleClick = () => {
    // 👇️ clear input value
    inputRef1.current.value = '';
    inputRef2.current.value = '';
    inputRef3.current.value = '';
    inputRef4.current.value = '';
    inputRef5.current.value = '';
    inputRef6.current.value = '';
  };

  const emailEntered = useParams();
  // console.warn(emailEntered.email);
  const dispatch = useDispatch();
  useEffect(() => {
    var container = document.getElementsByClassName("code-container")[0];

    var reverse_container = document.getElementsByClassName("code-container")[-1];
    container.onkeyup = (e) => {
      var target = e.srcElement;
      var maxLength = parseInt(target.attributes["maxlength"].value, 10);
      var myLength = target.value.length;
      if (myLength >= maxLength) {
        var next = target;
        var prev = target;
        while (next = next.nextElementSibling) {
          if (next == null)
            break;
          if (next.tagName.toLowerCase() == "input") {
            console.log("hello focus")
            next.focus();
            break;
          }
        }
        // while (prev = prev.previousElementSibling) {
          
        //     if(allowkey.includes(e.keyCode)){
        //     console.log("hello focus")
        //     prev.focus();
        //     break;
        //   }
        // }
     
      
      }
    }
  }, [])

  const sendOTP = () =>{
    handleClick();
		dispatch({
			type: REQUEST_OTP,
			payload: {
			  email: emailEntered.email
			}
		  });
      setCodeInvalid(false);
      setCodeExpired(false);
      setCheckUser(false);
      setValidateOTPError(false);
	}
  const  verifyOTP = async (e) => {
    e.preventDefault();
    const otp = code1.concat(code2,code3,code4,code5,code6);
   
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
     "email": emailEntered.email,
      "otp":otp
    });

    var requestOptions = {
      method: 'POST',
      headers: myHeaders,
      body: raw
    };

     
     const xyz = fetch(`https://${process.env.REACT_APP_URL}/ValidateOtpWeb`, requestOptions)
     .then(function (response) {
      return response.json()
      
    })
    .then(function (response) {
      if(response?.Response && response.Response?.verification_Status == 'Valid'){
        history.push(`/reset-password/${emailEntered.email}`);
    }else if(response?.Response && response?.Response?.verification_Status =='Invalid'){
      setCodeInvalid(true) 
    }
    else if(response?.Response && response?.Response?.verification_Status =='expired'){
      setCodeExpired(true)
    }
    else if( response?.Response && response?.Response?.verification_Status =='Invalid_user' || response?.Status?.StatusDesc =='Invalid user'){
      setCheckUser(true)
    }
    else{
      setValidateOTPError(true)
    }
    });
  }
  
  return (
    <SignInSection className="sign-in-section">
      <BackgroundImage
        src={sign_in_background}
        alt="Maverick running on Different Mobiles"
        className="sign-in-section-img"
      />

      <div className="container-fluid">
        <SignInCard className="card sign-in-card mt-5">

          <Link to="/sign-in" className="underline" tabIndex="-1" style={{ float: "right" }}>
            <Text type="2">{'<'}Back</Text>
          </Link>
          <form className="sign-in-form">
            <Heading color="var(--seaBlue)">Verify Code</Heading>

            <div className="mt-4 mb-4">
              <p>Enter the code we sent to the email ID</p>
              <p>{emailEntered.email}</p>
            </div>



            <div className="form-group mt-4 row">

              <div className='container'>
                <CodeContainer className="code-container">
                  
                  <input type="text" ref={inputRef1} onKeyDown={(e)=>handleKeyDown(e,inputRef1)} className="code" style={codeStyle} name="1" onChange={(e)=> setCode1(e.target.value)}  placeholder="0" min="0" max="1" maxLength={1} required />
                  <input type="text" ref={inputRef2} onKeyDown={(e)=>handleKeyDown(e,inputRef1)} className="code" style={codeStyle} name="2" onChange={(e)=> setCode2(e.target.value)} placeholder="0" min="0" max="1" maxLength={1} required />
                  <input type="text" ref={inputRef3} onKeyDown={(e)=>handleKeyDown(e,inputRef2)} className="code" style={codeStyle} name="3" onChange={(e)=> setCode3(e.target.value)} placeholder="0" min="0" max="1" maxLength={1} required />
                  <input type="text" ref={inputRef4} onKeyDown={(e)=>handleKeyDown(e,inputRef3)} className="code" style={codeStyle} name="4" onChange={(e)=> setCode4(e.target.value)} placeholder="0" min="0" max="1" maxLength={1} required />
                  <input type="text" ref={inputRef5} onKeyDown={(e)=>handleKeyDown(e,inputRef4)} className="code" style={codeStyle} name="5" onChange={(e)=> setCode5(e.target.value)} placeholder="0" min="0" max="1" maxLength={1}required />
                  <input type="text" ref={inputRef6} onKeyDown={(e)=>handleKeyDown(e,inputRef6)} className="code" style={codeStyle} name="6" onChange={(e)=> setCode6(e.target.value)} placeholder="0" min="0" max="1" maxLength={1} required />
                </CodeContainer>
               
          
               {getCodeInvalid ? <p style={{color:"red"}}> Entered code is Invalid</p>: null}
               {getCodeExpired ? <p style={{color:"red"}}> Code has expired</p>: null}
               {CheckUser ? <p style={{color:"red"}}> Invalid User</p>: null}
               {ValidateOTPError ? <p style={{color:"red"}}> Please try again</p>: null}
                <Link to={`/verify-code/${emailEntered.email}`} className="underline" tabIndex="-1" style={{ float: "right" }} onClick={sendOTP}>
                  <Text type="2">Resend Code</Text>
                </Link>
              </div>
              {/* <Button type="submit" loading={false}>Sign In</Button> */}



              <div className="form-group mt-4 container" >
                {/* <Link to={`/reset-password/${emailEntered.email}`}> */}
                <Button
                  disabled={!code1 || !code2 || !code3 ||!code4 || !code5 || !code6  }
                  fontSize="1.125rem"
                  size="mid"
                  onClick={verifyOTP}
                  // onClick={() => {
                  //   localStorage.setItem('user', usertype);

                  //   dispatch({ type: 'REQUEST_USER_LOGIN', payload: { email, password } });
                  // }}
                >
                  Next
                  {getStatus  == "expired" ? <Link to={`/reset-password/${emailEntered.email}`} />:"" }
                </Button>
 
                {/* </Link> */}
                {/* <Link to="/" className="underline" tabIndex="-1" style={{ float: "right" }}>
                  <Text type="3">Not getting the code?</Text>
                </Link>  */}
                <div tabIndex="-1" style={{ float: "right", textAlign: "right" }}>
                  <Text type="2">Problem getting code? Contact us at</Text>
                  <Text className="underline">hello@explorastory.in</Text>
                </div>
              </div>
            </div>
          </form>
        </SignInCard>
      </div>
    </SignInSection>
  );
}
