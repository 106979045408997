import maverickPortalhttp from '../../webService/webService';
import maverickPortalApiEndPoint from '../../../config/apiEndPoint';
import AuthService from '../../authService/AuthService';
import { createBrowserHistory } from 'history';



const history = createBrowserHistory({ forceRefresh: true });

const  ResetPassword =  (data) => {

  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.resetPassword.url,
        maverickPortalApiEndPoint.resetPassword.methodType,
        data
      )
      .then(response => {        
        if(response.data.Status.StatusCode == 1){
          // alert('Password Changed');
          history.push('/sign-in');
					console.log(response.data.Status.StatusDesc);
					resolve({ res });
        }
				else {
          // alert('Password Not Changed');
          console.log(response.data.Status.StatusDesc);
					// console.log('else',response.data);
				}
        const res = response.data;
        resolve({ res });
        console.log(res);
      });
  });
};



export { ResetPassword };
