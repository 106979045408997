export class maverickPortalconfig {
  static appName = 'Maverick Desktop Portal';

  static description = 'Configuration for API hostName';

  static version = '0.0.1';

  static private = true;

  static apiKey = '';

  static mock = true;

  static env = [
    {
      PROD: {
        hostname: '',
        version: 'v1'
      },
      ITG: {
        hostname: '',
        version: 'v1'
      },
      STG: {
        hostname: '',
        version: 'v1'
      },
      DEV: {
        // hostname: 'https://api.app.humanifytech.com/api',
        hostname: 'https://api.dev.humanifytechnologies.com/api',
        // hostname: 'https://api.demo.humanifytech.com/api',
        // hostname: 'http://localhost:8000/api',
        version: 'v1'
      },
      MOCK: {
        token: '',
        version: '',
        cache: true
      },
      PROD_AGORA: {
        AGORA_APP_ID: '25f2fa5ccacb4847a8a2016c9545fc64', // set your app id here
        customerKey: '114afe9bb28f48e38910cb3efd6ff243',
        customerSecret: '92dae5077af14429b302d11263425050'
      },
      DEV_AGORA: {
        AGORA_APP_ID: 'b5a7270cf93e43678b068f1ea23e2146', // set your app id here
        customerKey: '428f7f3b771644f8a5ac30298c05ed6b',
        customerSecret: 'e1a0be05dd34431e9270f63fe509cf3b'
      }
    }
  ];
}
