import { putResolve } from 'redux-saga/effects';
import actions from '../../actions';
import * as AddVideoTrimMethods from '../../utils/api/field/AddVideoTrimData';

function* AddVideoTrimData(payload) {
  console.log('from saga', payload);
  const promise = AddVideoTrimMethods.AddVideoTrimData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.AddVideoTrimAction.ADDVIDEOTRIM_RECEIVED, data: res.Response });
}

export { AddVideoTrimData };
