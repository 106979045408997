import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as filterMethods from '../../utils/api/analysis/filterData';

function* filterData(payload) {
  const promise = filterMethods.filterData(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.analysisAction.FILTER_DATA_RECEIVED, data: res.Response });
}

function* deleteCustomtag(payload) {
  const promise = filterMethods.deleteCustomtag(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.analysisAction.DELETE_CUSTOMTAG_RECEIVED, data: res.Response });
}

export  { filterData, deleteCustomtag }