import React, { useState, useEffect, Component, useRef } from 'react';
import ReactPlayer from 'react-player';
import { useDispatch, useSelector } from 'react-redux';
import { Slider, Handles, Tracks } from 'react-compound-slider';
import { Handle, Track } from '../../containers/ViewClips/RangeSlider';
import Previous from '../../images/previous.png';
import Next from '../../images/next.png';
import ProjectWrapper from './ProjectWrapper';
import {
  DiscussionGuide,
  Notes,
  AddEditTags,
  EditClips,
  VideoClipTime,
  AutoCreateClips,
  ControlButton,
  Seperator
} from '../../containers';
import { InputText } from '../../components/form';
import { Heading, HelperText, Text, Card, Button, Icon } from '../../common';
import styled from '@emotion/styled';
import HandleWhite from '../../images/handle-white.png';
import HandleGreen from '../../images/handle-limegreen.png';

const ControlsContainer = styled.div`
  width: 200px;
`;

const EditClipContainer = styled.div`
  min-height: 396px;
  position: relative;
  background: var(--deepBlue);
  border-radius: 5px;
  opacity: 0.95;

  .edit-clip {
    .range-clip {
      background: var(--seaBlue);
      height: 36px;
      position: relative;
      width: 100%;
      overflow: hidden;
      .range-circle {
        height: 9px;
        position: absolute;
        bottom: 0;
        background: #54636d;
      }

      .ruler {
        position: absolute;
        bottom: -6px;
        li {
          position: relative;
          img {
            position: absolute;
            top: -8px;
            left: 3px;
            opacity: 1;
          }
        }
        li .border-rule {
          border: 1px solid #ffffff;
          margin-left: 0.5rem;
        }
        ul li:nth-of-type(5n + 1) .border-rule {
          height: 6px;
        }

        .range-selector {
          position: absolute;
          top: -16px;
          ${'' /* left:30%; */};
        }
      }
    }
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

const ButtonBase = styled(Button)`
  width: ${props => props.width || 'auto'};
  height: ${props => props.height || 'auto'};
  color: ${props => props.color};
`;

const initialState = {
  url: null,
  playing: true,
  controls: false,
  light: false,
  volume: 0.8,
  muted: false,
  played: 0,
  loaded: 0,
  duration: 0,
  playbackRate: 1.0,
  loop: true
};

export default function CallClipping() {
  const [projectTitle, setProjectTitle] = useState('My Movie Binge');
  const loggedUser = useSelector(state => state.LoginReducer);
  const leftcurl = '{';
  const rightCurl = '}';
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({ type: 'REQUEST_GETVIDEO' });
  }, []);

  const clipVideo = () => {
    console.log('curlValues', curlValues);
    const startMinutes = Math.floor(curlValues[0] / 60);
    const startSeconds = curlValues[0] - startMinutes * 60;
    const startTiming = '00:' + startMinutes + ':' + startSeconds;
    const endMinutes = Math.floor(curlValues[1] / 60);
    const endSeconds = curlValues[1] - startMinutes * 60;
    const endTiming = '00:' + endMinutes + ':' + endSeconds;
    const projectRepondentVideo_ID = '6';
    console.log('start and end timings are', startTiming, endTiming);
    dispatch({
      type: 'REQUEST_ADDVIDEOTRIM',
      payload: {
        user_ID: loggedUser.userId,
        startTime: startTiming,
        endTime: endTiming,
        projectRepondentVideo_ID: projectRepondentVideo_ID,
        clipName: 'Test'
      }
    });
  };

  const video = useSelector(state => state.GetVideoReducer.video);

  const [globalState, setGlobalState] = useState(initialState);
  const playerRef = useRef();
  const load = url => {
    setGlobalState({ ...globalState, url, played: 0, loaded: 0, pip: false });
  };

  const handlePlayPause = e => {
    setGlobalState({ ...globalState, playing: !globalState.playing, played: parseFloat(e) });
  };

  useEffect(() => {
    const url = globalState.url;
    load(url);
  }, [globalState.controls, globalState.url]);

  const handlePlay = e => {
    setGlobalState({ ...globalState, playing: true, played: parseFloat(e) });
  };

  const handleEnablePIP = () => {
    console.log('onEnablePIP');
    setGlobalState({ ...globalState, pip: true });
  };

  const handleDisablePIP = () => {
    console.log('onDisablePIP');
    setGlobalState({ ...globalState, pip: false });
  };

  const handlePause = e => {
    setGlobalState({ ...globalState, playing: false, played: parseFloat(e) });
  };

  const handleSeekMouseDown = e => {
    setGlobalState({ ...globalState, seeking: true });
    playerRef.current.seekTo(e.target.value, 'seconds');
  };

  const handleSeekChange = e => {
    setGlobalState({ ...globalState, played: parseFloat(e.target.value), seeking: true });
    playerRef.current.seekTo(e.target.value, 'seconds');
    console.log('from seekchange', e.target.value);
  };

  const handleSeekMouseUp = e => {
    setGlobalState({ ...globalState, seeking: true,played: parseFloat(e) });
    playerRef.current.seekTo(e.target.value, 'seconds');
    console.log('player ref', playerRef);
  };

  const handleClipSeekMouseUp = e => {
    console.log("seek mouse", e,playerRef.current);
    setGlobalState({ ...globalState, seeking: false,played: parseFloat(e) });
    setCurlValues(e);
     if(playerRef.current && playerRef.current.seekTo){
      playerRef.current.seekTo(e[0], 'seconds');
     }
	//The above commented code is for trim seek bar functionality
    console.log('player ref 1', playerRef);
  };

  const handleProgress = e => {
    console.log('onProgress', e);
    setGlobalState({ ...globalState, played: e.playedSeconds });
  };

  const handleDuration = duration => {
    console.log('onDuration', duration);
    setGlobalState({ duration });
  };

  const [data, setData] = useState('');
  
  const childToParent = childdata => {
    console.log("childdata",childdata);
    setData(childdata);
  }

  const {
    playing,
    light,
    volume,
    muted,
    loop,
    played,
    duration,
    playbackRate,
    pip
  } = globalState;

  const domain = [];
  const [values, setValues] = useState([200, 300]);
  const [curlValues, setCurlValues] = useState();

  return (
    <ProjectWrapper projectTitle={projectTitle} step={4} tabContainerClass="p-0">
      <Heading type="2" className="mt-4" fontWeight="600" color="var(--seaBlue)">
        In this section...
      </Heading>
      <HelperText fontSize="0.99rem">Review and clip video interviews based on timestamped tags and notes.</HelperText>

      <Heading type="5" className="mt-5" fontWeight="500" color="var(--seaBlue)">
        Clipping Interviews
      </Heading>
      <HelperText fontSize="0.99rem">
        Mark in & out to create clips . View timestamps for notes and tags as references to be efficient.
      </HelperText>
      <div className="row">
        <div className="col-md-8">
          <EditClipContainer>
            <ReactPlayer
              ref={playerRef}
              width="100%"
              height="320px"
              url={video}
              pip={pip}
              playing={playing}
              controls={false}
              light={light}
              playbackRate={playbackRate}
              loop={loop}
              volume={volume}
              muted={muted}
              onReady={() => console.log('onReady')}
              onStart={() => console.log('onStart')}
              onPlay={handlePlay}
              onEnablePIP={handleEnablePIP}
              onDisablePIP={handleDisablePIP}
              onPause={handlePause}
              onBuffer={() => console.log('onBuffer')}
              onSeek={e => console.log('onSeek', e)}
              onError={e => console.log('onError', e)}
              onDuration={handleDuration}
              onProgress={handleProgress}
              stopOnUnmount={false}/>
            <div className="edit-clip w-100">
              <div className="range-clip">
                <div className="ruler">
                  <div className="range-selector w-100">
                    <React.Fragment>
                      <Slider
                        mode={2}
                        step={1}
                        domain={[0, duration]}
                        onChange={e => handleClipSeekMouseUp(e)}
                        values={values}>

                        <Handles>
                          {({ handles, getHandleProps }) => (
                            <div className="slider-handles">
                              {handles.map((handle, index) => (
                                <Handle key={handle.id} handle={handle} domain={domain} getHandleProps={getHandleProps}>
                                  {index === 1 ? rightCurl : leftcurl}
                                </Handle>
                              ))}
                            </div>
                          )}
                        </Handles>

                        <Tracks left={false} right={false}>
                          {({ tracks, getTrackProps }) => (
                            <div className="slider-tracks">
                              {tracks.map(({ id, source, target }) => (
                                <Track key={id} source={source} target={target} getTrackProps={getTrackProps} />
                              ))}
                            </div>
                          )}
                        </Tracks>
                      </Slider>
                    </React.Fragment>
                  </div>
                  <ul className="d-flex align-items-baseline list-inline">
                    {[1, 2, 3, 5, 6, 7, 8, 9, 10, 11, 12].map((list, index) => (
                      <React.Fragment>
                        <li>
                          <div className="border-rule" />
                        </li>
                        <li>
                          <div className="border-rule" />
                        </li>
                        <li>
                          <div className="border-rule" />
                          {index == 4 && <img src={HandleGreen} style={{ zIndex: 3 }} className="cursor" />}
                        </li>
                        <li>
                          <div className="border-rule" />
                        </li>
                        <li>
                          <div className="border-rule" />{' '}
                          {index == 1 && <img src={HandleWhite} style={{ zIndex: 3 }} className="cursor" />}
                        </li>
                        <li>
                          <div className="border-rule" />
                        </li>
                        <li>
                          <div className="border-rule" />
                        </li>
                      </React.Fragment>
                    ))}
                  </ul>
                </div>
                <div className="range-circle d-flex justify-content-end align-items-center w-100">
                  <input
                    type="range"
                    class="slider w-100"
                    min={0}
                    max={duration}
                    step="any"
                    value={played}
                    onMouseDown={handleSeekMouseDown}
                    onChange={handleSeekChange}
                    onMouseUp={handleSeekMouseUp}
                  />
                </div>
              </div>
              <div className="d-flex align-items-center m-auto">
                <VideoClipTime duration={duration} played={played} />
                <AutoCreateClips />
                <div className="ml-3">
                  <ControlsContainer className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <Seperator type="4" size="19px" color="var(--white)">
                        {leftcurl}
                      </Seperator>
                      <ControlButton icon="arrow_back" />
                    </div>
                    <ControlButton img={Previous} />
                    <ControlButton icon="arrow_right" size="25px" clickFunction={handlePlayPause} />
                    <ControlButton img={Next} />
                    <div className="d-flex align-items-center">
                      <ControlButton icon="arrow_forward" />
                      <Seperator type="4" size="19px" color="var(--white)">
                        {rightCurl}
                      </Seperator>
                    </div>
                  </ControlsContainer>
                </div>
                <div className="ml-3">
                  <ButtonBase
                    fill={false}
                    width="82px"
                    height="24px"
                    fontSize="10px"
                    className="p-0"
                    onClick={clipVideo}>
                    Save Clip
                  </ButtonBase>
                </div>
              </div>
            </div>
          </EditClipContainer>
          <div>
            <AddEditTags />
          </div>
        </div>
        <div className="col-md-4">
          <DiscussionGuide />
          <div className="mt-4">
            <Notes className="mt-3 p-3" childToParent={()=> childToParent(data)}/>
            <div className="mt-2">
              <InputText
                placeholder="Enter note"
                showCharacters={false}
                showIcon={true}
                iconName="add_circle_outline"
                iconStyle="p-1 icon-input-style cursor"
                color="var(--grey20)"
                placeholderSize="12px"
                fontWeight="500"/>
            </div>
          </div>
        </div>
      </div>

      <Heading type="5" breakHeading={false} color="var(--headingColor)" className="mt-4">
        Rename & Edit{' '}
        <Text fontWeight="800" color="var(--headingColor)" span>
          Clips
        </Text>
      </Heading>
      <Text type="3" className="mb-4">
        View and Edit Respondent Video Interview Clips to analyse
      </Text>
      <Card className="mt-4">
        <Heading breakHeading={false} type="3" fontSize="16px">
          <Text span>R3- </Text>
          <Text fontWeight="700" span>
            {' '}
            Sahil R.
          </Text>
          <Text span> -Task Analysis</Text>
        </Heading>
        <div className="mt-2 w-25">
          <InputText
            placeholder="Search Clips"
            showCharacters={false}
            showIcon={true}
            iconName="search"
            iconStyle="p-1 icon-input-style-left cursor"
            color="var(--grey20)"
            placeholderSize="12px"
            positionIcon="left"
            fontWeight="500"
          />
        </div>
        <EditClips />
      </Card>

      <div className="mt-4 d-flex justify-content-center">
        <Button primary className="mt-3" size="mid" fontSize="13px">
          Save & Proceed
        </Button>
      </div>
    </ProjectWrapper>
  );
}
