import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { Heading, Button, Text, CardButton, EmptyState, Avatar, Divider, ActionIcon } from "../../../common";
import { Link } from 'react-router-dom';
import { InputText } from '../../../components/form';
import PlusWhite from '../../../assets/images/plus-white.svg';
import PlusGreen from '../../../assets/images/plus-green.svg';
import PlusGrey from '../../../assets/images/plus-grey.svg';
import GreenDropdown from '../../../assets/images/green-dropdown.svg';
import Grey1 from '../../../assets/images/709.svg';
import Grey2 from '../../../assets/images/710.svg';
import Grey3 from '../../../assets/images/711.svg';
import Grey4 from '../../../assets/images/712.svg';
import Grey5 from '../../../assets/images/713.svg';
import profile_pic from '../../../images/profile_pic.png';
import relaunch from '../../../images/relaunch.png';
import diagnostic from '../../../images/diagnostic.png';
import discovery from '../../../images/discovery.png';
import digital from '../../../images/digital.png';
import search from '../../../images/search.svg';
import filter from '../../../images/filter.svg';
import uparrow from '../../../images/uparrow.svg';
import downArrow from '../../../images/downArrow.svg';
import cross from '../../../assets/images/cross.svg';
import complete from '../../../assets/images/complete_group.svg';
import setup from '../../../assets/images/setup.svg';
import AddUserModal from "../../../common/modal/AddUserModal";
import UserDeletedModal from '../../../common/modal/UserDeletedModal';
import EditRespondantModal from '../../../common/modal/EditRespondantModal';
import EditResearcherModal from '../../../common/modal/EditResearcherModal';
import EditObserverModal from '../../../common/modal/EditObserverModal';
import EditTeamModal from '../../../common/modal/EditTeamModal';
import AddNewClientAdminModal from '../../../common/modal/super_admin_modals/AddNewClientAdminModal';
import EditClientAdminModal from '../../../common/modal/super_admin_modals/EditClientAdminModal';
import AddNewUserModal_SA from '../../../common/modal/super_admin_modals/AddNewUserModal_SA';
import EditResearcherGenPoolModal from '../../../common/modal/super_admin_modals/EditResearcherGenPoolModal';
import EditRespondantGenPoolModal from '../../../common/modal/super_admin_modals/EditRespondantGenPoolModal';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_SUPERADMINDASHBOARD_DATA } from '../../../constants/SuperAdmin/SuperAdminDashboardConstants';
import { REQUEST_USERINGENERALPOOL_DATA } from '../../../constants/SuperAdmin/ViewUserInGeneralPoolConstants';
import { REQUEST_VIEWPROJECTSUPERADMINDASHBOARD_DATA } from '../../../constants/SuperAdmin/ViewProjectSuperAdminDashboardConstants';
import analysis from '../../../assets/images/analysis.svg';
import design from '../../../assets/images/design.svg';
import field from '../../../assets/images/field.svg';
import recruit from '../../../assets/images/recruit.svg';
import recruitdesign from '../../../assets/images/recruit-design.svg';
import { REQUEST_EDITCLIENTADMINDATA } from '../../../constants/SuperAdmin/EditClientAdminConstants';
import { object } from 'prop-types';



const grey_circle = {
	marginLeft: "-10px"
}

const emailFont = {
	fontSize: "12px"
}

const datefont = {
	fontSize: "10px"
}

const ClientAdmin = styled.div`
	// position: absolute;
	width: 225px;

	right: 0px;
	// left: 0;
	top: 0px;

	/* Colour/White */

	background: #ffffff;
	/* Colour/Light Grey */

	border: 1px solid #e2e2e2;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
`;

const ClientAdminHover = styled.div`
	// position: absolute;
	width: 225px;
	height: 130px;

	right: 0px;
	// left: 0;
	top: 0px;

	/* Colour/White */

	background: #ffffff;
	/* Colour/Light Grey */

	// border: 1px solid #e2e2e2;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
`;

const SeeButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    line-height: 1;

    .reverse {
        transform: rotate(-180deg);
    }
`;


const Project = styled.div`
// position: absolute;
// width: 245px;

right: 0px;
// left: 0;
top: 0px;

/* Colour/White */

background: #ffffff;
/* Colour/Light Grey */

border: 1px solid #e2e2e2;
box-sizing: border-box;
box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
border-radius: 5px;
`;

const CustomButton = styled(Button)`
	width: ${(props) => props.width};
	height: ${(props) => props.height};
	padding: 3px 3px !important;
	// float: right;
    // margin-right: 5px;
`;

const Pill = styled.span`
	font-size: 9px;
	font-family: "Readex Pro", sans-serif;
	padding: 0.5em 1em;
	margin: 0.25em;
	// border-radius: 1em;
	// border: none;
	// outline: none;
	// background: #dddddd;
	border-radius: 20px;
	cursor: pointer;
	border: 1px solid grey;
	font-weight: bold;
`;

const TableContainer = styled.table`
	table-layout: fixed;
	border-collapse: collapse;
	border-radius: 10px;
	border-style: hidden; /* hide standard table (collapsed) border */
	box-shadow: 0 0 0 0.1px var(--greyBlue);
	.search {
		padding-left: 10px;
	}

	th:first-of-type {
		border: none;
		border-top-left-radius: 10px;
		padding-left: 2rem;
	}
	th:last-of-type {
		border: none;
		border-top-right-radius: 10px;
		border-left: 1px solid var(--grey20);
	}
	tr:last-of-type td:first-of-type {
		border-bottom-left-radius: 10px;
	}
	tr:last-of-type td:last-of-type {
		border-bottom-right-radius: 10px;
	}
	tr,
	td,
	th {
		border: 0;
		border-bottom: 0 !important;
	}
	thead {
		background: var(--greyBlue);
		color: var(--white);
	}

	th {
		font-weight: 600;
		font-size: 0.75rem;
		padding: 1rem 0;
		padding-left: 0.625rem;
		border: 0;
		border-left: 1px solid var(--grey20);
	}

	td:first-of-type {
		padding-left: 1rem;
	}

	td {
		padding: 0.675rem 0;
		padding-left: 0.625rem;
		vertical-align: middle;
	}

	button {
		font-size: 0.625rem;
		font-weight: 700;
		padding: 5px 10px;

		:disabled,
		[disabled] {
			background: transparent;
			border-color: var(--middleGrey);
			color: var(--middleGrey);

			:hover {
				box-shadow: none;
				background: #c2c2c2;
				color: #ffffff;
				cursor: not-allowed;
			}
		}
	}

	.icon {
		font-size: 1rem;
		font-weight: 100;
	}
	& > tbody > tr:nth-child(even) {
		background: var(--greyBlueLight);
	}
	& > tbody > trtr:nth-child(odd) {
		background: var(--white);
	}
`;

const Container = styled.div`
	position: absolute;
	width: 240px;

	// right: 0px;
	// left: 0;
	// top: 0px;

	/* Colour/White */

	background: #ffffff;
	/* Colour/Light Grey */

	border: 1px solid #e2e2e2;
	box-sizing: border-box;
	box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
	border-radius: 5px;
`;



const SeeLess = () => <>See less <img src={GreenDropdown} className="ml-2" /></>

const SeeMore = () => <>See more <img src={GreenDropdown} className="ml-2 reverse" /></>

export default function DashboardScreen_SA() {

	const [seeMore, setSeeMore] = useState(false);
	const [seeMoreUser, setSeeMoreUser] = useState(false);
	const [seeMoreClients, setSeeMoreClients] = useState(false);
	const [getINdexOfClientInfo, setGetINdexOfClientInfo] = useState(null);
	const [clientAdminModaldata, setClientAdminModaldata] = useState();
	const [userList, setUserList] = useState();
	const [ onGoingProject,setOngoingProject]=useState()
	const [sort, setSort] = useState(false);
	const [IsFlag, setIsFlag] = useState(0);
	const [isShown, setIsShown] = useState(false);
	const [isActive, setIsActive] = useState(false);
	const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
	// const [isEditVisible2, setIsEditVisible2] = useState(false);
	// const [isEditVisible3, setIsEditVisible3] = useState(false);
	// const [isEditVisible4, setIsEditVisible4] = useState(false);
	// const [isEditVisible5, setIsEditVisible5] = useState(false);
	const dispatch = useDispatch();
	const [userModaldata, setuserModaldata] = useState();

	useEffect(() => {
		dispatch({
			type: REQUEST_SUPERADMINDASHBOARD_DATA,
		}),
		dispatch({
			type: REQUEST_USERINGENERALPOOL_DATA,
		}),
			dispatch({
				type: REQUEST_VIEWPROJECTSUPERADMINDASHBOARD_DATA,
			})
	}, []);

	// useEffect(() => {
	// 	dispatch({
	// 		type: REQUEST_USERINGENERALPOOL_DATA,
	// 	})
	// },[])
	// useEffect(() => {
	// 	dispatch({
	// 		type: REQUEST_USERINGENERALPOOL_DATA,
	// 	})
	// },[])

	const clientAdminAccountDataDetails = useSelector(state => state.SuperAdminDashboardReducer.clientAdminAccountData.clientDetails);
	// console.warn(clientAdminAccountData);
	const clientAdminAccountDataLicence = useSelector(state => state.SuperAdminDashboardReducer.clientAdminAccountData.clientLicense);
	// console.warn(clientAdminAccountDataLicence);
	let clientAdminAccountData = clientAdminAccountDataDetails.map((item, i) => Object.assign({}, item, clientAdminAccountDataLicence[i]));
	// console.log(clientAdminAccountData);
	const userInGeneralPool = useSelector(state => state.ViewUserInGeneralPoolReducer.generalPoolUsersData);
	// console.warn(userInGeneralPool);
	const projectSuperAdminDashboard = useSelector(state => state.ViewProjectSuperAdminDashboardReducer.superAdminprojectData);
	// console.warn(projectSuperAdminDashboard);
	// const clientAdminData = useSelector(state => state.EditClientAdminReducer.editClientAdminData);
	// console.warn(clientAdminData);
	const [isEditVisible1, setIsEditVisible1] = useState(false);
	const [permission, setPermission] = useState();

	const handelModal = (clientAdminData) => {
		setClientAdminModaldata(clientAdminData);
		setIsFlag(1);
		console.log('handle change');
	}
	// console.warn(clientAdminModaldata);

	const handleOnMouseGetIndex = (index) => {
		setGetINdexOfClientInfo(index)
	}

	const handleOnMouseLeaveGetIndex = (index) => {
		setGetINdexOfClientInfo(null);
	}

	const editUserModal = (user) => {
		setuserModaldata(user);
		setIsFlag(1);
	}

	const change_permission = (evt) => {
		const value = evt.target.checked;
		console.warn(value);
		setPermission({
			...permission,
			[evt.target.name]: value
		})
	}

	// console.warn(permission);

	useEffect(() => {
		if(userInGeneralPool != null){
		  setUserList([...userInGeneralPool?.users].sort(function (a,b){
			
			let x = a.name.toUpperCase();
			let y = b.name.toUpperCase();
			if(x < y){
			  return -1;
			}
			if(x > y){
			  return 1;
			}
			return 0;
		  }));
		}
	  }, []);
	  useEffect(() => {
		if(userInGeneralPool != null){
		  setOngoingProject([...userInGeneralPool?.OngoingProject].sort(function (a,b){
			
			let x = a.name.toUpperCase();
			let y = b.name.toUpperCase();
			if(x < y){
			  return -1;
			}
			if(x > y){
			  return 1;
			}
			return 0;
		  }));
		}
	  }, []);
	  useEffect(() => {
		if(userInGeneralPool != null){
		  setUserList([...userInGeneralPool?.users].sort(function (a,b){
			
			let x = a.name.toUpperCase();
			let y = b.name.toUpperCase();
			if(x < y){
			  return -1;
			}
			if(x > y){
			  return 1;
			}
			return 0;
		  }));
		}
	  }, [userInGeneralPool]);
	  
	  useEffect(() => {
		if(userInGeneralPool != null){
			setOngoingProject([...userInGeneralPool?.OngoingProject].sort(function (a,b){
			
			let x = a.name.toUpperCase();
			let y = b.name.toUpperCase();
			if(x < y){
			  return -1;
			}
			if(x > y){
			  return 1;
			}
			return 0;
		  }));
		}
	  }, [userInGeneralPool]);
	//   console.warn(userList);
	  

	// for name sorting
	function sortList() {
		// const numDescending = [...userInGeneralPool.FieldOverview].sort((a, b) => b.Respondent_ID - a.Respondent_ID);
		// console.log(numDescending);
		// const userList = numDescending;
		if (sort === true) {
			const numAscending = [...userInGeneralPool?.OngoingProject].sort(function (a, b) {

				let x = a.name.toUpperCase();
				let y = b.name.toUpperCase();
				if (x < y) {
					return -1;
				}
				if (x > y) {
					return 1;
				}
				return 0;
			});
			setSort(false);
			setOngoingProject(numAscending);
		} else if (sort === false) {
			const numDescending = [...userInGeneralPool?.OngoingProject].sort(function (a, b) {

				let x = a.name.toUpperCase();
				let y = b.name.toUpperCase();
				if (x > y) {
					return -1;
				}
				if (x < y) {
					return 1;
				}
				return 0;
			});

			setSort(true);
			setOngoingProject(numDescending);
		}
		// console.warn(userList);
	}


	return (
		<>
			{/* Heading */}
			<div className="row">

				<div className="col-sm-8">
					<Heading >Your Super Admin Dashboard</Heading>
					<Text type="3" color="var(--ternaryColor)" className="mb-0">View your live projects, calendar and recent notifications in one place</Text>
				</div>
				{/* <div className="col-sm-4 ">
                    <div className="d-flex justify-content-end">
                        <Link to="/project/create">
                            <CardButton><img src={PlusGreen} alt="Plus Green" className="plus-green" /> <img src={PlusWhite} alt="Plus White" className="plus-white" /> New Client Admin</CardButton>
                        </Link>
                        
                    </div>
                    
                </div> */}

			</div>

			<div className='row mt-4'>

				<div className="col-sm-8 mt-2">
					<Heading >
						Client Administrators
						<img src={Grey1} alt="grey circle" className="ml-2 grey-circle" />
						<img src={Grey2} alt="grey circle" className="grey-circle" style={grey_circle} />
						<img src={Grey3} alt="grey circle" className="grey-circle" style={grey_circle} />
						<img src={Grey4} alt="grey circle" className="grey-circle" style={grey_circle} />
						<img src={Grey5} alt="grey circle" className="grey-circle" style={{ marginLeft: '-15px', paddingRight: '15px' }} />
						{clientAdminAccountData?.length == 0 ? " " : clientAdminAccountData?.length + " Accounts"}
					</Heading>
					{/* <Text type="3" color="var(--ternaryColor)" className="mb-0">View your live projects, calendar and recent notifications in one place</Text> */}
				</div>

				<div className="col-sm-4 ">
					<div className="d-flex justify-content-end">
						<div data-toggle="modal" data-target="#addNewClientAdminModal">
							<CardButton><img src={PlusGreen} alt="Plus Green" className="plus-green" /> <img src={PlusWhite} alt="Plus White" className="plus-white" /> New Client Admin</CardButton>
						</div>

					</div>
					{/* Add User Modal Start */}
					<AddNewClientAdminModal />
					{/* Add User Modal End */}
				</div>

			</div>


			<div className='row'>

				{/* Client admin list */}

				{clientAdminAccountData?.filter((clientAdmin, index) => seeMoreClients ? true : index <= 3).map((clientAdmin, index) =>

					<ClientAdmin className='px-3 mt-4 team ml-3 mr-3' onMouseEnter={() => handleOnMouseGetIndex(index)} onMouseLeave={() => handleOnMouseLeaveGetIndex()}>
						<div className='d-flex align-items-center ml-3 py-2 card1' style={{ display: 'none' }}>
							<Avatar
								src={profile_pic}
								className='profile-pic'
								width='60'
								height='60'
							/>
							<div className='row'>
								<div className='col-12'>
									<Heading
										fontSize='14px'
										fontWeight='700'
										color='var(--deepBlue)'
										className='mb-0 pb-0 ml-3'
									>
										{clientAdmin.clientsName}
									</Heading>
								</div>
								<div className='col'>
									<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}><u>{(clientAdmin.email).slice(0, 10) + ('...')}</u></Text>
								</div>
							</div>
						</div>

						{getINdexOfClientInfo == index ? <div className='cardOverlay'>
							<ClientAdminHover className='px-3 team' onMouseEnter={() => setIsEditVisible1(true)} onMouseLeave={() => setIsEditVisible1(false)}>
								<div className='container card1' style={{ paddingLeft: '0' }}>
									{/* <Avatar
										src={profile_pic}
										className='profile-pic'
										width='60'
										height='60'
									/> */}
									<div className='row'>
										<div className='col'>
											<Heading
												fontSize='10px'
												fontWeight='700'
												color='var(--deepBlue)'
												className='mb-0 pb-0 ml-2 mt-3'
											>
												{(clientAdmin.clientsName).slice(0, 12) + ('...')}
											</Heading>
										</div>
										<CustomButton
											className='ml-2 mt-2 mr-3'
											size='small'
											// variant='outlined'
											width='100px'
											height='22px'
											// style={{ color: "#798E8F", background: "none" }}
											data-toggle="modal"
											data-target="#editClientAdminModal"
											// onClick={() => { handelModal(clientAdmin) }}
											onClick={() => {
												// dispatch({
												// 	type: REQUEST_EDITCLIENTADMINDATA,
												// 	payload: clientAdmin.user_id
												// });

												handelModal(clientAdmin);
											}}
										>
											Select & Edit
										</CustomButton>
									</div>
									<div className='row mt-2' style={{ flexWrap: "inherit", display: "inline-flex" }}>
										<div className='d-flex align-items-center ml-2 py-2 col-6'>
											<Avatar
												src={profile_pic}
												className='profile-pic'
												width='20'
												height='20'
											/>
											<div className='row'>
												<div className='col'>
													<Heading
														fontSize='10px'
														fontWeight='700'
														color='var(--deepBlue)'
														className='mb-0 pb-0 ml-1'
													>
														{(clientAdmin[0]?.name)?.slice(0, 5) + ('...')}
													</Heading>
												</div>
												<div className='col'>
													<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>{(clientAdmin[0].email)?.slice(0, 10) + ('...')}</u></Text>
												</div>
											</div>
										</div>
										<div className='d-flex align-items-center py-2 col-6' style={{ paddingLeft: "0px" }}>
											<Avatar
												src={profile_pic}
												className='profile-pic'
												width='20'
												height='20'
											/>
											<div className='row'>
												<div className='col'>
													<Heading
														fontSize='10px'
														fontWeight='700'
														color='var(--deepBlue)'
														className='mb-0 pb-0 ml-1'
													>
														{(clientAdmin[1]?.name)?.slice(0, 5) + ('...')}
													</Heading>
												</div>
												<div className='col'>
													<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>{(clientAdmin[1]?.email)?.slice(0, 10) + ('...')}</u></Text>
												</div>
											</div>
										</div>
									</div>
									<div className='row' style={{ flexWrap: "inherit", display: "inline-flex" }}>
										<div className='d-flex align-items-center ml-2 py-2 col-6'>
											<Avatar
												src={profile_pic}
												className='profile-pic'
												width='20'
												height='20'
											/>
											<div className='row'>
												<div className='col'>
													<Heading
														fontSize='10px'
														fontWeight='700'
														color='var(--deepBlue)'
														className='mb-0 pb-0 ml-1'
													>
														{(clientAdmin[2]?.name)?.slice(0, 5) + ('...')}
													</Heading>
												</div>
												<div className='col'>
													<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>{(clientAdmin[2]?.email)?.slice(0, 10) + ('...')}</u></Text>
												</div>
											</div>
										</div>
										<div className='d-flex align-items-center py-2 col-6' style={{ paddingLeft: "0px" }}>
											<Avatar
												src={profile_pic}
												className='profile-pic'
												width='20'
												height='20'
											/>
											<div className='row'>
												<div className='col'>
													<Heading
														fontSize='10px'
														fontWeight='700'
														color='var(--deepBlue)'
														className='mb-0 pb-0 ml-1'
													>
														{(clientAdmin[3]?.name)?.slice(0, 5) + ('...')}
													</Heading>
												</div>
												<div className='col'>
													<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>{(clientAdmin[3]?.email)?.slice(0, 10) + ('...')}</u></Text>
												</div>
											</div>
										</div>
									</div>
								</div>

								{/* {isEditVisible4 == true ? <div className='cardOverlay'>
					</div> : null} */}
							</ClientAdminHover>
						</div> : null}


						{/* <Divider
				margin='0'
				height='1px'
				width='100%'
				color='var(--lightGrey)'
			/> */}

					</ClientAdmin>
				)
				}



				{/* <ClientAdmin className='px-3 ml-4 team' onMouseEnter={() => setIsEditVisible2(true)} onMouseLeave={() => setIsEditVisible2(false)}>
					<div className='d-flex align-items-center ml-3 py-2 card1' style={{ display: 'none' }}>
						<Avatar
							src={profile_pic}
							className='profile-pic'
							width='60'
							height='60'
						/>
						<div className='row'>
							<div className='col'>
								<Heading
									fontSize='14px'
									fontWeight='700'
									color='var(--deepBlue)'
									className='mb-0 pb-0 ml-3'
								>
									Rohit
								</Heading>
							</div>
							<div className='col'>
								<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}><u>rohit@xyz.in</u></Text>
							</div>
						</div>
					</div>

					{isEditVisible2 == true ? <div className='cardOverlay'>
						<ClientAdminHover className='px-3 team' onMouseEnter={() => setIsEditVisible2(true)} onMouseLeave={() => setIsEditVisible2(false)}>
							<div className='container card1' style={{ paddingLeft: '0' }}>
								<Avatar
							src={profile_pic}
							className='profile-pic'
							width='60'
							height='60'
						/>
								<div className='row'>
									<div className='col'>
										<Heading
											fontSize='14px'
											fontWeight='700'
											color='var(--deepBlue)'
											className='mb-0 pb-0 ml-2 mt-3'
										>
											Rohit
										</Heading>
									</div>
									<CustomButton
										className='ml-2 mt-2'
										size='small'
										// variant='outlined'
										width='100px'
										height='22px'
										// style={{ color: "#798E8F", background: "none" }}
										data-toggle="modal"
										data-target="#editClientAdminModal"
									>
										Select & Edit
									</CustomButton>
								</div>
								<div className='row mt-2' style={{ flexWrap: "inherit", display: "inline-flex" }}>
									<div className='d-flex align-items-center ml-2 py-2 col-6'>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='20'
											height='20'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='10px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-1'
												>
													Rohit
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>rohit@xyz.in</u></Text>
											</div>
										</div>
									</div>
									<div className='d-flex align-items-center py-2 col-6' style={{ paddingLeft: "0px" }}>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='20'
											height='20'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='10px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-1'
												>
													Khushbu
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>khushbu@xyz.in</u></Text>
											</div>
										</div>
									</div>
								</div>
								<div className='row' style={{ flexWrap: "inherit", display: "inline-flex" }}>
									<div className='d-flex align-items-center ml-2 py-2 col-6'>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='20'
											height='20'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='10px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-1'
												>
													Aritra
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>aritra@xyz.in</u></Text>
											</div>
										</div>
									</div>
									<div className='d-flex align-items-center py-2 col-6' style={{ paddingLeft: "0px" }}>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='20'
											height='20'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='10px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-1'
												>
													Mayur
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>mayur@xyz.in</u></Text>
											</div>
										</div>
									</div>
								</div>
							</div>

							{isEditVisible4 == true ? <div className='cardOverlay'>
					</div> : null}
						</ClientAdminHover>
					</div> : null}


					<Divider
				margin='0'
				height='1px'
				width='100%'
				color='var(--lightGrey)'
			/>

				</ClientAdmin> */}


				{/* <ClientAdmin className='px-3 ml-4 team' onMouseEnter={() => setIsEditVisible3(true)} onMouseLeave={() => setIsEditVisible3(false)}>
					<div className='d-flex align-items-center ml-3 py-2 card1' style={{ display: 'none' }}>
						<Avatar
							src={profile_pic}
							className='profile-pic'
							width='60'
							height='60'
						/>
						<div className='row'>
							<div className='col'>
								<Heading
									fontSize='14px'
									fontWeight='700'
									color='var(--deepBlue)'
									className='mb-0 pb-0 ml-3'
								>
									Gazala
								</Heading>
							</div>
							<div className='col'>
								<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}><u>gazala@xyz.in</u></Text>
							</div>
						</div>
					</div>

					{isEditVisible3 == true ? <div className='cardOverlay'>
						<ClientAdminHover className='px-3 team' onMouseEnter={() => setIsEditVisible3(true)} onMouseLeave={() => setIsEditVisible3(false)}>
							<div className='container card1' style={{ paddingLeft: '0' }}>
								<Avatar
							src={profile_pic}
							className='profile-pic'
							width='60'
							height='60'
						/>
								<div className='row'>
									<div className='col'>
										<Heading
											fontSize='14px'
											fontWeight='700'
											color='var(--deepBlue)'
											className='mb-0 pb-0 ml-2 mt-3'
										>
											Gazala
										</Heading>
									</div>
									<CustomButton
										className='ml-2 mt-2'
										size='small'
										// variant='outlined'
										width='100px'
										height='22px'
										// style={{ color: "#798E8F", background: "none" }}
										data-toggle="modal"
										data-target="#editClientAdminModal"
									>
										Select & Edit
									</CustomButton>
								</div>
								<div className='row mt-2' style={{ flexWrap: "inherit", display: "inline-flex" }}>
									<div className='d-flex align-items-center ml-2 py-2 col-6'>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='20'
											height='20'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='10px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-1'
												>
													Gazala
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>gazala@xyz.in</u></Text>
											</div>
										</div>
									</div>
									<div className='d-flex align-items-center py-2 col-6' style={{ paddingLeft: "0px" }}>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='20'
											height='20'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='10px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-1'
												>
													Rohit
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>rohit@xyz.in</u></Text>
											</div>
										</div>
									</div>
								</div>
								<div className='row' style={{ flexWrap: "inherit", display: "inline-flex" }}>
									<div className='d-flex align-items-center ml-2 py-2 col-6'>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='20'
											height='20'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='10px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-1'
												>
													max
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>max@xyz.in</u></Text>
											</div>
										</div>
									</div>
									<div className='d-flex align-items-center py-2 col-6' style={{ paddingLeft: "0px" }}>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='20'
											height='20'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='10px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-1'
												>
													Lisa
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>lisa@xyz.in</u></Text>
											</div>
										</div>
									</div>
								</div>
							</div>

							{isEditVisible4 == true ? <div className='cardOverlay'>
					</div> : null}
						</ClientAdminHover>
					</div> : null}


					<Divider
				margin='0'
				height='1px'
				width='100%'
				color='var(--lightGrey)'
			/>

				</ClientAdmin> */}

				{/* <ClientAdmin className='px-3 ml-4 team' onMouseEnter={() => setIsEditVisible4(true)} onMouseLeave={() => setIsEditVisible4(false)}>
					<div className='d-flex align-items-center ml-3 py-2 card1' style={{ display: 'none' }}>
						<Avatar
							src={profile_pic}
							className='profile-pic'
							width='60'
							height='60'
						/>
						<div className='row'>
							<div className='col'>
								<Heading
									fontSize='14px'
									fontWeight='700'
									color='var(--deepBlue)'
									className='mb-0 pb-0 ml-3'
								>
									Rohit
								</Heading>
							</div>
							<div className='col'>
								<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}><u>rohit@xyz.in</u></Text>
							</div>
						</div>
					</div>

					{isEditVisible4 == true ? <div className='cardOverlay'>
						<ClientAdminHover className='px-3 team' onMouseEnter={() => setIsEditVisible4(true)} onMouseLeave={() => setIsEditVisible4(false)}>
							<div className='container card1' style={{ paddingLeft: '0' }}>
								<Avatar
							src={profile_pic}
							className='profile-pic'
							width='60'
							height='60'
						/>
								<div className='row'>
									<div className='col'>
										<Heading
											fontSize='14px'
											fontWeight='700'
											color='var(--deepBlue)'
											className='mb-0 pb-0 ml-2 mt-3'
										>
											Rohit
										</Heading>
									</div>
									<CustomButton
										className='ml-2 mt-2'
										size='small'
										// variant='outlined'
										width='100px'
										height='22px'
										// style={{ color: "#798E8F", background: "none" }}
										data-toggle="modal"
										data-target="#editClientAdminModal"
									>
										Select & Edit
									</CustomButton>
								</div>
								<div className='row mt-2' style={{ flexWrap: "inherit", display: "inline-flex" }}>
									<div className='d-flex align-items-center ml-2 py-2 col-6'>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='20'
											height='20'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='10px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-1'
												>
													Rohit
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>rohit@xyz.in</u></Text>
											</div>
										</div>
									</div>
									<div className='d-flex align-items-center py-2 col-6' style={{ paddingLeft: "0px" }}>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='20'
											height='20'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='10px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-1'
												>
													Mayur
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>mayur@xyz.in</u></Text>
											</div>
										</div>
									</div>
								</div>
								<div className='row' style={{ flexWrap: "inherit", display: "inline-flex" }}>
									<div className='d-flex align-items-center ml-2 py-2 col-6'>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='20'
											height='20'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='10px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-1'
												>
													Abhishek
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>abhishek@xyz.in</u></Text>
											</div>
										</div>
									</div>
									<div className='d-flex align-items-center py-2 col-6' style={{ paddingLeft: "0px" }}>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='20'
											height='20'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='10px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-1'
												>
													John D
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>john@xyz.in</u></Text>
											</div>
										</div>
									</div>
								</div>
							</div>

							{isEditVisible4 == true ? <div className='cardOverlay'>
					</div> : null}
						</ClientAdminHover>
					</div> : null}


					 <Divider
				margin='0'
				height='1px'
				width='100%'
				color='var(--lightGrey)'
			/> 

				</ClientAdmin> */}

			</div>

			{/* {seeMoreClients == true ?
				<div className='row mt-4 ml-1'>
					<ClientAdmin className='px-3 team' onMouseEnter={() => setIsEditVisible5(true)} onMouseLeave={() => setIsEditVisible5(false)}>
						<div className='d-flex align-items-center ml-3 py-2 card1' style={{ display: 'none' }}>
							<Avatar
								src={profile_pic}
								className='profile-pic'
								width='60'
								height='60'
							/>
							<div className='row'>
								<div className='col'>
									<Heading
										fontSize='14px'
										fontWeight='700'
										color='var(--deepBlue)'
										className='mb-0 pb-0 ml-3'
									>
										Gazala
									</Heading>
								</div>
								<div className='col'>
									<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}><u>gazala@xyz.in</u></Text>
								</div>
							</div>
						</div>

						{isEditVisible5 == true ? <div className='cardOverlay'>
							<ClientAdminHover className='px-3 team' onMouseEnter={() => setIsEditVisible5(true)} onMouseLeave={() => setIsEditVisible5(false)}>
								<div className='container card1' style={{ paddingLeft: '0' }}>
									<Avatar
							src={profile_pic}
							className='profile-pic'
							width='60'
							height='60'
						/>
									<div className='row'>
										<div className='col'>
											<Heading
												fontSize='14px'
												fontWeight='700'
												color='var(--deepBlue)'
												className='mb-0 pb-0 ml-2 mt-3'
											>
												Gazala
											</Heading>
										</div>
										<CustomButton
											className='ml-2 mt-2'
											size='small'
											// variant='outlined'
											width='100px'
											height='22px'
											// style={{ color: "#798E8F", background: "none" }}
											data-toggle="modal"
											data-target="#editClientAdminModal"
										>
											Select & Edit
										</CustomButton>
									</div>
									<div className='row mt-2' style={{ flexWrap: "inherit", display: "inline-flex" }}>
										<div className='d-flex align-items-center ml-2 py-2 col-6'>
											<Avatar
												src={profile_pic}
												className='profile-pic'
												width='20'
												height='20'
											/>
											<div className='row'>
												<div className='col'>
													<Heading
														fontSize='10px'
														fontWeight='700'
														color='var(--deepBlue)'
														className='mb-0 pb-0 ml-1'
													>
														Gazala
													</Heading>
												</div>
												<div className='col'>
													<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>gazala@xyz.in</u></Text>
												</div>
											</div>
										</div>
										<div className='d-flex align-items-center py-2 col-6' style={{ paddingLeft: "0px" }}>
											<Avatar
												src={profile_pic}
												className='profile-pic'
												width='20'
												height='20'
											/>
											<div className='row'>
												<div className='col'>
													<Heading
														fontSize='10px'
														fontWeight='700'
														color='var(--deepBlue)'
														className='mb-0 pb-0 ml-1'
													>
														Rohit
													</Heading>
												</div>
												<div className='col'>
													<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>rohit@xyz.in</u></Text>
												</div>
											</div>
										</div>
									</div>
									<div className='row' style={{ flexWrap: "inherit", display: "inline-flex" }}>
										<div className='d-flex align-items-center ml-2 py-2 col-6'>
											<Avatar
												src={profile_pic}
												className='profile-pic'
												width='20'
												height='20'
											/>
											<div className='row'>
												<div className='col'>
													<Heading
														fontSize='10px'
														fontWeight='700'
														color='var(--deepBlue)'
														className='mb-0 pb-0 ml-1'
													>
														max
													</Heading>
												</div>
												<div className='col'>
													<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>max@xyz.in</u></Text>
												</div>
											</div>
										</div>
										<div className='d-flex align-items-center py-2 col-6' style={{ paddingLeft: "0px" }}>
											<Avatar
												src={profile_pic}
												className='profile-pic'
												width='20'
												height='20'
											/>
											<div className='row'>
												<div className='col'>
													<Heading
														fontSize='10px'
														fontWeight='700'
														color='var(--deepBlue)'
														className='mb-0 pb-0 ml-1'
													>
														Lisa
													</Heading>
												</div>
												<div className='col'>
													<Text type="7" fontSize={'10px'} color="var(--ternaryColor)" className="ml-1" style={emailFont}><u>lisa@xyz.in</u></Text>
												</div>
											</div>
										</div>
									</div>
								</div>

								{isEditVisible4 == true ? <div className='cardOverlay'>
					</div> : null}
							</ClientAdminHover>
						</div> : null}


						<Divider
				margin='0'
				height='1px'
				width='100%'
				color='var(--lightGrey)'
			/>

					</ClientAdmin></div>
				: null} */}



			{/* ********END****** */}
			<SeeButton className='mt-4' simple color="var(--primaryColor)" fontSize="0.875rem" fontWeight="500" onClick={() => setSeeMoreClients(!seeMoreClients)}>{seeMoreClients ? SeeLess() : SeeMore()}</SeeButton>

			{/* **********PROJECTS SECTION*************** */}
			<div className='row mt-4'>
				<div className="col-sm-8 mt-4">
					<Heading >Projects</Heading>
				</div>
			</div>
			
			<div className='row mt-3'>
				
			{projectSuperAdminDashboard &&
					projectSuperAdminDashboard.filter((project, index) => seeMore ? true : index <= 3).map((project, index) => {
						const projectsteps = project?.steps_array;
						return (
							<div className='col-lg-3 mt-4'>
								<Project className='project'>
									<div className='d-flex'>
										<img
											src={project.projectImages_array.length > 0 && project.projectImages_array[project.projectImages_array.length - 1] != null ? project.projectImages_array[project.projectImages_array.length - 1] : `https://thumbs.dreamstime.com/b/projects-concept-black-chalkboard-d-rendering-handwritten-top-view-office-desk-lot-business-office-supplies-79906734.jpg`}
											// className='profile-pic'
											width='100'
											max-width='100%'
											height='125'
											style={{
												borderRadius: "5px 0 0 5px",
												objectFit: "cover"
											}}
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='12px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-3 mt-1'
												//style={{ width: "100px" }}
												>
													{((project?.title.length) < 15) ? (project?.title) : (project?.title).slice(0, 15) + ('...')}
													{/* <img
												src={delete_icon}
												// src={projectData.projectImages_array[0]}
												className='mr-2'
												// width='125'
												// height='125'
												style={{ float: "right", cursor: 'pointer' }}
												data-toggle="modal"
												data-target="#DeleteModalProject"
												data-dismiss="modal"

												onClick={() => {
													setProjectID(project.project_ID);
													console.warn(project.project_ID);
												}}
											/> */}
												</Heading>
												<Text type="7" color="var(--ternaryColor)" className="ml-3 mt-2" style={emailFont}>{project?.templateName}</Text>
												{project.startDuration != null ? <Text color="var(--ternaryColor)" className="ml-3" fontSize={"8px"} style={datefont}>
													{new Date(project?.startDuration).getDate() + " " + months[new Date(project?.startDuration).getMonth()]} - {new Date(project?.endDuration).getDate() + " " + months[new Date(project?.endDuration).getMonth()]}
												</Text> : ""}
												<div className='mrg'>
													{projectsteps[projectsteps.length - 1].ModuleDescription == 'Project Setup' ?
														<img src={setup} className='ml-1 pt-2' />
														: projectsteps[projectsteps.length - 1].ModuleDescription == 'Recruit' ? <img src={recruit} className='pt-2' />
															: projectsteps[projectsteps.length - 1].ModuleDescription == 'Design' ? <img src={recruitdesign} className='pt-2' />
																: projectsteps[projectsteps.length - 1].ModuleDescription == 'Field' ? <img src={field} className='pt-2' />
																	: projectsteps[projectsteps.length - 1].ModuleDescription == 'Analysis' ? <img src={analysis} className='pt-2' /> : <img src={complete} className='ml-1 pt-2' />}
													<div>
														<CustomButton
															className='ml-2 mt-1'
															size='small'
															variant='outlined'
															width='60px'
															style={{ color: "#798E8F", background: "none" }}
															disabled
														>
															View
														</CustomButton>
														<CustomButton
															className='ml-2 mt-1'
															size='small'
															variant='outlined'
															width='60px'
															style={{ color: "#798E8F", background: "none" }}
															disabled
														>
															Download
														</CustomButton>
													</div>
												</div>
												{/* <CustomButton
											className='ml-2 mt-1'
											size='small'
											variant='outlined'
											width='60px'
											style={{ color: "#798E8F", background: "none" }}
											disabled
										>
											View
										</CustomButton>
										<CustomButton
											className='ml-2 mt-1'
											size='small'
											variant='outlined'
											width='60px'
											style={{ color: "#798E8F", background: "none" }}
											disabled
										>
											Download
										</CustomButton>
										{ projectsteps[projectsteps.length-1].ModuleDescription == 'Project Setup' ?
											<img src={setup} className='ml-1 pt-2'/> 
										: projectsteps[projectsteps.length-1].ModuleDescription == 'Recruit' ? <img src={recruit} className='pt-2'/> 
										: projectsteps[projectsteps.length-1].ModuleDescription == 'Design' ? <img src={recruitdesign} className='pt-2'/> 
										: projectsteps[projectsteps.length-1].ModuleDescription == 'Field' ? <img src={field} className='pt-2'/>
										: projectsteps[projectsteps.length-1].ModuleDescription == 'Analysis' ? <img src={analysis} className='pt-2'/> : <img src={complete} className='ml-1 pt-2'/>} */}
											</div>
											{/* <div className='col'>
									</div> */}
										</div>
									</div>
								</Project>
							</div>
						)
					}

					)}
				
				{/* <div className='col-3'>
					<Project className='project'>
						<div className='d-flex'>
							<img
								src={digital}
								className='profile-pic'
								width='125'
								height='125'
								style={{ borderRadius: "5px 0 0 5px" }}
							/>
							<div className='row'>
								<div className='col'>
									<Heading
										fontSize='12px'
										fontWeight='700'
										color='var(--deepBlue)'
										className='mb-0 pb-0 ml-3 mt-1'
										style={{ width: "100px" }}
									>
										Weekend Plan
									</Heading>
									<Text type="7" color="var(--ternaryColor)" className="ml-3 mt-2" style={emailFont}>Experience</Text>
									<Text color="var(--ternaryColor)" className="ml-3" fontSize={"8px"} style={datefont}>25 Oct - 10 Nov</Text>
									<CustomButton
										className='ml-2 mt-2'
										size='small'
										variant='outlined'
										width='60px'
										style={{ color: "#798E8F", background: "none" }}
										disabled
									>
										View
									</CustomButton>
									<CustomButton
										className='ml-2 mt-1'
										// disabled
										size='small'
										variant='outlined'
										width='60px'
										style={{ color: "#798E8F", background: "none" }}
										disabled
									>
										Download
									</CustomButton>
									<img
										src={setup}
										className='pt-2'
										style={{ marginLeft: "12px" }}
									// style={{ cursor: 'pointer', borderRadius: '50%' }}
									/>
								</div>
								<div className='col'>
								</div>
							</div>
						</div>

						<Divider
					margin='0'
					height='1px'
					width='100%'
					color='var(--lightGrey)'
				/>

					</Project>
				</div>

				<div className='col-3'>
					<Project className='project'>
						<div className='d-flex'>
							<img
								src={discovery}
								className='profile-pic'
								width='125'
								height='125'
								style={{ borderRadius: "5px 0 0 5px" }}
							/>
							<div className='row'>
								<div className='col'>
									<Heading
										fontSize='12px'
										fontWeight='700'
										color='var(--deepBlue)'
										className='mb-0 pb-0 ml-3 mt-1'
										style={{ width: "100px" }}
									>
										My Movie Binge
									</Heading>
									<Text type="7" color="var(--ternaryColor)" className="ml-3 mt-2" style={emailFont}>Experience</Text>
									<Text color="var(--ternaryColor)" className="ml-3" fontSize={"8px"} style={datefont}>01 Oct - 01 Nov</Text>
									<CustomButton
										className='ml-2 mt-2'
										size='small'
										variant='outlined'
										width='60px'
									>
										View
									</CustomButton>
									<CustomButton
										className='ml-2 mt-1'
										// disabled
										size='small'
										variant='outlined'
										width='60px'
									>
										Download
									</CustomButton>
									<img
										src={complete}
										className='ml-1 pt-2'
									// style={{ cursor: 'pointer', borderRadius: '50%' }}
									/>
								</div>
								<div className='col'>
								</div>
							</div>
						</div>

						<Divider
					margin='0'
					height='1px'
					width='100%'
					color='var(--lightGrey)'
				/>

					</Project>
				</div>

				<div className='col-3'>
					<Project className='project'>
						<div className='d-flex'>
							<img
								src={diagnostic}
								className='profile-pic'
								width='125'
								height='125'
								style={{ borderRadius: "5px 0 0 5px" }}
							/>
							<div className='row'>
								<div className='col'>
									<Heading
										fontSize='12px'
										fontWeight='700'
										color='var(--deepBlue)'
										className='mb-0 pb-0 ml-3 mt-1'
										style={{ width: "100px" }}
									>
										Book a show
									</Heading>
									<Text type="7" color="var(--ternaryColor)" className="ml-3 mt-2" style={emailFont}>Experience</Text>
									<Text color="var(--ternaryColor)" className="ml-3" fontSize={"8px"} style={datefont}>10 Oct - 05 Nov</Text>
									<CustomButton
										className='ml-2 mt-2'
										size='small'
										variant='outlined'
										width='60px'
									>
										View
									</CustomButton>
									<CustomButton
										className='ml-2 mt-1'
										// disabled
										size='small'
										variant='outlined'
										width='60px'
									>
										Download
									</CustomButton>
									<img
										src={complete}
										className='ml-1 pt-2'
									// style={{ cursor: 'pointer', borderRadius: '50%' }}
									/>
								</div>
								<div className='col'>
								</div>
							</div>
						</div>

						<Divider
					margin='0'
					height='1px'
					width='100%'
					color='var(--lightGrey)'
				/>

					</Project>
				</div> */}

				{/* {seeMore == true ? (
					<>
						<div className='col-3 mt-4'>
							<Project className='project'>
								<div className='d-flex'>
									<img
										src={diagnostic}
										className='profile-pic'
										width='125'
										height='125'
										style={{ borderRadius: "5px 0 0 5px" }}
									/>
									<div className='row'>
										<div className='col'>
											<Heading
												fontSize='12px'
												fontWeight='700'
												color='var(--deepBlue)'
												className='mb-0 pb-0 ml-3 mt-1'
												style={{ width: "100px" }}
											>
												Eating Out
											</Heading>
											<Text type="7" color="var(--ternaryColor)" className="ml-3 mt-2" style={emailFont}>Experience</Text>
											<Text color="var(--ternaryColor)" className="ml-3" fontSize={"8px"} style={datefont}>06 Nov - 20 Nov</Text>
											<CustomButton
												className='ml-2 mt-2'
												size='small'
												variant='outlined'
												width='60px'
											>
												View
											</CustomButton>
											<CustomButton
												className='ml-2 mt-1'
												// disabled
												size='small'
												variant='outlined'
												width='60px'
											>
												Download
											</CustomButton>
											<img
												src={complete}
												className='ml-1 pt-2'
											// style={{ cursor: 'pointer', borderRadius: '50%' }}
											/>
										</div>
										<div className='col'>
										</div>
									</div>
								</div>

								<Divider
									margin='0'
									height='1px'
									width='100%'
									color='var(--lightGrey)'
								/>

							</Project>
						</div>

						<div className='col-3 mt-4'>
							<Project className='project'>
								<div className='d-flex'>
									<img
										src={relaunch}
										className='profile-pic'
										width='125'
										height='125'
										style={{ borderRadius: "5px 0 0 5px" }}
									/>
									<div className='row'>
										<div className='col'>
											<Heading
												fontSize='12px'
												fontWeight='700'
												color='var(--deepBlue)'
												className='mb-0 pb-0 ml-3 mt-1'
												style={{ width: "100px" }}
											>
												Let's Drive
											</Heading>
											<Text type="7" color="var(--ternaryColor)" className="ml-3 mt-2" style={emailFont}>Experience</Text>
											<Text color="var(--ternaryColor)" className="ml-3" fontSize={"8px"} style={datefont}>02 Nov - 15 Nov</Text>
											<CustomButton
												className='ml-2 mt-1'
												size='small'
												variant='outlined'
												width='60px'
												style={{ color: "#798E8F", background: "none" }}
												disabled
											>
												View
											</CustomButton>
											<CustomButton
												className='ml-2 mt-1'
												size='small'
												variant='outlined'
												width='60px'
												style={{ color: "#798E8F", background: "none" }}
												disabled
											>
												Download
											</CustomButton>
											<img
												src={setup}
												className='pt-2'
												style={{ marginLeft: "12px" }}
											// style={{ cursor: 'pointer', borderRadius: '50%' }}
											/>
										</div>
										<div className='col'>
										</div>
									</div>
								</div>

								<Divider
					margin='0'
					height='1px'
					width='100%'
					color='var(--lightGrey)'
				/>

							</Project>
						</div>
					</>

				) : null} */}
			</div>




			<SeeButton className='mt-4' simple color="var(--primaryColor)" fontSize="0.875rem" fontWeight="500" onClick={() => setSeeMore(!seeMore)}>{seeMore ? SeeLess() : SeeMore()}</SeeButton>

			{/* ********END****** */}



			{/* **********USER SECTION*************** */}


			<div className='row mt-4'>
				<div className="col-sm-8 mt-3">
					<Heading>Users</Heading>
				</div>
				<div className="col-sm-4 ">
					<div className="d-flex justify-content-end">
						<div data-toggle="modal" data-target="#addNewUserModal_SA">
							<CardButton><img src={PlusGreen} alt="Plus Green" className="plus-green" /> <img src={PlusWhite} alt="Plus White" className="plus-white" /> New User</CardButton>
						</div>

					</div>

					{/* Add User Modal Start */}
					<AddNewUserModal_SA />
					{/* Add User Modal End */}

					{/* Edit User Modal Start */}
					< EditRespondantGenPoolModal />
					{/* Edit User Modal End */}

					{/* Edit Researcher Modal Start */}
					< EditResearcherGenPoolModal userModaldata={userModaldata} IsFlag={IsFlag} setIsFlag={setIsFlag} />
					{/* Edit User Modal End */}

					{/* Edit Observer Modal Start */}
					< EditObserverModal />
					{/* Edit User Modal End */}

					{/* Confirm User deleted Modal Start */}
					<UserDeletedModal />
					{/* Confirm User deleted Modal End */}

					{/*Edit Team Modal Start */}
					<EditTeamModal />
					{/*Edit Team Modal End */}

					{/*Client Admin Modal Start */}
					<EditClientAdminModal clientAdminModaldata={clientAdminModaldata} IsFlag={IsFlag} setIsFlag={setIsFlag} />
					{/*Edit Team Modal End */}



				</div>
			</div>

			<div className='row mt-2'>
				<div className='col-2'>
					<Text><u>General Pool</u></Text>
				</div>
				<div className='col-2'>
					<Text>Client Pool</Text>
				</div>
				<div className='col'>
					<InputText
						style={{ width: '50%', height: "20px" }}
						placeholder='Search'
						showCharacters={false}
					// showIcon={true}
					// iconName="create"
					/>
				</div>
				{/* <div className='col-2'>
					<Text style={emailFont}>General Pool</Text>
				</div>
				<div className='col-2'>
					<Text style={{marginLeft: "-5%"}}>General Pool</Text>
				</div> */}
			</div>




			<TableContainer className='table mt-3'>
				<thead>
					<tr>
						<th style={{ width: '20%' }}>
							<div className='name d-flex align-items-center'>
								Name{' '}
								<div className="d-flex px-3" onClick={sortList}>
									<img className="icon" src={uparrow} />
									<img className="icon" src={downArrow} />
								</div>
								{/* <img
										className='icon'
										src={search}
									/> */}
								<img
									className='icon pl-3'
									src={filter}
								/>
							</div>
						</th>
						<th style={{ width: '16%' }}>
							<div className=' d-flex align-items-center '>
								Roles{' '}
								<img
									className='icon pl-3'
									src={filter}
								/>
							</div>
						</th>
						<th style={{ width: '25%' }}>
							<div className=' d-flex align-items-center '>
								OnGoing Projects{' '}
								<img
									className='icon pl-3'
									src={filter}
								/>
							</div>
						</th>
						<th style={{ width: '42%' }}>
							<div className='d-flex align-items-center'>
								Permission
							</div>
						</th>
					</tr>
				</thead>

				<tbody>
					{onGoingProject &&
						onGoingProject.filter((userValue, index) => seeMoreUser ? true : index <= 3).map((userValue, index) =>
							<tr>
								<th scope="row">
									<div className='d-flex align-items-center py-2 mt-2'>
										<Avatar
											src={userList[2].presignedUrl}
											className='profile-pic'
											width='30'
											height='30'
										/>
										<div className='row '>
											<div className='col'>
												<Heading
													fontSize='14px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-3'
												>
													{(userValue.name).slice(0, 9) + ('...')}
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>{(userValue.email).slice(0, 10) + ('...')}</Text>
											</div>
										</div>
										{
											userValue.role_ID == "4" ? <div data-toggle="modal" data-target="#editRespondantGenPoolModal" onClick={() => { editUserModal(userValue) }}><ActionIcon>create</ActionIcon></div>
												: userValue.role_ID == "1" ? <div data-toggle="modal" data-target="#editResearcherGenPoolModal" onClick={() => { editUserModal(userValue) }}><ActionIcon>create</ActionIcon></div>
													: ""
										}
									</div>
								</th>
								<td>
								{index == 0 ?
									<Text className='mt-4'>
										<Pill className="pill">Researcher
											<img
												src={cross}
												className='ml-1'
												style={{ cursor: 'pointer', borderRadius: '50%' }}
											/>
										</Pill>
										<img src={PlusGrey} alt="Plus Grey" className="plus-grey mt-1 ml-1" />
									</Text>
									: <Text className='mt-1'>
									<Pill className="pill">Researcher
										<img
											src={cross}
											className='ml-1'
											style={{ cursor: 'pointer', borderRadius: '50%' }}
										/>
									</Pill>
									<img src={PlusGrey} alt="Plus Grey" className="plus-grey mt-1 ml-1" />
								</Text>
									}

								</td>
								<td>
								<Text className='mt-4'type='2' >{userValue.project_Title.split(',',3).join(" | ")}<button  style={{display:'inline',border:'none',background:'none'}}><Text>{userValue.project_Title.split(',').length-3 <= 0?"":"+".concat(userValue.project_Title.split(',').length-3)}</Text></button></Text>
								</td>
								<td>
									{index == 0 ?
										<div className='row' style={{ marginLeft: "3px", marginBottom: "15px" }}>
											<Text type='8' className=''>Setup</Text>
											<Text type='8' className='ml-2 permission'>Recruit</Text>
											<Text type='8' className='ml-2 permission'>Design</Text>
											<Text type='8' className='ml-2 permission'>Field</Text>
											<Text type='8' className='ml-2 permission'>Analysis</Text>
											<Text type='8' className='ml-2 permission'>Reports</Text>
											<div style={{ marginBottom: "-15px" }}>
												<Text type='8' className='ml-2 permission'>Approve</Text>
												<Text type='8' className='ml-2 permission'>budget</Text>
											</div>
											<div style={{ marginBottom: "-15px" }}>
												<Text type='8' className='ml-2 permission'>Close</Text>
												<Text type='8' className='ml-2 permission'>Project</Text>
											</div>
										</div>
										: null}
									<div className='row ml-1 mt-2'>
										<label className="switch">
											<input type="checkbox" name='setup' onChange={change_permission} defaultChecked />
											<span className="slider round"></span>
										</label>
										<label className="switch ml-3 permission-toggle">
											<input type="checkbox" name='recruit' onChange={change_permission} defaultChecked />
											<span className="slider round"></span>
										</label>
										<label className="switch ml-3 permission-toggle">
											<input type="checkbox" name='design' onChange={change_permission} defaultChecked />
											<span className="slider round"></span>
										</label>
										<label className="switch ml-3 permission-toggle">
											<input type="checkbox" name='field' onChange={change_permission} defaultChecked />
											<span className="slider round"></span>
										</label>
										<label className="switch ml-3 permission-toggle">
											<input type="checkbox" name='analysis' onChange={change_permission} defaultChecked />
											<span className="slider round"></span>
										</label>
										<label className="switch ml-3 permission-toggle">
											<input type="checkbox" name='reports' onChange={change_permission} defaultChecked />
											<span className="slider round"></span>
										</label>
										<label className="switch ml-3 permission-toggle">
											<input type="checkbox" name='approve_budget' onChange={change_permission} defaultChecked />
											<span className="slider round"></span>
										</label>
										<label className="switch ml-3 permission-toggle">
											<input type="checkbox" name='close_project' onChange={change_permission} defaultChecked />
											<span className="slider round"></span>
										</label>

										<CustomButton
											className='ml-4 save_button_pool'
											size='small'
											variant='outlined'
											width='50px'
										>
											Save
										</CustomButton>
									</div>


								</td>
							</tr>

						)}
					{/* <tr>
						<th scope="row">
							<div className='d-flex align-items-center py-2'>
								<Avatar
									src={profile_pic}
									className='profile-pic'
									width='30'
									height='30'
								/>
								<div className='row'>
									<div className='col'>
										<Heading
											fontSize='14px'
											fontWeight='700'
											color='var(--deepBlue)'
											className='mb-0 pb-0 ml-3'
										>
											Sonia P
										</Heading>
									</div>
									<div className='col'>
										<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>sonia@xyz.in</Text>
									</div>
								</div>
								<div data-toggle="modal" data-target="#editResearcherGenPoolModal">
									<ActionIcon>create</ActionIcon>
								</div>
							</div>
						</th>
						<td>
							<Text><Pill className="pill">Lead Researcher
								<img
									src={cross}
									className='ml-1'
									style={{ cursor: 'pointer', borderRadius: '50%' }}
								/>
							</Pill>
								<Pill className="pill">Researcher
									<img
										src={cross}
										className='ml-1'
										style={{ cursor: 'pointer', borderRadius: '50%' }}
									/>
								</Pill></Text>
						</td>
						<td><Text>Book A Show</Text></td>
						<td>
							<label className="switch">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<CustomButton
								className='ml-2'
								size='small'
								variant='outlined'
								width='50px'
							>
								Save
							</CustomButton>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<div className='d-flex align-items-center py-2'>
								<Avatar
									src={profile_pic}
									className='profile-pic'
									width='30'
									height='30'
								/>
								<div className='row'>
									<div className='col'>
										<Heading
											fontSize='14px'
											fontWeight='700'
											color='var(--deepBlue)'
											className='mb-0 pb-0 ml-3'
										>
											Aarushi P
										</Heading>
									</div>
									<div className='col'>
										<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>aarushi@xyz.in</Text>
									</div>
								</div>
								<div
								// data-toggle="modal" data-target="#editObserverModal"
								>
									<ActionIcon>create</ActionIcon>
								</div>
							</div>
						</th>
						<td>
							<Text><Pill className="pill">Researcher
								<img
									src={cross}
									className='ml-1'
									style={{ cursor: 'pointer', borderRadius: '50%' }}
								/>
							</Pill>
								<img src={PlusGrey} alt="Plus Grey" className="plus-grey mt-1 ml-1" /></Text>
						</td>
						<td><Text>Style Your Hair | Book A Show | My Movie Binge | +3</Text></td>
						<td>
							<label className="switch">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" />
								<span className="slider round"></span>
							</label>
							<CustomButton
								className='ml-2'
								size='small'
								variant='outlined'
								width='50px'
							>
								Save
							</CustomButton>
						</td>
					</tr>
					<tr>
						<th scope="row">
							<div className='d-flex align-items-center py-2'>
								<Avatar
									src={profile_pic}
									className='profile-pic'
									width='30'
									height='30'
								/>
								<div className='row'>
									<div className='col'>
										<Heading
											fontSize='14px'
											fontWeight='700'
											color='var(--deepBlue)'
											className='mb-0 pb-0 ml-3'
										>
											Sam j
										</Heading>
									</div>
									<div className='col'>
										<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>sam@xyz.in</Text>
									</div>
								</div>
								<ActionIcon>create</ActionIcon>
							</div>
						</th>
						<td>
							<Text><Pill className="pill">Researcher
								<img
									src={cross}
									className='ml-1'
									style={{ cursor: 'pointer', borderRadius: '50%' }}
								/>
							</Pill>
								<img src={PlusGrey} alt="Plus Grey" className="plus-grey mt-1 ml-1" /></Text>
						</td>
						<td><Text>My Movie Binge</Text></td>
						<td>
							<label className="switch">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" defaultChecked />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" />
								<span className="slider round"></span>
							</label>
							<label className="switch ml-3">
								<input type="checkbox" />
								<span className="slider round"></span>
							</label>
							<CustomButton
								className='ml-2'
								size='small'
								variant='outlined'
								width='50px'
							>
								Save
							</CustomButton>
						</td>
					</tr> */}

					{/* {seeMoreUser == true ? (
						<>
							<tr>
								<th scope="row">
									<div className='d-flex align-items-center py-2'>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='30'
											height='30'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='14px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-3'
												>
													Gazala
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>gazala@xyz.in</Text>
											</div>
										</div>
										<ActionIcon>create</ActionIcon>
									</div>
								</th>
								<td>
									<Text><Pill className="pill">Lead Researcher
										<img
											src={cross}
											className='ml-1'
											style={{ cursor: 'pointer', borderRadius: '50%' }}
										/>
									</Pill>
										<Pill className="pill">Researcher
											<img
												src={cross}
												className='ml-1'
												style={{ cursor: 'pointer', borderRadius: '50%' }}
											/>
										</Pill></Text>
								</td>
								<td><Text>Book A Show</Text></td>
								<td>
									<label className="switch">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<CustomButton
										className='ml-2'
										size='small'
										variant='outlined'
										width='50px'
									>
										Save
									</CustomButton>
								</td>
							</tr>
							<tr>
								<th scope="row">
									<div className='d-flex align-items-center py-2'>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='30'
											height='30'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='14px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-3'
												>
													Rohit
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>rohit@xyz.in</Text>
											</div>
										</div>
										<ActionIcon>create</ActionIcon>
									</div>
								</th>
								<td>
									<Text><Pill className="pill">Researcher
										<img
											src={cross}
											className='ml-1'
											style={{ cursor: 'pointer', borderRadius: '50%' }}
										/>
									</Pill>
										<img src={PlusGrey} alt="Plus Grey" className="plus-grey mt-1 ml-1" /></Text>
								</td>
								<td><Text>Style Your Hair | Book A Show | My Movie Binge | +3</Text></td>
								<td>
									<label className="switch">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" />
										<span className="slider round"></span>
									</label>
									<CustomButton
										className='ml-2'
										size='small'
										variant='outlined'
										width='50px'
									>
										Save
									</CustomButton>
								</td>
							</tr>
							<tr>
								<th scope="row">
									<div className='d-flex align-items-center py-2'>
										<Avatar
											src={profile_pic}
											className='profile-pic'
											width='30'
											height='30'
										/>
										<div className='row'>
											<div className='col'>
												<Heading
													fontSize='14px'
													fontWeight='700'
													color='var(--deepBlue)'
													className='mb-0 pb-0 ml-3'
												>
													Sia
												</Heading>
											</div>
											<div className='col'>
												<Text type="7" color="var(--ternaryColor)" className="ml-3" style={emailFont}>sia@xyz.in</Text>
											</div>
										</div>
										<ActionIcon>create</ActionIcon>
									</div>
								</th>
								<td>
									<Text><Pill className="pill">Lead Researcher
										<img
											src={cross}
											className='ml-1'
											style={{ cursor: 'pointer', borderRadius: '50%' }}
										/>
									</Pill>
										<Pill className="pill">Researcher
											<img
												src={cross}
												className='ml-1'
												style={{ cursor: 'pointer', borderRadius: '50%' }}
											/>
										</Pill></Text>
								</td>
								<td><Text>Book A Show</Text></td>
								<td>
									<label className="switch">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<label className="switch ml-3">
										<input type="checkbox" defaultChecked />
										<span className="slider round"></span>
									</label>
									<CustomButton
										className='ml-2'
										size='small'
										variant='outlined'
										width='50px'
									>
										Save
									</CustomButton>
								</td>
							</tr>
						</>
					) : null} */}


				</tbody>
			</TableContainer>
			<SeeButton className='mt-4' simple color="var(--primaryColor)" fontSize="0.875rem" fontWeight="500" onClick={() => setSeeMoreUser(!seeMoreUser)}>{seeMoreUser ? SeeLess() : SeeMore()}</SeeButton>


			{/* ********END****** */}	
		</>
	)
}
