import React from 'react';
import { useState,useEffect } from 'react';
import { Button, Text, Avatar, Heading } from '..';
import styled from '@emotion/styled';
import {
    InputText,
    TextArea,
    DropFile,
    Dropdown,
    ButtonRadio,
    TaskTypeOption,
    TaskMenu,
} from '../../components/form';
import { Divider } from '..';
import Icon from '../Icon'
import cross from '../../assets/images/cross.svg';
import GreenDropdown from '../../assets/images/green-dropdown.svg';
import profile_pic from '../../assets/images/profile_pic.png';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_EDITTEAMDATA } from '../../constants/clientAdmin/EditTeamdataConstants';
import { REQUEST_CLIENTDASHBOARD_DATA } from '../../constants/clientAdmin/clientDashboardConstants';
//import {REQUEST_UPDATE_PASSWORD}  from '../../../constants/SuperAdmin/UpdatedPasswordConstant';
import {REQUEST_UPDATE_PASSWORD} from '../../constants/SuperAdmin/UpdatedPasswordConstant';
import avatar from '../../images/researcher.png';
import { color } from 'd3';

const field = {
    marginLeft: "0px"
}

const InputTextContainer = styled.div`
	.iconContainer {
		position: absolute;
		top: 30%;
		right: 15px;
	}
	span {
		font-size: 18px;
		color: var(--greyBlue);
	}
`;

const SeeButton = styled(Button)`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    line-height: 1;

    .reverse {
        transform: rotate(-180deg);
    }
`;
const CustomButton = styled(Button)`
	width: ${(props) => props.width};
	padding: 3px 3px !important;
	float: right;
    margin-right: 5px;
`;

const Pill = styled.span`
	font-size: 9px;
	font-family: "Readex Pro", sans-serif;
	padding: 0.5em 1em;
	margin: 0.25em;
	// border-radius: 1em;
	// border: none;
	// outline: none;
	// background: #dddddd;
	border-radius: 20px;
	cursor: pointer;
	border: 1px solid grey;
`;
const popupColor = styled.div `
&.green{
    color: green;  
}
`;

const gender_list = [
    { value: 1, label: 'Male' },
    { value: 2, label: 'Female' },
    { value: 3, label: 'Non-Binary' },
];

const city_list = [
    { value: 1, label: 'Ahmedabad' },
    { value: 2, label: 'Bangalore' },
    { value: 3, label: 'Mumbai' },
    { value: 4, label: 'Kolkata' },
    { value: 5, label: 'Delhi' },
];

const qualification_list = [
    { value: 1, label: 'Illiterate' },
    { value: 2, label: 'Literate but no formal schooling' },
    { value: 3, label: 'School (5-9 yrs)' },
    { value: 4, label: 'SSC/HSC' },
    { value: 5, label: 'Some College(Including a diploma but not graduate)' },
    { value: 6, label: 'Graduate/Post-Graduate: General' },
    { value: 7, label: 'Graduate/Post-Graduate: Professional' },
];

const SeeLess = () => <>See less <img src={GreenDropdown} className="ml-2" /></>

const SeeMore = () => <>See more <img src={GreenDropdown} className="ml-2 reverse" /></>

export default function EditTeamModal({teamModaldata, setIsFlag, IsFlag}) {
    const [seeMoreProfile, setSeeMoreProfile] = useState(true);
    const [seeMorePrimary, setSeeMorePrimary] = useState(false);
    const [userType, setUserType] = useState('Respondant');
	const [GetTeamData,setGetTeamData] = useState([]);
    const userId = useSelector(state => state.LoginReducer.userId);
    const dispatch = useDispatch(); 

    console.log(GetTeamData)
    console.log("111")
    useEffect(() => {
		setGetTeamData(teamModaldata);
	},[teamModaldata])

    const handleChnage = (evt) => {
		const value = evt.target.value;
		setGetTeamData({
			...GetTeamData,
			[evt.target.name]: value,
		});

	};

    const change_password = () => {
        const user_id = userId
 
        dispatch({
            type: REQUEST_UPDATE_PASSWORD,
            payload: GetTeamData.user_id
        });
    }

    // const[isSending,setIsSending] = useState(false);
    // const[popupVisible, setPopupVisible] = useState(false);
    // const [popupMessage, setPopupMessage] = useState('');
    // const [isError, setIsError] = useState(false);
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [isPopupVisible,setIsPopupVisible] = useState(false);
    const [popupMessage,setPopupMessage] = useState('');
    const [popupColor,setPopupColor] = useState('');
    const [isModelVisible, setIsModelVisible] = useState(false);
    const [isNetworkError, setIsNetworkError] = useState(false);
    const [networkErrorMessage, setNetworkErrorMessage] = useState('');
    
    const validateEmail = (email) =>{
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    const validatePhoneNumber = (mobile) =>{
        const phoneRegex = /^[0-9]{10}$/;
        return phoneRegex.test(mobile);
    }
    const validateFields = () =>{
        let isValid = true;
        setEmailError('');
        setPhoneError('');

        if(!validateEmail(GetTeamData.email)){
            setEmailError('Invalid Email Address');
            isValid = false;
        }

        if(!validatePhoneNumber(GetTeamData.mobile)){
            setPhoneError('Invalid Phone Number');
            isValid = false;
        }
        return isValid;
    }
    const handlePopupClose = () =>{
        setIsPopupVisible(false);
        setPopupMessage('');
    };
    // const handleCloseModal = () => {
    //     // setIsPopupVisible(false);
    //     setIsFlag(0);
    // };
    
    const applyTeamChanges = async () =>{
        if(!validateFields()){
            return;
        }
       
        setIsPopupVisible(true);
        setPopupMessage('Data is being sent...');

        // setIsSending(true);
    const formData ={
        userID :GetTeamData.user_id , name : GetTeamData.teamLeadName, 
        email : GetTeamData.email, mobile : GetTeamData.mobile, 
        desigination : GetTeamData.desigination, clientTeam_ID : GetTeamData.clientTeam_ID, 
        team : GetTeamData.teamName, division: GetTeamData.division}
      
        
        dispatch({
			type: REQUEST_EDITTEAMDATA,
			payload: formData,
		});
        
      
        setTimeout(() =>{
            setIsPopupVisible(true);
            setPopupMessage('Data sent successfully!');
            setPopupColor('green');
            setTimeout(() =>{
                setPopupMessage('');
                // handleCloseModal();
                
            },3000);
            
            // setTimeout(() =>{
            //     handleCloseModal();
            // },15000);
        },5000);
        // setTimeout(() =>{
        //     setIsSending(false);
        //     setIsError(Math.random() < 0.5);

        //     if(isError){
        //         setPopupMessage('Error Sending Data.');
        //     }else{
        //         setPopupMessage('Data Sent Successfully!');
        //     }
        //     setPopupVisible(true);

        //     setTimeout(() =>{
        //         setPopupVisible(false);
        //     },8000);

        // },2000)
		setTimeout( /*Here it is in settimeout because it takes sometime to update values and the fetch*/
			() => dispatch({
				type: REQUEST_CLIENTDASHBOARD_DATA,
				payload: {
				  user_ID: userId
				}
                
			  }), 
			500
		  );
        //   setGetTeamData(teamModaldata);
          setIsFlag(1);
	}
    useEffect(() =>{

    },[isModelVisible]);

    return (
        <div>
            <>

                {/* <Button
                    className='ml-4'
                    size='small'
                    variant='outlined'
                    width='50px'
                    data-toggle="modal"
                    data-target="#exampleModalCenter"
                >
                    edit
                </Button> */}

                <div className="modal fade" id="editTeamModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered" role="document" style={{ maxWidth: "700px" }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header border-0" >
                                <Text><Pill className='mt-2 ml-2'>{GetTeamData?.teamName}
                                    <img
                                        src={cross}
                                        className='ml-1 mb-1'
                                        style={{ cursor: 'pointer', borderRadius: '50%' }}
                                    /></Pill></Text>
                                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            {isPopupVisible && (
                                <center><div style={{color: 'var(--primaryColor)',fontWeight: 'bold'}}><i>{popupMessage}</i></div></center>
                            ) }
                            {/* {isSending && <center><p>Sending Data...</p></center>}
                                    {popupVisible && <center><div className={`popup ${isError ?'error' : 'success' }`}>{popupMessage}</div></center>} */}
                            <div className="modal-body">
                                <div className='container'>
                                    <div className='row'>
                                        <div className='col-1'>
                                            <Avatar
                                                src={GetTeamData?.presignedUrl != null ? GetTeamData?.presignedUrl : avatar}                                                
                                                className='team-profile-pic'
                                                width='60'
                                                height='60'
                                            />
                                        </div>
                                        <div className='container w-75'>
                                            <div className='row'>
                                                {/* PRofile Details */}
                                                <div>
                                                    <Heading
                                                        fontSize='14px'
                                                        className='mt-3 ml-3 mb-3'
                                                    >
                                                        Profile
                                                    </Heading>

                                                    <>
                                                        <InputTextContainer>
                                                            <div className='row' style={field}>
                                                                <div className='col-8'>
                                                                    <InputText
                                                                    	name='teamLeadName'
                                                                        placeholder='Full Name'
                                                                        showCharacters={false}
                                                                        defaultValue={GetTeamData?.teamLeadName}
                                                                        value={GetTeamData?.teamLeadName}
                                                                        onChange={handleChnage}
                                                                        setIsFlag={setIsFlag}
						                                                IsFlag={IsFlag}
                                                                    // showIcon={true}
                                                                    // iconName="create"
                                                                    // error='Hey, looks like you’ve missed this'
                                                                    />
                                                                    <Icon className='iconContainer'>create</Icon>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <InputText
                                                                        name='mobile'
                                                                        placeholder='Phone No.'
                                                                        showCharacters={false}
                                                                        value={GetTeamData?.mobile}
                                                                        onChange={handleChnage}
                                                                        setIsFlag={setIsFlag}
						                                                IsFlag={IsFlag}
                                                                    // showIcon={true}
                                                                    // iconName="create"
                                                                    // error='Hey, looks like you’ve missed this'
                                                                    />
                                                                    <Icon className='iconContainer'>create</Icon>
                                                                    <div className="error-message" style={{color: 'red', fontWeight: 'bold'}}>{phoneError}</div>
                                                                </div>
                                                                

                                                            </div>
                                                            <div className='row' style={field}>
                                                                <div className='col-8'>
                                                                    <InputText
                                                                        name='email'
                                                                        placeholder='Email'
                                                                        showCharacters={false}
                                                                        value={GetTeamData?.email}
                                                                        onChange={handleChnage}
                                                                        setIsFlag={setIsFlag}
						                                                IsFlag={IsFlag}
                                                                    // showIcon={true}
                                                                    // iconName="create"
                                                                    // error='Hey, looks like you’ve missed this'
                                                                    />
                                                                    <Icon className='iconContainer'>create</Icon>
                                                                    <div className="error-message" style={{color: 'red'}} >{emailError}</div>
                                                                </div>
                                                                
                                                            </div>
                                                            {/* ///// reset password */}
                                                            <div className='row' id='ResetPassword'>
                                                                <div className='col col-5 mt-2 mb-2 ml-1'>

                                                                    <CustomButton
                                                                        className='ml-0'
                                                                        size='small'
                                                                        variant='outlined'
                                                                        fontSize='8px'
                                                                        data-toggle="modal" data-target="#ResetPasswordTeam"
                                                                        style={{ paddingLeft: '20px', marginRight: '95px', borderRadius: "7px", height: "20px", width: '85px', marginTop: '10px' }}
                                                                        onClick={change_password}
                                                                    >
                                                                        Reset Password
                                                                    </CustomButton>
                                                                    {/* New Modal for Reset Password  */}
                                                                    <div className="modal fade" id="ResetPasswordTeam" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                                                                        <div className="modal-dialog modal-dialog-centered " role="document" style={{ width: '400px' }}>
                                                                            <div className="modal-content" style={{ borderRadius: "10px" }}>
                                                                                <div className="modal-header border-0">
                                                                                    <Heading fontSize='18px'>Reset Your Password</Heading>
                                                                                    {/* <button type="button" className="close" data-dismiss="modal" data-target='#DeleteModal' aria-label="Close">
                                                                                        <span aria-hidden="true">&times;</span>
                                                                                    </button> */}
                                                                                </div>
                                                                                <div className="modal-body border-0" style={{ paddingBottom: '20px' }}>
                                                                                    <div className='row mb-2'>
                                                                                        <div className='col col-12'>
                                                                                            <Text type='3' color="var(--ternaryColor)" className="mb-0">We sent a reset password email to {GetTeamData?.email}. Please click the reset password link to set your new password.</Text>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='row' style={{ paddingTop: '20px' }}>
                                                                                        <div id='delete' className='col col-3'>
                                                                                            <CustomButton
                                                                                                className='ml-1 mt-1'
                                                                                                size='small'
                                                                                                // variant='outlined'
                                                                                                width='60px'
                                                                                                data-dismiss="modal"
                                                                                            // style={{ color: "#798E8F", background: "none" }}
                                                                                            >
                                                                                                Done
                                                                                            </CustomButton>
                                                                                        </div>
                                                                                        <div id='resendEmail' className='col col-8 col-offset-6' style={{ display: 'flex', justifyContent: 'end' }}>
                                                                                            <Text type="3" color="var(--ternaryColor)" fontSize='10px' className="mt-2"><u>Resend Email</u></Text>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>

                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* END */}

                                                                </div>
                                                            </div>
                                                            {/* ///////////////////// */}
                                                            <div className='row' style={field}>
                                                                <div className='col-8'>
                                                                    <InputText
                                                                        name='division'
                                                                        placeholder='Division Name'
                                                                        showCharacters={false}
                                                                        value={GetTeamData?.division}
                                                                        onChange={handleChnage}
                                                                        setIsFlag={setIsFlag}
						                                                IsFlag={IsFlag}
                                                                    // showIcon={true}
                                                                    // iconName="create"
                                                                    // error='Hey, looks like you’ve missed this'
                                                                    />
                                                                    <Icon className='iconContainer'>create</Icon>
                                                                </div>
                                                                <div className='col-4'>
                                                                    <InputText
                                                                        name='teamName'
                                                                        placeholder='Team name'
                                                                        showCharacters={false}
                                                                        value={GetTeamData?.teamName}
                                                                        onChange={handleChnage}
                                                                        setIsFlag={setIsFlag}
						                                                IsFlag={IsFlag}
                                                                    // showIcon={true}
                                                                    // iconName="create"
                                                                    // error='Hey, looks like you’ve missed this'
                                                                    />
                                                                    <Icon className='iconContainer'>create</Icon>
                                                                </div>
                                                            </div>
                                                            <div className='row' style={field}>
                                                                <div className='col-8'>
                                                                    <InputText
                                                                        name='desigination'
                                                                        placeholder='Designation'
                                                                        showCharacters={false}
                                                                        value={GetTeamData?.desigination}
                                                                        onChange={handleChnage}
                                                                        setIsFlag={setIsFlag}
						                                                IsFlag={IsFlag}
                                                                    // showIcon={true}
                                                                    // iconName="create"
                                                                    // error='Hey, looks like you’ve missed this'
                                                                    />
                                                                    <Icon className='iconContainer'>create</Icon>
                                                                </div>
                                                            </div>
                                                        </InputTextContainer>
                                                    </>
                                                </div>
                                                {/* END */}
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                            <div className="modal-footer border-0">
                            </div>
                            {/* <div className='container-fluid'> */}
                            <div className='row'>
                                <div className='col-3 modal-footer border-0 ml-4' style={{ justifyContent: "flex-start" }}>
                                    <CustomButton
                                        className='ml-2 mt-1'
                                        size='small'
                                        // variant='outlined'
                                        width='60px'
                                        onClick={applyTeamChanges}
                                        // onClick={() =>{
                                        //     applyTeamChanges();
                                        //     setTimeout(handleCloseModal,6000);
                                        // }}
                                    // style={{ color: "#798E8F", background: "none" }}
                                    >
                                        Save
                                    </CustomButton>
                                    {/* {isSending && <p>Sending Data...</p>}
                                    {popupVisible && <div className='popup'>Data Sent Successfully!</div>} */}
                                    <CustomButton
                                        className='ml-2 mt-1'
                                        size='small'
                                        variant='outlined'
                                        width='60px'
                                        data-dismiss="modal"
                                    // style={{ color: "#798E8F", background: "none" }}
                                    >
                                        Cancel
                                    </CustomButton>
                                </div>
                                {/* <div className='col-8'>
                                    <CustomButton
                                        className='ml-2 mt-3'
                                        size='small'
                                        variant='outlined'
                                        width='60px'
                                        data-toggle="modal" data-target="#DeleteModalTeam" data-dismiss="modal"
                                    // style={{ color: "#798E8F", background: "none" }}
                                    >
                                        Delete
                                    </CustomButton>
                                </div> */}
                            </div>
                            {/* </div> */}
                        </div>
                    </div>
                </div>

                {/* New Modal for Delete User  */}
                <div className="modal fade" id="DeleteModalTeam" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                    <div className="modal-dialog modal-dialog-centered " role="document" style={{ width: '400px' }}>
                        <div className="modal-content" style={{ borderRadius: "10px" }}>
                            <div className="modal-header border-0" style={{paddingBottom: "0px"}}>
                                <Heading fontSize='18px'>Confirm Delete</Heading>
                                <button type="button" className="close" data-dismiss="modal" data-target='#DeleteModal' aria-label="Close">
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                            <div className="modal-body border-0" style={{ paddingBottom: '20px' }}>
                                <div className='row'>
                                    <div className='col col-12'>
                                        <Text type='7' color="var(--ternaryColor)" className="mb-0">Are you sure you want to delete this Team?</Text>
                                    </div>
                                </div>
                                <div className='row' style={{ paddingTop: '20px' }}>
                                    <div className='col'>
                                        <CustomButton
                                            className='ml-2 mt-1'
                                            size='small'
                                            variant='outlined'
                                            width='60px'
                                            // width='5px'
                                            // height='4px'
                                            fontSize='10px'
                                            style={{ float: "left" }}
                                            data-dismiss="modal"
                                        >
                                            Cancel
                                        </CustomButton>
                                    </div>
                                    <div id='delete' className='col col-4 col-offset-6'>
                                        <CustomButton
                                            className='ml-2 mt-1'
                                            size='small'
                                            // variant='outlined'
                                            width='60px'
                                            data-toggle="modal"
                                            data-target="#UserDeletedModal" data-dismiss="modal"
                                        // style={{ color: "#798E8F", background: "none" }}
                                        >
                                            Delete
                                        </CustomButton>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="modal-footer border-0">
                                <div className='row'>
                                    <div className='col col-4 col-offset-6'>
                                        <Button
                                            className='ml-4'
                                            size='small'
                                            variant='outlined'
                                            width='20px'

                                        >
                                            save changes
                                        </Button>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                </div>

                {/* END */}
            </>
        </div >
    )
}
