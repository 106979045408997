export const REQUEST_ANALYSIS_DATA = 'REQUEST_ANALYSIS_DATA';
export const ANALYSIS_DATA_RECEIVED = 'ANALYSIS_DATA_RECEIVED';
export const REQUEST_ANALYSIS_CSV = 'REQUEST_ANALYSIS_CSV';
export const REQUEST_ANALYSIS_CSV_WITH_CODE = 'REQUEST_ANALYSIS_CSV_WITH_CODE';
export const ANALYSIS_CSV_RECEIVED = 'ANALYSIS_CSV_RECEIVED';
export const ANALYSIS_CSV_RECEIVED_WITH_CODE = 'ANALYSIS_CSV_RECEIVED_WITH_CODE';
export const REQUEST_GRAPH_DATA = 'REQUEST_GRAPH_DATA';
export const GRAPH_DATA_RECEIVED = 'GRAPH_DATA_RECEIVED';
export const REQUEST_WORD_ANSWER = 'REQUEST_WORD_ANSWER';
export const WORD_ANSWER_RECEIVED = 'WORD_ANSWER_RECEIVED';
export const REQUEST_FILTER_DATA = 'REQUEST_FILTER_DATA';
export const FILTER_DATA_RECEIVED = 'FILTER_DATA_RECEIVED';
export const ADD_TO_REPORT = 'ADD_TO_REPORT';
export const FETCH_REPORT = 'FETCH_REPORT';
export const UPDATE_REPORT = 'UPDATE_REPORT';
export const REQUEST_ADD_COHORT = 'REQUEST_ADD_COHORT';
export const ADD_COHORT_RESPONSE = 'ADD_COHORT_RESPONSE';
export const REQUEST_STICH_VIDEO = 'REQUEST_STICH_VIDEO';
export const STICH_VIDEO_RESPONSE = 'STICH_VIDEO_RESPONSE';
export const REQUEST_ANALYSIS_CLIPS_DATA = 'REQUEST_ANALYSIS_CLIPS_DATA';
export const ANALYSIS_CLIPS_DATA_RECEIVED = 'ANALYSIS_CLIPS_DATA_RECEIVED';
export const REQUEST_DELETE_CUSTOMTAG = 'REQUEST_DELETE_CUSTOMTAG';
export const DELETE_CUSTOMTAG_RECEIVED = 'DELETE_CUSTOMTAG_RECEIVED';
