import styled from '@emotion/styled';
import React, { useState, useEffect } from 'react';
import { Button, Heading, Icon } from '.';
import recording from '../images/recording.svg';
import recordingActive from '../images/recording-active.svg';
import onRecording from '../images/onRecording.svg';

import note from '../images/note.svg';
import noteActive from '../images/note-active.svg';
import noteWhite from '../images/note-white.svg';
import {  useDispatch, useSelector } from 'react-redux';
import { REQUEST_CAPTURE_GUIDE } from '../constants/field/GetCaptureGuideConstants';
import { useParams } from 'react-router-dom';

const QuickNoteContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 3px;
`;

const RecordingContainer = styled.div`
	display: flex;
	flex-direction: row;
	background: var(--greyBlueLight);
	align-items: center;
	margin-right: 2px;
	border-top-left-radius: 20px;
	border-bottom-left-radius: 20px;
	position: relative;
`;

const NoteContainer = styled.div`
	border-top-right-radius: 20px;
	border-bottom-right-radius: 20px;
	padding: 2px 7px;

	background: ${(props) =>
		props.active ? 'var(--sapGreen)' : 'var(--greyBlueLight)'};
	img {
		margin-bottom: 2px;
	}
`;
const IconCustom = styled(Icon)`
	font-size: 0.75rem;
	margin-top: 5px;
`;
const TextInput = styled.input`
	background: transparent;
	font-weight: 600;
	width: 80px;
	font-size: 0.75rem;
	color: var(--deepBlue);
	border: 0;

	&:focus-visible {
		border: 0;

		outline: none;
	}
`;

const QuickNoteInputContainer = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 0 3px;
	background: var(--greyBlueLight);
	border-radius: 20px;
	width: 150px;

	.button {
		cursor: pointer;
	}
`;

const Badge = styled.div`
	font-size: 8px;
	font-weight: 500;
	width: 14px;
	height: 14px;
	border-radius: 7px;
	text-align: center;
	padding: 0;
	margin: 0;
	display: flex;
	align-items: center;
	justify-content: center;
	background: var(--greyBlue);
	color: var(--white);

	position: absolute;
	top: -8px;
	right: 2px;
`;
export function QuickNoteInput({ title = 'New 1', active = false, submit }) {
	const [text, setText] = useState(title);

	const onChangeText = (e) => {
		setText(e.target.value);
	};
	const handleSubmit = (e) => {
		e.preventDefault();
		submit(text);
		setText('');
	};

	return (
		<QuickNoteInputContainer>
			<img src={active ? recordingActive : recording} />

			<div style={{ minWidth: 70, paddingRight: 10 }}>
				<div
					className='d-flex '
					style={{
						width: '100px',
						marginBottom: '5px',
						marginLeft: '3px',
						borderBottom: '1px solid var(--sapGreen)',
					}}
				>
					<form onSubmit={handleSubmit}>
						<TextInput value={text} onChange={onChangeText} />
					</form>
					<div className='button' onClick={handleSubmit}>
						<IconCustom color='var(--sapGreen)'>
							subdirectory_arrow_left
						</IconCustom>
					</div>
				</div>
			</div>
		</QuickNoteInputContainer>
	);
}

const AddNoteInputContainer = styled.div`
	background: white;
	border: 1px solid red;
	width: 200px;
	height: 100px;
	position: absolute;
	/*right: 60px;*/
	margin-top: 5px;

	padding-bottom: 1rem;
	margin-bottom: 20px;
	border: 1px solid var(--greyBlue30);
	box-sizing: border-box;
	box-shadow: 2px 3px 7px rgba(0, 0, 0, 0.2);
	border-radius: 5px;
	z-index: 2;
`;

const NoteTextArea = styled.textarea`
	border: 0;
	resize: none;
	font-size: 10px;
	font-weight: 500;
	color: var(--middleGrey);
	width: 100%;

	padding: 0 0.5rem;

	&:focus-visible {
		border: 0;

		outline: none;
	}
`;
const IconContainer = styled.div`
	float: right;
	margin-right: 5px;

	cursor: pointer;
`;
const CustomButton = styled(Button)`
	float: right;
	margin-right: 5px;
	padding: 2px;
	cursor: pointer;
`;

const AddNoteInput = ({ onClose, onSaveNote }) => {
	const [text, setText] = useState('');
	const handleSave = () => {
		onSaveNote(text);
		setText('');
		onClose();
	};
	return (
		<AddNoteInputContainer>
			<IconContainer onClick={onClose}>
				<Icon color='var(--deepBlue)' style={{ fontSize: '12px' }}>
					close
				</Icon>
			</IconContainer>

			<NoteTextArea
				value={text}
				onChange={(e) => {
					setText(e.target.value);
				}}
				placeholder='Add a note new note'
			/>

			<CustomButton
				onClick={handleSave}
				className='px-3 py-1 '
				fontWeight='500'
				fontSize='10px'
				size='small'
				variant='outlined'
				buttonColor='var(--sapGreen)'
			>
				Save
			</CustomButton>
		</AddNoteInputContainer>
	);
};
function QuickNote({ title = 'Hits', active = false, badge, questionId,questionPaperSection_ID,projectRepondentVideo_ID,projectRespondent_ID, setSelectedOption }) {
	const [startRecording, setStartRecording] = useState(false);
	const [addNote, setAddNote] = useState(false);
	const [titleClicked, setTitleClicked] = useState(false);
	const [selectedBadge, setSelectedBadge] = useState('');
	const [array,setArray] = useState([]);
	const loggedUser = useSelector(state => state.LoginReducer);
	const projectId = useSelector(state => state.ProjectReducer.project_ID);
	const [newNote, setnewNote] = useState();
	let { id } = useParams();
	const dispatch = useDispatch();
	//this.videostarttime = localStorage.getItem("videostarttime") == undefined ? "00:00:00" : localStorage.getItem("videostarttime");
	const handleTime = (title) => {
		// active && setStartRecording((prev) => !prev);
		// var today = new Date(),
  //   	time = today.getFullYear().toString() + "-" + (today.getMonth()+1).toString().padStart(2, "0") + "-" + today.getDate().toString().padStart(2, "0") + " " + today.getHours().toString().padStart(2, "0") + ':' + today.getMinutes().toString().padStart(2, "0") + ':' + today.getSeconds().toString().padStart(2, "0");
		// setArray(oldArray => [...oldArray,time] );
		console.log("array",array);
		if(array.length == 2){
		const data2 = {user_ID:loggedUser.userId, question_ID:questionId, startTime:null, endTime:null, questionPaperSection_ID:questionPaperSection_ID,systemStartTime:array[0] , systemEndTime:array[1],projectRepondentVideo_ID:projectRepondentVideo_ID,videoName:newNote !=undefined ? newNote : ""};
		console.log(data2);
		dispatch({ type: 'REQUEST_ADDTIMEVIDEOQUESTION', payload: data2 });
		setnewNote('');
		setTimeout(() => {
			dispatch({
				type: REQUEST_CAPTURE_GUIDE,
				payload: {
				  user_ID: loggedUser.userId,
				  project_ID: id,
				  projectRespondent_ID:projectRespondent_ID
				}
			  });		
		}, 2000);
		
		setArray([]);
		setStartRecording(false);
		} else {
			setStartRecording(true);
		}
		setSelectedOption('');
		if(!startRecording) {
			setSelectedOption(title);
		}

	};
	 
	useEffect(() => {
	  if(titleClicked && array.length > 0) {
	  	handleTime(title);
	  }
	}, [array]);

	useEffect(() => {
	  }, [newNote]);

	return (
		<QuickNoteContainer>
			<RecordingContainer>
				<div
					onClick={() => {
						if(!active) {
							return;
						}
						setTitleClicked(true)
						let today = new Date(),
						time = today.getFullYear().toString() + "-" + (today.getMonth()+1).toString().padStart(2, "0") + "-" + today.getDate().toString().padStart(2, "0") + " " + today.getHours().toString().padStart(2, "0") + ':' + today.getMinutes().toString().padStart(2, "0") + ':' + today.getSeconds().toString().padStart(2, "0");
						setArray(oldArray => [...oldArray,time] );
						// handleTime(title)
					}}
				>
					{startRecording ? (
						<img src={onRecording} />
					) : (
						<img src={active ? recordingActive : recording} />
					)}
				</div>

				<div style={{ minWidth: 90 }}>
					<Heading
						className='pb-0 mb-0 px-1'
						type={3}
						fontWeight='600'
						fontSize='0.75rem'
						color={
							active
								? 'var(--snapGreen)'
								: 'var(--middleGrey)'
						}
					>
						{title}
					</Heading>

					{active && badge && (
						<Badge class='badge'>{badge}</Badge>
					)}
				</div>
			</RecordingContainer>

			<NoteContainer active={addNote}>
				{addNote ? (
					<img
						src={noteWhite}
						onClick={() => {
							active && setAddNote((prev) => !prev);
						}}
					/>
				) : (
					<img
						onClick={() => {
							active && setAddNote((prev) => !prev);
						}}
						src={active ? noteActive : note}
					/>
				)}
				{addNote && (
					<AddNoteInput
						onSaveNote={(note) => {
							console.log("note",note);
							setnewNote(note);
							const data1 = {user_ID:loggedUser.userId, question_ID:questionId, VideoNote:note,questionPaperSection_ID:questionPaperSection_ID,projectRepondentVideo_ID:projectRepondentVideo_ID};
							dispatch({ type: 'REQUEST_ADDNEWNOTE', payload: data1 });
						}}
						onClose={() => setAddNote((prev) => !prev)}
					/>
				)}
			</NoteContainer>
		</QuickNoteContainer>
	);
}

export default QuickNote;
