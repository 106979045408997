import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import Button from './Button';
import Heading from './Heading';
import Card from './Card';
import Icon from './Icon';
import { css } from '@emotion/react'
import { startOfWeek, addDays, format, addWeeks, subWeeks, isSameDay } from 'date-fns'
import { Link } from 'react-router-dom';
import { Text } from '.';
import { useDispatch, useSelector } from 'react-redux';
import { REQUEST_CALENDER_DATA} from '../constants/dashboard/calenderDataConstants';
import {ScrollContainer }  from '../views/Notificaions/common.js';
import profile from '../images/researcher.png'



// import Button from "../../common";


const days = [];
const startDate = startOfWeek(new Date());

for (let i = 0; i < 7; i++){
    days.push(format(addDays(startDate, i+1), 'EEEE'));
}

const WeekContainer = styled.div`
    display: flex;
    justify-content: space-between;
    padding: 0.5rem 1rem;
`

const ArrowStyles = (props) => css`
    background: transparent;
    border: 0;
    display: flex;
    align-items: center;

    .material-icons {
        color: ${ props.color || 'var(--primaryColor)' };
        font-size: 2.5rem;
    }
`


const Prev = styled.button`
    ${ArrowStyles}
`

const Next = styled.button`
    ${ArrowStyles}
`

const DaysContainer = styled.div`
    display: flex;
    justify-content: space-around;
    width: 100%;

`

const Day = styled.div`
    padding: 0.6rem;
    display: flex;
    flex-direction: column;
    min-width: 72px;
    cursor: pointer;

    &.selected,
    :hover {
        background: var(--secondaryColor);
        border-radius: 7px;
    }

    :hover .date,
    :hover .days {
        color: var(--white);
    }

    .date {
        color: var(--quadColor);
    }

    .days {
        font-size: 0.72rem;
    }

    &.selected .date,
    &.selected .days {
        color: var(--white);
    }
`

export default function WeekCalenderCard({ children, onDateSelection }) {
    const loggedUser = useSelector(state => state.LoginReducer);
    const dispatch = useDispatch(); 
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [weekStartDate, setWeekStartDate] = useState(startOfWeek(new Date()));
    const data = useSelector(state => state.calenderDataReducer.data);
    const dates = [];
    const months = [
        'January','February','March','April','May','June',
        'July','August','September','October','November','December'
    ]

   

    const dateFormat = "d";
    // const dayChange = (index) => {

    // }

    for (let i = 0; i < 7; i++){
        dates.push({day: addDays(weekStartDate, (i+1)), formattedDate: format(addDays(weekStartDate, (i+1)), dateFormat), formattedMonth: format(addDays(weekStartDate, (i+1)), 'MMMM'), formattedYear: format(addDays(weekStartDate, (i+1)), 'yyy')});
    }


    return (
        <Card padding="0">
            <div className="d-flex justify-content-between align-items-center p-4">
                <Heading fontWeight="700" type="6" color="var(--quadColor)" className="mb-0">Calendar</Heading>
                <Link to='/calendar' className="underline">
                    <Text type="6">View All</Text>
                </Link>
                
            </div>

            <div style={{textAlign: 'center',padding: '3%',paddingTop:'0px'}}>
                {String(months[selectedDate.getMonth()])+ ' ' + String(selectedDate.getFullYear())}
            </div>

            <WeekContainer>
                <Prev onClick={() => setWeekStartDate(subWeeks(weekStartDate,1))}>
                    <Icon>keyboard_arrow_left</Icon>
                </Prev>
                <DaysContainer>
                   
                    {
                        dates.map((date,index) => <Day key={date.day} onClick={() => {
                            setSelectedDate(date.day);
                            //Dispatch API getCalenderData
                            
                            const year = date.day.getFullYear()
                            console.log(year);
                            const month = String(date.day.getMonth()+1).padStart(2,"0");
                            const day = String(date.day.getDate()).padStart(2,"0");
                            const convertedDate = `${year}-${month}-${day}`;
                           
                            dispatch({
                                type: REQUEST_CALENDER_DATA,
                                payload: {
                                    "user_ID":loggedUser.userId,
                                    "selected_date":convertedDate
                                }
                              });
                                                     
                            
                            onDateSelection(date);
                        }} className={isSameDay(date.day, selectedDate) ? 'selected' : ''}>
                                <Heading type="4" fontWeight="700" className="date mb-0">{date.formattedDate}</Heading>
                                <Text type="5" className="days">{days[index]}</Text>
                            </Day>
                        )
                    }
                </DaysContainer>
                
                <Next onClick={() => setWeekStartDate(addWeeks(weekStartDate,1)) }>
                    <Icon>keyboard_arrow_right</Icon>
                </Next>
               
            </WeekContainer>
            {/* <div style={{'overflowY': 'scroll',height: '250px',margin: '4%'}}> */}
            <ScrollContainer style={{height: '250px',margin: '4%'}}>
                {data != null && data.length != 0 ? data.map((item,index) => (
                    <div key={index} style={{padding: '2%',color:"var(--quadColor)",type: '6'}}>
                        {/* {console.log(data)} */}
                        {item.preAssingedImageUrl === null ? <img style={{borderRadius: '50%',maxWidth: '5%', paddingRight:'1%'}} src={profile} alt='Image'></img> : <img style={{borderRadius: '50%',maxWidth: '5%', paddingRight:'1%'}} src={item.preAssingedImageUrl} alt='Image'></img>}
                          {item.projectName + " - " +  item.message}<b>{" "+ item.name}</b>
                        <Button style={{float: 'right',padding: '4px',paddingLeft: '8px',paddingRight: '8px'}} primary className="pt-1 pb-1" fontSize="0.75rem"  onClick={event => window.location.href = `/project/${item.project_ID}/field`}>Go to field</Button>
                        </div>
                        
                )):
                    <p style={{textAlign: 'center','marginTop':'10%'}}>No meetings scheduled yet</p>
                }
            </ScrollContainer>
                
            {/* </div> */}
            

            {/* {children} */}
        </Card>
    )
}