import actions from '../../actions';

// reducer with initial state
const initialState = {
    GetResponse: null
};
  // const getUsername = data => {
  //   return data.email;
  // };


  const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
      case actions.otpAction.REQUEST_OTP: 
        return {
            ...state
          };
          case actions.otpAction.OTP_RECEIVED:
            console.log(action.data)
            return {
              ...state,
              GetResponse: action.data
            };
      default:
        return state;
    }
  };

  export default reducer;
  