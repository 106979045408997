import styled from '@emotion/styled';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Heading, Icon } from '../../common';
import { NavTab, NavTabs, NavTabsContainer } from '../../common/NewTab';
import PageLoader2 from '../PageLoader2';
import VideoCall from './VideoCall';
import GuidedScreen from './GuidedScreen';
import { useRef } from 'react';
import { REQUEST_CAPTURE_GUIDE } from '../../constants/field/GetCaptureGuideConstants';

const CustomButton = styled(Button)`
  color: var(--sapGreen);
  .material-icons {
    color: var(--sapGreen);
  }
  :hover {
    .material-icons {
      color: var(--white);
    }
  }
  :focus {
    background: transparent;
    color: var(--sapGreen);
    outline: none;
    box-shadow: none;
    :hover {
      background: var(--sapGreen);
      color: var(--white);
    }
  }
  :disabled,
  [disabled] {
    background: transparent;
    border-color: var(--middleGrey);
    color: var(--middleGrey);
    .material-icons {
      color: var(--middleGrey);
    }
    :hover {
      box-shadow: none;
      background: #c2c2c2;
      color: #ffffff;
      cursor: not-allowed;
      .material-icons {
        color: var(--white);
      }
    }
  }
`;

const Container = styled.div`
  padding: 0;
  margin: 0;
  border-bottom: 1px solid var(--secondaryColor);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const CustomNavTabs = styled(NavTabs)`
  transform: translateY(0px);
`;

// const Tooltip = ({ text }) => {
//   const tooltipStyle = {
//     position: 'absolute',
//     bottom: '-68%',
//     left: '75%',
//     transform: 'translateX(-50%)',
//     backgroundColor: 'white',
//     color: 'var(--headingColor);',
//     fontWeight: 'bold',
//     padding: '5px 10px',
//     border: '1px solid white',
//     borderRadius: '4px',
//     fontSize: '14px',
//     whiteSpace: 'nowrap'
//   };

//   return <div style={tooltipStyle}>{text}</div>;
// };

const Tooltip = ({ text }) => {
  const tooltipStyle = {
    zIndex: 10,
    backgroundColor: '#ffffff',
    border: '1px solid #efefef',
    boxSizing: 'border-box',
    boxShadow: '0px 12px 28px rgba(0, 0, 0, 0.15)',
    padding: '5px 10px',
    position: 'absolute',
    transform: 'translateX(-43%) translateY(calc(-100% - 2px))',
    bottom: '-10%', // Position the tooltip correctly
    left: '80%',
    fontWeight: 'bold',
    fontSize: '14px',
    whiteSpace: 'nowrap',
    color: 'var(--headingColor)'
  };

  const triangleStyle = {
    content: '""',
    position: 'absolute',
    bottom: '-10px',
    left: '50%',
    marginLeft: '-10px',
    borderWidth: '5px',
    borderStyle: 'solid',
    borderColor: '#ffffff transparent transparent transparent'
  };

  return (
    <div style={tooltipStyle}>
      {text}
      <div style={triangleStyle}></div>
    </div>
  );
};

function InterviewTab({
  callOn,
  setStep,
  setCallOn,
  respondantInfo,
  projectRespondent_ID,
  id,
  setRespondantActive,
  InterviewSchedule_ID,
  fullScreen,
  cleanup,
  getAudioVideoState,
  setShowPrecallTest
}) {
  const userId = useSelector(state => state.LoginReducer.userId);
  const captureguideData = useSelector(state => state.GetCaptureGuideReducer.captureguideData);
  const dispatch = useDispatch();
  const [showValidationMessages, setShowValidationMessages] = useState(false);
  const [isSectionComplete, setIsSectionComplete] = useState(false);
  const [isSectionCompleteTab, setIsSectionCompleteTab] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [guideStep, setGuideStep] = useState(1);
  const [clickedDisabledTab, setClickedDisabledTab] = useState(null);
  

  const [selectedOptionData, setSelectedOptionData] = useState([]);
  const [selectedTextValue, setSelectedTextValue] = useState([]);
  const transcriptedData = useSelector(state => state.SendTranscriptReducer.transcriptData);
  //console.log("data", transcriptedData)
  const messageContainerRef = useRef(null);

  const data = { user_ID: userId };

  useEffect(() => {
    dispatch({
      type: 'GET_RESOLUTION',
      payload: data
    });
  });

  //  btnClickTranscriptData = () => {
  //   dispatch({
  //     type: 'GET_RTM_TRANSCRIPT_DATA',
  //     payload: data
  //   });
  // }

  // const handleTabClick = index => {
  //   setGuideStep(index + 1); // Always update guideStep
  //   if (callOn && !isSectionCompleteTab && !isSectionComplete && respondantInfo?.isInterviewStart == 1) {
  //     setShowTooltip(true);
  //     setTimeout(() => setShowTooltip(false), 3000);
  //   }
  // };

  useEffect(() => {
    if (callOn && respondantInfo?.isInterviewStart == 1) {
      setGuideStep(1);
    }
  }, [callOn, respondantInfo?.isInterviewStart]);
  

  useEffect(() => {
    localStorage.setItem(
      'channel',
      respondantInfo?.agoraChannelName == undefined ? 'Test' : respondantInfo?.agoraChannelName
    );
    //localStorage.setItem('channel', respondantInfo?.agorachannelname == undefined ? 1806092021063926 : respondantInfo?.agorachannelname);
    localStorage.setItem('baseMode', 'avc');
    localStorage.setItem('transcode', 'interop');
    localStorage.setItem('attendeeMode', 'video');
    localStorage.setItem('videoProfile', '480p_4');
    localStorage.setItem('projectRespondent_ID', projectRespondent_ID);
    localStorage.setItem(
      'InterviewSchedule_ID',
      InterviewSchedule_ID != undefined ? InterviewSchedule_ID : InterviewSchedule_ID
    );
  });

  const scrollToBottom = () => {
    if (messageContainerRef.current && guideStep == 2000) {
      messageContainerRef.current.scrollTop = messageContainerRef.current.scrollHeight;
    }
  };

  const callCaptureGuide = () => {
    dispatch({
      type: REQUEST_CAPTURE_GUIDE,
      payload: {
        user_ID: userId,
        project_ID: id,
        projectRespondent_ID: projectRespondent_ID
      }
    });
    console.log('Mayur called Capture Guide');
  };

  return (
    <div className="row px-3 py-2">
      <div className={`${fullScreen ? 'col-md-8' : 'col-md-7'}`}>
        <VideoCall
          setRespondantActive={setRespondantActive}
          setCallOn={setCallOn}
          setStep={setStep}
          callOn={callOn}
          agoraChannelName={respondantInfo?.agoraChannelName}
          respondantInfo={respondantInfo}
          id={id}
          fullScreen={fullScreen}
          cleanup={cleanup}
          getAudioVideoState={getAudioVideoState}
          setShowPrecallTest={setShowPrecallTest}
        />
      </div>
      <div className={`${fullScreen ? 'col-md-4' : 'col-md-5'}`} style={fullScreen ? { padding: 0 } : {}}>
        <Heading type={3} className="mb-0 pb-0 mt-4" fontWeight="700" fontSize="0.75rem" color="var(--deepBlue)">
          Capture Guide
        </Heading>
        {captureguideData ? (
          <Container className="mt-2" style={{ alignItems: 'end' }}>
            <NavTabsContainer>
              <CustomNavTabs>
                {captureguideData?.captureGuide?.map((section, sIndex) => (
                  <div key={sIndex} style={{ position: 'relative' }}>

                    <NavTab
                     // className={sIndex === 0 ? '' : ''}
                       className={sIndex + 1 === guideStep ? 'active' : ''}
                      fontSize="0.73rem"
                      active={sIndex + 1 === guideStep}
                      onClick={() => {
                        if (callOn && respondantInfo?.isInterviewStart == 1) {
                          if (guideStep === 2000 && sIndex === 0) {
                            // Allow navigation from guideStep 2000 to guideStep 1
                            setGuideStep(1);
                          }
                        } else {
                          // Allow normal navigation when callOn && !respondentInfo?.isInterviewStart == 1 or when !callOn
                          setGuideStep(sIndex + 1);
                        }
                      }}
                  
                      disabled={callOn && respondantInfo?.isInterviewStart == 1 && !(guideStep === 2000 && sIndex === 0) && sIndex + 1 !== guideStep}
                  
                    >
                      {section.sectionName}
                    </NavTab>
                  </div>
                ))}
              </CustomNavTabs>
            </NavTabsContainer>
            <NavTabsContainer>
              <CustomNavTabs>
                <NavTab fontSize="0.73rem" active={2000 === guideStep} onClick={() => setGuideStep(2000)}>
                  Transcript
                </NavTab>
              </CustomNavTabs>
            </NavTabsContainer>
          </Container>
        ) : (
          <>
            <Button
              className="mt-2"
              size="small"
              onClick={callCaptureGuide}
              variant="fill"
              style={{ color: 'white' }}
              buttonColor="var(--sapGreen)"
            >
              Request Capture Guide
            </Button>
            <PageLoader2 />
            {/* {callCaptureGuide}   */}
          </>
        )}
        {captureguideData ? (
          <div
            style={{
              minHeight: 480,
              borderBottom: '1px solid var(--greyBlue)',
              maxHeight: 450,
              overflowY: 'auto',
              position: 'relative'
            }}
            ref={messageContainerRef}
            className="py-2"
          >
            <GuidedScreen
              setSelectedOptionData={setSelectedOptionData}
              selectedOptionData={selectedOptionData}
              setSelectedTextValue={setSelectedTextValue}
              selectedTextValue={selectedTextValue}
              step={guideStep}
              callOn={callOn == 1 && respondantInfo?.isInterviewStart == 1 ? true : false}
              id={id}
              respondantInfo={respondantInfo}
              fullScreen={fullScreen}
              transcriptedData={transcriptedData}
              scrollToBottom={scrollToBottom}
              showValidationMessages={showValidationMessages}
              setIsSectionComplete={setIsSectionComplete}
              isSectionComplete={isSectionComplete}
              setShowValidationMessages={setShowValidationMessages}
              setIsSectionCompleteTab = {setIsSectionCompleteTab}
            />
          </div>
        ) : (
          ''
        )}
        {captureguideData ? (
          <div
            className={`py-2 d-flex ${
              callOn && guideStep == captureguideData?.captureGuide?.length
                ? 'justify-content-between'
                : 'justify-content-end '
            }  align-items-center`}
          >
            {callOn && guideStep == captureguideData?.captureGuide?.length && (
              <div>
                <Heading type={3} fontSize="0.75rem" fontWeight="500" color="var(--seaBlue)" className="">
                  You’ve reached the end of the capture guide
                </Heading>
              </div>
            )}

            <div className="d-flex justify-content-end align-items-center">
              
            {guideStep !== 2000 && (
              <CustomButton
                fontWeight="500"
                fontSize="0.75rem"
                size="small"
                variant="outlined"
                buttonColor="var(--sapGreen)"
                disabled={guideStep == 1 ? true : false}
                onClick={() => {
                  console.log("GuideStep", guideStep)
                  if (guideStep === captureguideData?.captureGuide?.length && callOn && !isSectionComplete && respondantInfo?.isInterviewStart == 1) {
                    setShowValidationMessages(true);
                  } else {
                    setGuideStep(prev => prev - 1);
                  }
                }}
              >
                <Icon style={{ fontSize: 10 }}>arrow_back_ios</Icon> Back
              </CustomButton>
            )}
              {guideStep !== 2000 && (
              <CustomButton
                className="ml-2"
                fontWeight="500"
                fontSize="0.75rem"
                size="small"
                buttonColor="var(--sapGreen)"
                variant="outlined"
                disabled={guideStep === captureguideData?.captureGuide?.length}
                onClick={() => {
                  console.log("GuideStep", guideStep)
                  if (callOn  && respondantInfo?.isInterviewStart == 1) {
                    if (!isSectionComplete) {
                      setShowValidationMessages(true);
                    } else {
                      setTimeout(() => {
                        if (isSectionComplete) {
                          setGuideStep(prev => prev + 1);
                        }
                      }, 0);
                    }
                  } else {
                 
                    setGuideStep(prev => prev + 1);
                  }
                }}
              >
                Next <Icon style={{ fontSize: 10 }}>arrow_forward_ios</Icon>
              </CustomButton>
              )}
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default InterviewTab;
