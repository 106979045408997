import React from 'react';
import { Text, Card } from '../../common';
import { InputText } from '../../components/form';
import styled from '@emotion/styled';
import {  useDispatch, useSelector } from 'react-redux';
import { useState, useEffect } from 'react';

const NotesContainer = styled.div`
	max-height: ${(props) => (props.height ? props.height : '89px')};
	height:${(props)=>props.height && props.height };
	transition: 3s;
	.scrollable {
		overflow-y: auto;
		max-height: ${(props) => (props.maxHeight ? props.maxHeight : '85px')};
		scrollbar-width: thin;
	}

	li {
		margin-top: 0.25em;
	}
`;

// const tags = [
// 	{ tag: 'Readability', time: '02:15' },
// 	{ tag: 'Style Preference', time: '03:15' },
// 	{ tag: 'Content Discoverability', time: '04:15' },
// 	{ tag: 'Paused Action', time: '05:15' },
// 	{ tag: 'Tone of Voice', time: '04:15' }
// 	// { tag: 'Readability', time: '07:15' },
// 	// { tag: 'Readability', time: '07:15' },
// 	// { tag: 'Readability', time: '07:15' },
// 	// { tag: 'Readability', time: '07:15' },
// ];

export function Notes({ height, maxHeight, displayTitle = true, className, type = 'note' ,childToParent}) {
	console.log("ghjfhgkh",typeof childToParent);
	const loggedUser = useSelector(state => state.LoginReducer);
	const project_ID = useSelector(state => state.ProjectReducer.project_ID);
	const dispatch = useDispatch(); 
	const data = {user_ID : loggedUser.userId, project_ID: 76}
	useEffect(() => {
		dispatch({ type: 'REQUEST_GETVIDEOTAG', payload: data });
		dispatch({ type: 'REQUEST_GETVIDEONOTE', payload: data });

	},[]);
	
	const videoTag = useSelector(state => state.GetVideoTagReducer.videoTag);
	const videoNote = useSelector(state => state.GetVideoNoteReducer.videoNote);

	const childToParent1 = (data) => {
		console.log("tag click",data);
		if(typeof childToParent != undefined){
		childToParent(data);
		}
	  };

	return (
		<div>
			{displayTitle && (
				<React.Fragment>
					<Text type="5" fontSize="18px" fontWeight="600" color="var(--seaBlue)">
						Notes
					</Text>
					<Text type="3" fontSize="12px" fontWeight="300" color="var(--seaBlue)">
						Add & Edit notes at specific moments in the video
					</Text>
				</React.Fragment>
			)}
			<Card className={` ${className}`}>
				<NotesContainer height={height} maxHeight={maxHeight}>
					<div className="scrollable">
						{type == 'tag' &&
							videoTag?.map((data, index) => (
								<div className="d-flex mb-2" key={`tags-${index}`} onClick={() => childToParent1(data)}>
									<Text type="3" fontSize="10px" fontWeight="300">
										{data.created_on} -
									</Text>
									<Text type="4" fontSize="10px" className="ml-1">
										{data.projectTagName}
									</Text>
								</div>
							))}
						{type == 'note' && (
							<React.Fragment>
								{videoNote?.map((data, index) => (
								<div className="d-flex mb-2" key={`tags-${index}`}>
									<Text type="3" fontSize="10px" fontWeight="300">
										{data.created_on} -
									</Text>
									<Text type="4" fontSize="10px" className="ml-1">
										{data.note}
									</Text>
								</div>
								))}
								{/* <div className="d-flex mb-2">
									<Text type="3" fontSize="10px" fontWeight="300">
										03:15 -
									</Text>
									<Text type="4" fontSize="10px" className="ml-1">
										Smooth process of using product
									</Text>
								</div>
								<div className="d-flex mb-2">
									<Text type="3" fontSize="10px" fontWeight="300">
										14:15 -
									</Text>
									<Text type="4" fontSize="10px" className="ml-1">
										Inconvenient to store it
									</Text>
								</div>
								<div className="d-flex mb-2">
									<Text type="3" fontSize="10px" fontWeight="300">
										18:15 -
									</Text>
									<Text type="4" fontSize="10px" className="ml-1">
										Intuitive
									</Text>
								</div> */}
							</React.Fragment>
						)}
					</div>
				</NotesContainer>
			</Card>
		</div>
	);
}
