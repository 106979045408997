import { Provider, useDispatch, useSelector } from 'react-redux';
import { Store, persistor } from './config/sagaConfig';
import { PersistGate } from 'redux-persist/integration/react';
import React, { useState, useEffect, Suspense, lazy } from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect, useLocation } from 'react-router-dom';
import './App.css';
import { Nav, AppNav, SideNav, Spinner } from './common';
import SideNav_SA from './common/SideNav_SA';
import SideNav_A from './common/SideNav_A';
import SideNav_Respondent from './common/SideNav_Respondent';
import { BeARespondentScreen, HowItWorksScreen, LandingScreen } from './views/StaticScreen';
import SignInScreen from './views/SignInScreen/SignInScreen';
import DashboardScreen from './views/DashboardScreen/DashboardScreen';
import DashboardScreen_Respondent from './views/DashboardScreen/RespondentdashboardScreen';
import EmptyDashboardScreen from './views/DashboardScreen/EmptyDashboardScreen';
// import SetupScreen from './views/Project/SetupScreen';
// import EditSetupScreen from './views/Project/EditSetupScreen';
// import CriteriaBudgetScreen from './views/Project/CriteriaBudgetScreen';
// import SetupSummary from './views/Project/SetupSummary';
import DesignScreen from './views/Project/DesignScreen';
import InviteRecruit from './views/Project/RecruitInvite';
import EmptyRecruitInvite from './views/Project/EmptyRecruitInvite';
// import ScheduleScreen from './views/Project/ScheduleScreen';
// import FieldsScreen from './views/Project/FieldsScreen';
import ViewClipScreen from './views/Project/ViewClipsScreen';
import PreVideoCallScreen from './views/Project/PreVideoCallScreen';
import VideoCallScreen from './views/Project/VideoCallScreen';
import CallClipping from './views/Project/VideoClipScreen';
import Projects from './views/Project/Projects';
import PreCallScreen from './views/Project/PreCallScreen';
import AnalysisScreen from './views/analysis/AnalysisScreen';
import Help from './common/Help';
import ProfileScreen from './views/Profile/ProfileScreen';
import SecurityScreen from './views/Profile/SecurityScreen';
import AnalysisReport from './views/analysis/AnalysisReport';
import NotificationScreen from './views/Notificaions/NotificationScreen';
// import CalendarScreen from './views/Calendar/CalendarScreen';
import { createBrowserHistory } from 'history';
import '../node_modules/video-react/dist/video-react.css';
import DownloadReport from './views/analysis/DownloadReport';
import VerifyCode from './views/VerifyCode/VerifyCode';
import ResetPassword from './views/ResetPassword/ResetPassword';
import DashboardScreen_SA from './views/super_admin/DashboardScreen_SA/DashboardScreen_SA';
import DashboardScreen_A from './views/admin/DashboardScreen_A/DashboardScreen_A';
import ResetPasswordEmailNotification from './views/ResetPassword/ResetPasswordEmailNotification';
import PreCallScreenResp from './views/Project/PreCallScreenResp';
import AppNavResp from './common/AppNavResp';
import AppNavAdmin from './common/AppNavAdmin';
import AppNavSA from './common/AppNavSA';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import PageLoader from './views/PageLoader';
import { func } from 'prop-types';
import RenderDashboard from './RenderDashboard';
import { sort } from 'd3';
// import {useNavigate} from 'react-router-dom';
const EditSetupScreen = lazy(() => import('./views/Project/EditSetupScreen'));
const SetupScreen = lazy(() => import('./views/Project/SetupScreen'));
const FieldsScreen = lazy(() => import('./views/Project/FieldsScreen'));
const CriteriaBudgetScreen = lazy(() => import('./views/Project/CriteriaBudgetScreen'));
const SetupSummary = lazy(() => import('./views/Project/SetupSummary'));
const ScheduleScreen = lazy(() => import('./views/Project/ScheduleScreen'));

const history = createBrowserHistory({ forceRefresh: true });

const AuthScreen = () => {
  const value = useSelector(state => state.LoginReducer.roleId);
  console.warn(value);

  useEffect(() => {
    console.log({ value });
    if (value == 5) {
      history.push('/dashboard-super-admin');
    } else if (value == 3) {
      history.push('/dashboard-admin');
    } else if (value == 4) {
      history.push('/dashboard-respondent');
    } else if (value == 1) {
      history.push('/dashboard');
    }
  }, [value]);

  return (
    <div>
      <Nav />

      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}
      <Switch>
        <Route path="/how-it-works">
          <HowItWorksScreen />
        </Route>
        <Route path="/be-a-respondent">
          <BeARespondentScreen />
        </Route>
        <Route path="/sign-in">
          <SignInScreen />
        </Route>
        <Route path="/verify-code/:email">
          <VerifyCode />
        </Route>
        <Route path="/reset-password/:email">
          <ResetPassword />
        </Route>
        <Route path="/reset-password-via-link/:token">
          <ResetPasswordEmailNotification />
        </Route>
        <Route path="/">
          <LandingScreen />
        </Route>
      </Switch>
    </div>
  );
};

const AppScreen = () => {
  const loc = useLocation();
  // console.warn(loc.pathname);
  const urlSegments = loc.pathname.split('/');
  const lastPath = urlSegments[urlSegments.length - 1];
  // console.log(lastPath);
  // const isloggedIn = useSelector(state => state.LoginReducer.isloggedIn);
  const history = useHistory();
  const dispatch = useDispatch();

  //Sageer's code
  // const value = useSelector(state => state.LoginReducer.roleId);
  const value = useSelector(state => state.LoginReducer);
  // const isLoggedIn = Store.getState().LoginReducer.isLoggedIn;
  // useEffect(() => {
  // 	const handleRedirect = () => {
  // 		const currentPath = window.location.pathname;
  // 		if (isLoggedIn && currentPath === '/') {
  // 			const roleId = Store.getState().LoginReducer.roleId;
  // 			window.location.replace(`/dashboard?$loginInfo=${roleId}`);
  // 		}
  // 	};
  // 	handleRedirect();
  // }, [isLoggedIn]);

  function Loading() {
    return <PageLoader size="100px" />;
  }

  return (
    <div className="admin">
      {/* <Help /> */}
      {loc.pathname == '/dashboard-super-admin' ? (
        ''
      ) : loc.pathname == '/dashboard-admin' ? (
        ''
      ) : loc.pathname === '/dashboard-respondent' || lastPath === 'pre-call-resp' ? (
        ''
      ) : (
        <Help />
      )}
      {/* <AppNav /> */}
      {loc.pathname == '/dashboard-super-admin' ? (
        <AppNavSA />
      ) : loc.pathname == '/dashboard-admin' ? (
        <AppNavAdmin />
      ) : loc.pathname === '/dashboard-respondent' || lastPath === 'pre-call-resp' ? (
        <AppNavResp />
      ) : (
        <AppNav />
      )}
      {loc.pathname == '/dashboard-super-admin' ? (
        <SideNav_SA />
      ) : loc.pathname == '/dashboard-admin' ? (
        <SideNav_A />
      ) : loc.pathname === '/dashboard-respondent' || lastPath === 'pre-call-resp' ? (
        <SideNav_Respondent />
      ) : (
        <SideNav />
      )}
      {/* {(loc.pathname == '/dashboard-admin') ? <SideNav_A /> : <SideNav />} */}

      {/* A <Switch> looks through its children <Route>s and
          renders the first one that matches the current URL. */}

      <Switch>
        {/* {isLoggedIn || localStorage.getItem('token') ? (
					<Route path='/' render={() => <RenderDashboard  />}/>
				) :
					(
						<Route path="/sign-in" component={SignInScreen}/>
					)
				} */}
        {/* Sageer's code for redirection */}
        <Route exact path="/">
          <RenderDashboard roleId={value.roleId} isloggedIn={value.isloggedIn} />
        </Route>

        <Route path="/dashboard">
          {/* {history.location.pathname !== '/dashboard' ? history.replace('/dashboard') : ''} */}
          <DashboardScreen />
          <RenderDashboard roleId={value.roleId} isloggedIn={value.isloggedIn} />
        </Route>

        <Route path="/empty-dashboard">
          <EmptyDashboardScreen />
        </Route>
        <Route exact path="/project">
          <Projects />
        </Route>
        <Route path="/project/create">
          <Suspense fallback={<Loading />}>
            <SetupScreen />
          </Suspense>
        </Route>

        {/* {showLoader && <Loading/>}   */}
        {/* <Suspense fallback= {<Loading/>}> */}
        <Route path="/project/:projectId/edit">
          <Suspense fallback={<Loading />}>
            <EditSetupScreen />
          </Suspense>
        </Route>

        <Route path="/project/:projectId/criteria-and-budget">
          <Suspense fallback={<Loading />}>
            <CriteriaBudgetScreen />
          </Suspense>
        </Route>

        <Route path="/project/:projectId/publish">
          <Suspense fallback={<Loading />}>
            <SetupSummary />
          </Suspense>
        </Route>
        {/* recruit path */}
        <Route path="/project/:projectId/invite-recruit">
          <InviteRecruit />
        </Route>
        <Route path="/project/1/empty-invite-recruit">
          <EmptyRecruitInvite />
        </Route>
        <Route path="/project/:projectId/design">
          <DesignScreen />
        </Route>
        <Route path="/project/:projectId/schedule">
          <Suspense fallback={<Loading />}>
            <ScheduleScreen />
          </Suspense>
        </Route>
        <Route path="/project/:id/field">
          <Suspense fallback={<Loading />}>
            <FieldsScreen />
          </Suspense>
        </Route>
        <Route path="/project/:id/pre-call">
          <PreCallScreen />
        </Route>
        {/* respondent Call screen start */}
        <Route path="/project/:id/pre-call-resp">
          <PreCallScreenResp />
        </Route>
        {/* END */}
        <Route path="/project/1/view-clips">
          <ViewClipScreen />
        </Route>
        <Route path="/project/1/pre-video-call">
          <PreVideoCallScreen />
        </Route>
        <Route path="/project/1/video-call">
          <VideoCallScreen />
        </Route>
        <Route path="/project/1/clipping">
          <CallClipping />
        </Route>
        <Route path="/project/:id/analysis/report">
          <AnalysisReport />
        </Route>
        <Route path="/project/:id/analysis">
          <AnalysisScreen />
        </Route>
        <Route path="/notifications">
          <NotificationScreen />
        </Route>
        <Route path="/profile">
          <ProfileScreen />
        </Route>
        <Route path="/security">
          <SecurityScreen />
        </Route>
        {/* <Route path='/calendar'>
					<CalendarScreen />
				</Route> */}
        <Route path="/project/export">
          <DownloadReport />
        </Route>

        {/* Routes for Super admin Screen  ******START******* */}

        <Route path="/dashboard-super-admin">
          <DashboardScreen_SA />
          <RenderDashboard roleId={value.roleId} isloggedIn={value.isloggedIn} />
          {/* {renderDashboard()} */}
        </Route>

        {/* {***********END***********} */}

        {/* Routes for Admin Screen  ******START******* */}

        <Route path="/dashboard-admin">
          <DashboardScreen_A />
          <RenderDashboard roleId={value.roleId} isloggedIn={value.isloggedIn} />
          {/* {renderDashboard()} */}
        </Route>

        {/* {***********END***********} */}

        {/*************dashboard screen for respondent******** */}
        <Route path="/dashboard-respondent">
          {/* {history.location.pathname !== '/dashboard-respondent' ? history.replace('/dashboard-respondent'): ''} */}
          <DashboardScreen_Respondent />
          <RenderDashboard roleId={value.roleId} isloggedIn={value.isloggedIn} />
        </Route>
        {/*************END******** */}
      </Switch>
    </div>
  );
};

export default function App() {
  const { isloggedIn } = Store.getState().LoginReducer;

  return (
    <Router>
      <Provider store={Store}>
        <PersistGate loading={null} persistor={persistor}>
          <Switch>{isloggedIn || localStorage.getItem('token')?.length > 0 ? <AppScreen /> : <AuthScreen />}</Switch>
        </PersistGate>
      </Provider>
    </Router>
  );
}
