import { all, putResolve, takeLatest } from 'redux-saga/effects';
import actions from '../../actions';
import * as generateSummaryMethods from '../../utils/api/analysis/generateSummary';

function* generateSummary(payload) {
  const promise = generateSummaryMethods.generateSummary(payload.payload);
  const { res } = yield promise;
  yield putResolve({ type: actions.generateSummaryActions.GENERATE_SUMMARY_DATA_RECEIVED, data: res.data });
}
function* storeAISummaryLogs(payload) {
  const promise = generateSummaryMethods.storeAISummaryLogs(payload.payload);
  const { res } = yield promise;
  console.log("PROMISE",promise)
  yield putResolve({ type: actions.generateSummaryActions.AI_LOGS_RECEIVED, data: res.data });
}

export  { generateSummary,storeAISummaryLogs }