import maverickPortalhttp from '../../webService/webService';
import maverickPortalApiEndPoint from '../../../config/apiEndPoint';
import { createBrowserHistory } from 'history';
import { CONFIRM_RESPONDENT_RESPONSE } from '../../../constants/invite-recruit/ConfirmRespondentConstants';

const history = createBrowserHistory({ forceRefresh: true });
export let showOnAnalysisResponse;
const getProjectTask = data => {
  let params = data.payload;
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        `${maverickPortalApiEndPoint.getProjectTask.url}/${params.user_ID}/${params.project_ID}/${params.projectTask_ID}`,
        maverickPortalApiEndPoint.getProjectTask.methodType
      )
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log('DESIGN', response.data);
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }

        resolve({ res });
      });
  });
};

const getVideoTask = data => {
  let params = data.payload;
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        `${maverickPortalApiEndPoint.getVideoTask.url}/${params.user_ID}/${params.project_ID}/${params.projectTask_ID}`,
        maverickPortalApiEndPoint.getVideoTask.methodType
      )
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }

        resolve({ res });
      });
  });
};

const deleteQuestion = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.deleteQuestion.url,
        maverickPortalApiEndPoint.deleteQuestion.methodType,
        data
      )
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }

        resolve({ res });
      });
  });
};
const deleteSection = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.deleteSection.url,
        maverickPortalApiEndPoint.deleteSection.methodType,
        data
      )
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }

        resolve({ res });
      });
  });
};
const addQuestion = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(maverickPortalApiEndPoint.addQuestion.url, maverickPortalApiEndPoint.addQuestion.methodType, data)
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }

        resolve({ res });
      });
  });
};
const showScreenerFilterOnAnalysis = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.showScreenerFilterOnAnalysis.url,
        maverickPortalApiEndPoint.showScreenerFilterOnAnalysis.methodType,
        data
      )
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        showOnAnalysisResponse = res;
        resolve({ res });
      });
  });
};

const editTaskQuestion = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.editTaskQuestion.url,
        maverickPortalApiEndPoint.editTaskQuestion.methodType,
        data
      )
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        resolve({ res });
      });
  });
};

const updateProjectScreener = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.updateProjectScreener.url,
        maverickPortalApiEndPoint.updateProjectScreener.methodType,
        data
      )
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        resolve({ res });
      });
  });
};

const addScreenerValidationQuestion = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.addScreenerValidationQuestion.url,
        maverickPortalApiEndPoint.addScreenerValidationQuestion.methodType,
        data
      )
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        resolve({ res });
      });
  });
};

const addQuestionTags = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.addQuestionTags.url,
        maverickPortalApiEndPoint.addQuestionTags.methodType,
        data
      )
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        resolve({ res });
      });
  });
};

const deleteQuestionTag = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.deleteQuestionTag.url,
        maverickPortalApiEndPoint.deleteQuestionTag.methodType,
        data
      )
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        resolve({ res });
      });
  });
};

const DeleteFormTagData = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.deleteFormTag.url,
        maverickPortalApiEndPoint.deleteFormTag.methodType,
        data
      )
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        resolve({ res });
      });
  });
};

const editVideoQuestion = data => {
  return new Promise(function request(resolve) {
    maverickPortalhttp
      .makeRequest(
        maverickPortalApiEndPoint.editVideoQuestion.url,
        maverickPortalApiEndPoint.editVideoQuestion.methodType,
        data
      )
      .then(response => {
        const res = response.data;
        if (response.data.Response !== undefined) {
        } else if (response.data?.Status && response.data?.Status.StatusCode === -2) {
          localStorage.removeItem('token');
          history.push('/#/sign-in');
          console.log(response.data?.Status.StatusDesc);
          resolve({ res });
        } else {
          console.log(
            response.data?.Status && response.data?.Status.StatusDesc ? response.data?.Status.StatusDesc : 'Error'
          );
        }
        resolve({ res });
      });
  });
};

export {
  getProjectTask,
  editTaskQuestion,
  updateProjectScreener,
  addScreenerValidationQuestion,
  addQuestionTags,
  deleteQuestionTag,
  DeleteFormTagData,
  getVideoTask,
  deleteQuestion,
  deleteSection,
  addQuestion,
  editVideoQuestion,
  showScreenerFilterOnAnalysis
};
