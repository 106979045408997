import { Link } from 'react-router-dom';
import styled from '@emotion/styled';
import logo from '../assets/images/logo.svg';
import Button from './Button';
import { useHistory } from 'react-router-dom';
import { Icon } from '.';

const StyledNav = styled.nav`
	border-radius: 0;
	box-shadow: 1px 5px 12px rgba(0, 0, 0, 0.2);
	background: var(--grey5);
	.nav-item:last-child {
		margin-left: 1rem;
	}
`;

export default function Nav() {
	const history = useHistory();

	console.log(history.location.pathname);

	return (
		<StyledNav className='navbar navbar-expand-lg fixed-top p-1 mb-5'>
			<div className='container-fluid'>
				<Link className='navbar-brand' to='/'>
					<img src={logo} alt='Maverick Logo' />
				</Link>
				<button
					className='navbar-toggler'
					type='button'
					data-toggle='collapse'
					data-target='#navbarSupportedContent'
					aria-controls='navbarSupportedContent'
					aria-expanded='false'
					aria-label='Toggle navigation'
				>
					<Icon color='var(--secondaryColor)'>menu</Icon>
					{/* <span className='navbar-toggler-icon'></span> */}
				</button>

				<div
					className='collapse navbar-collapse'
					id='navbarSupportedContent'
				>
					<ul className='navbar-nav ml-auto  text-right'>
						{/* <li className="nav-item">
                            <Link to='/how-it-works'>
                                <Button simple fontSize="1rem" color="var(--secondaryColor)" className="p-3">How it Works</Button>
                            </Link>
                        </li> */}
						{history.location.pathname === '/' && (
							<li className='nav-item'>
								<Link to='/be-a-respondent'>
									<Button
										simple
										fontSize='1rem'
										color='var(--secondaryColor)'
										className='p-3'
									>
										Be A Respondent
									</Button>
								</Link>
							</li>
						)}
						{history.location.pathname ===
							'/be-a-respondent' && (
							<li className='nav-item'>
								<Link to='/'>
									<Button
										simple
										fontSize='1rem'
										color='var(--secondaryColor)'
										className='p-3'
									>
										How it Works
									</Button>
								</Link>
							</li>
						)}
						<li className='nav-item'>
							<Link to='/sign-in' className='mt-1'>
								<Button
									fontSize='18px'
									fontWeight='700'
									buttonColor='var(--sapGreen)'
									size='small'
									padding='0.375rem 1.525rem'
								>
									Sign In
								</Button>
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</StyledNav>
	);
}
