import React, { useEffect, useState } from 'react';
import { components } from 'react-select';
import { Heading, Icon, Button } from '../../common';
import { Dropdown } from '../../components/form';
import DropdownGreen from '../../images/chevron-down-green.svg';
import BarChart from './charts/dthree/barchart';
import StackedBarChart from './charts/StackedBarChart';

import {
	data,
	keys,
	colors,
	groupedData2,
	groupedData1,
	stackedBarChartData,
	colorData,
} from './charts/temp_data';
import GroupedStackedBarChart from './charts/dthree/stackedchart';
import Legend from './charts/Legend';
import useWindowDimensions from '../../common/useWindowDimensions';
import VerticalStakedBarChart from './charts/dthree/verticalstack';
import {  useDispatch ,useSelector} from 'react-redux';
import { REQUEST_GRAPH_DATA } from '../../constants/analysis/analysisConstants';
import styled from '@emotion/styled';
import copy_active from '../../images/copy_active.svg';
import SummaryTextContainer from '../../common/SummaryTextContainer';
import loading from '../../assets/images/loading.svg';


// const barChartData = [
// 	{ x: 'After Every Wash', y: 13 },
// 	{ x: 'Daily', y: 9 },
// 	{ x: 'Special Occasions', y: 8 },
// ];

const CustomButton = styled(Button)`
	display: flex;
	align-items: center;
	justify-content: center;
	border: none;
	background: var(--midWhite);
	color: var(--sapGreen);
	border-radius: 3px;
	padding: 0.4rem 0.9rem;
	img {
		padding-right: 5px;
	}
	:hover {
		background: var(--midWhite);
		color: ${(props) => props.buttonColor};
	}

	:disabled,
	[disabled] {
		background: var(--midWhite);
		border: none;
		color: var(--middleGrey);

		:hover {
			background: var(--midWhite);
			border: none;
			color: var(--middleGrey);
			cursor: not-allowed;
		}
	}
`;


const product_types = [
	{ value: 1, label: 'None' },
	{ value: 2, label: 'New' },
];

const legendList = [
	{
		color: '#2361A1',
		label: 'Basic',
	},
	{
		color: '#E55555',
		label: 'Travel',
	},
	{
		color: '#A6A6A6',
		label: 'Professional',
	},
];

const legendNumberList = [
	{
		number: 1,
		label: 'Philips',
	},
	{
		number: 2,
		label: 'Remington',
	},
	{
		number: 3,
		label: 'Braun',
	},
];
const controlStyle = {
	minHeight: 'auto',
	// padding: '0',
	//width: '100px',
	'&:hover': {
		borderColor: 'var(--sapGreen)',
	},
};
const DownIndicatorCustom = ({ ...props }) => {
	return (
		<components.DropdownIndicator {...props}>
			{/* <Icon
				color='var(--sapGreen)'
				className='mb-0 pb-0'
				style={{ fontSize: '14px' }}
			>
				expand_more
			</Icon> */}
			<img src={DropdownGreen} alt='Dropdown Icon' />
		</components.DropdownIndicator>
	);
};

// const GraphContainer = styled.div`
// 		position: relative;
// 	`;

// 	const BarGraph = styled.div`
// 		width: ${({graphWidth}) => graphWidth}px;
// 	`;

// 	const Description = styled.div`
// 		overflow: hidden;
// 		white-space: nowrap;
// 		text-overflow: ellipsis;
// 		max-width: ${({graphWidth}) => `${graphWidth}px`};
// 	`;

function AnalysisBarChart({ questionObject, qid, question, information, handleCopyClick, data, filters, options, questionID }) {
	// const graphR = "";
	// const graphQuestionId = "";

	const { height, width } = useWindowDimensions();
	const barWidth = width > 1500 ? width / 1.3 : width / 1.4;
	// const barWidth = '70%'
	const barHeight = height / 2.4;
	const [barChartData, setBarChartData] = useState([])
	const [graphR,setgraphData] =useState([])
	const [graphQuestionId,setgraphQuestionId] = useState()
	const [stackedBarChartData, setStackedBarChartData] = useState(graphR);
	const [productTypes, setProductTypes] = useState([])
	const [contribution,setContribution] = useState(null)
	const [contributionLabel,setContributionLabel] = useState(null)
	const [graphItem, setGraphItem] = useState(null)
	const [graphItemLabel, setGraphItemLabel] = useState(null)
	const [selectedGenerateSummary, setSelectedGenerateSummary] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	// const [userTypeArray, setUserTypeArray] = useState(false);
	
	const [addReport,setAddReport] = React.useState({
		contribution: null, graphItem: null
	})
	const updateReport = (selection)=>{
		setAddReport(selection)
	}

	const dispatch = useDispatch(); 

	// Sageer's code
	// const [graphWidth, setGraphWidth] = React.useState(0);
	// const handleGraphResize = () =>{
	// 	if(graphRef.current){
	// 		setGraphWidth(graphRef.current.offsetWidth);
	// 	}
	// };

	// React.useEffect(()=>{
	// 	handleGraphResize();
	// 	window.addEventListener('resize',handleGraphResize);

	// 	return() =>{
	// 		window.removeEventListener('resize',handleGraphResize);
	// 	};
	// },[]);

	// const graphRef = React.useRef(null);
	// till here



	// Sageer's code for dropdown values
	useEffect(()=>{
		// let dropdownVal = options
		// .filter(option =>option.groupFilterLable !==null && option.groupFilterLable!==undefined)
		// .map(options =>({
		// 	label: options.groupFilterLable,
		// 	id: options.id
		// }))
		
		let dropdownVal = [
			{ label : 'None', id: null },
			...options
				.filter(option => option.groupFilterLable !== null && option.groupFilterLable !== undefined)
				.map(option => ({
					label: option.groupFilterLable,
					id: option.id
				}))
		];

		setProductTypes(dropdownVal)
		let d = []
		if(data !== undefined && data.length > 0){
		for(let i=0; i < data.length;i++){
			d.push({
				x: data[i].choiceName,
				y: data[i].anscnt
			});
		}
		}
		setBarChartData([...d])
		let t = addReport
		t['data'] = d
		setAddReport(t)
	},[data,options]);

	// useEffect(() => {
	// 	if (filters && filters['UserType']) {
	// 		const userTypeObject = filters['UserType'];
	// 		const userTypeArray = Object.entries(userTypeObject).map(([key, value]) => ({
	// 		  key: Number(key), // Assuming the keys are numbers
	// 		  value: value, // The value is already an array of strings
	// 		}));
	// 		console.log("userTypeArray",userTypeArray);
	// 		setUserTypeArray(userTypeArray);
	// 	}
	// }, [filters]);


	const getData = ()=>{

		// dispatch({
		// 	type: REQUEST_GRAPH_DATA,
		// 	payload:{
		// 		"question_ID":questionID,
		// 		"contribution_ID" : contribution,
		// 		"graph_item_ID": graphItem
		// 	}
		// });
	
	
		  var myHeaders = new Headers();
		  myHeaders.append("Content-Type", "application/json");

		  const userTypeObject = filters['UserType'];
		//   const userTypeArray = Object.entries(userTypeObject).map(([key, value]) => ({key,value}));
		const userTypeArray = Object.entries(userTypeObject).reduce((acc, [key, value]) => {
			acc[Number(key)] = value;
			return acc;
		}, {});
	  
		  var raw = JSON.stringify({
			  "question_ID":questionID,
			  "contribution_ID" : contribution,
		 	  "graph_item_ID": graphItem,
			  "Gender": filters['Gender'],
			  "MaritalStatus": filters['MaritalStatus'],
			  "classificationData": filters['classificationData'],
			  "AgeGroup": filters['AgeGroup'],
			  "Geography": filters['Geography'],
			  //"UserType": filters['UserType'],
			  "UserType": [userTypeArray],
		  });
	  
		  var requestOptions = {
			method: 'POST',
			headers: myHeaders,
			body: raw
		  };


	const xyz = fetch(`https://${process.env.REACT_APP_URL}/getGraphData`, requestOptions)
	.then(function (response) {
	 return response.json()
   })
   .then(function (response) {
	if(response.Response?.graphData){
		
		setgraphData(response.Response?.graphData)
		setgraphQuestionId(response.Response?.question_ID)
		console.log(response.Response?.question_ID)
		console.log("214")
	}
   });
	 ///
	}

	useEffect(()=>{
		if(contribution != null || graphItem != null)
		{
			getData()
		}	
	},[contribution,graphItem,filters])

	useEffect(()=>{
		if( graphQuestionId== questionID)
		{
	
			setStackedBarChartData(graphR);
		}
		if(graphR != null && (contribution != null || graphItem != null)){
			updateReport({contribution: contribution,graphItem:graphItem,data:graphR})
		}
	},[graphR, graphQuestionId])

	

	return (
		<div
			className='pb-4  question'
			style={{ borderBottom: '1px solid var(--greyBlue30)' }}
		>
			<div className='d-flex justify-content-between my-3'>
				<div style={{ width: '60%' }}>
					<Heading
						fontSize='14px'
						fontWeight='700'
						color='var(--deepBlue)'
						className='mb-0 pb-0'
						breakHeading={false}
					>
						{question}
						{information && (
							<span>
								<Heading
									fontSize='12px'
									fontWeight='400'
									color='var(--deepBlue)'
									className='mb-0 pb-0 pl-1'
								>
									{information}
								</Heading>
							</span>
						)}
						
					</Heading>
				</div>

				<div className='d-flex align-items-center'>
					
					<CustomButton
						fontWeight='400'
						fontSize='10px'
						className='ml-2'
						size='small'
						variant='outlined'
						buttonColor='var(--sapGreen)'
						onClick={()=>handleCopyClick(qid, addReport, question, questionObject)}
					>
						
							<img src={copy_active} />
						{' '}
						Copy to Report
					</CustomButton>
				</div>
			</div>
			{isLoading === true ? <div className='pb-2'>
				<Heading
					fontSize='12px'
					fontWeight='500'
					color='var(--deepBlue)'
					className='mb-0 pb-0 mt-4'
				>
					Clips Loading...
				</Heading>
				<img src={loading} className="loading" alt="loading" />
			</div> : ""}
			
			{questionObject.questionType_ID != 11 ? <div className='d-flex align-items-center  py-3'>
				<Heading
					fontSize='14px'
					fontWeight='500'
					color='var(--deepBlue)'
					className='mb-0 pb-0 pr-2'
				>
					Show Contribution:
				</Heading>
				<div >
					<Dropdown
						selectedFontSize='14px'
						selectedFontWeight='400'
						options={productTypes}
						selectedColor='var(--deepBlue)'
						defaultValue={contribution == null ? { value: 1, label: 'None'} : {value: contribution, label: contributionLabel }}
						controlStyle={controlStyle}
						valueContainerStyle={{
							padding: '0',
						}}
						selectedBorderBottom='1px solid var(--sapGreen)'
						indicatorContainer={{
							padding: '0',
						}}
						DropdownIndicator={(props) => (
							<DownIndicatorCustom {...props} />
						)}
						onChange={(ev)=>{
							setContribution(ev.id)
							setContributionLabel(ev.label)
						}}
					/>
				</div>
				
				<div
					className='px-2'
					style={{ cursor: 'pointer' }}
					onClick={() => {
						setContribution(graphItem)
						setContributionLabel(graphItemLabel)
						setGraphItem(contribution)
						setGraphItemLabel(contributionLabel)
					}}
				>
					<Icon
						className='px-3'
						style={{ fontSize: '14px' }}
						color='var(--sapGreen)'
					>
						sync_alt
					</Icon>
				</div>
				<Heading
					fontSize='14px'
					fontWeight='500'
					color='var(--deepBlue)'
					className='mb-0 pb-0 pr-2'
				>
					Show Graph Items:
				</Heading>
				<div >
					<Dropdown
						selectedFontSize='14px'
						selectedFontWeight='400'
						options={productTypes}
						selectedColor='var(--deepBlue)'
						defaultValue={graphItem == null ? { value: 1, label: 'None' } : { value: graphItem, label: graphItemLabel }}
						controlStyle={controlStyle}
						valueContainerStyle={{
							padding: '0',
						}}
						selectedBorderBottom='1px solid var(--sapGreen)'
						DropdownIndicator={(props) => (
							<DownIndicatorCustom {...props} />
						)}
						onChange={(ev)=>{
							setGraphItem(ev.id)
							setGraphItemLabel(ev.label)
						}}
					/>
				</div>
			</div> : ""}
			<div className='mb-3'>
				
				{contribution == null && graphItem == null && (
				
							<BarChart 
							datasets={barChartData}
							width={barWidth}
							height={barHeight}
							top={20}
							bottom={30}
							left={30}
							right={0}
							/>
					
				)}
				
			</div>

			<div
				className='mt-0 mb-5'
				
			>
				{/* <Legend style={{ top: 0 }} list={legendList} /> */}
				{contribution != null && graphItem == null &&
					<VerticalStakedBarChart
						colors={colorData}
						data={stackedBarChartData}
						primary={'cn1'}
						secondary={'cn2'}
						top={20}
						bottom={30}
						left={30}
						right={0}
					/>
				}
				{contribution == null && graphItem != null &&
					<VerticalStakedBarChart
						colors={colorData}
						data={stackedBarChartData}
						primary={'cn1'}
						secondary={'cn3'}
						top={20}
						bottom={30}
						left={30}
						right={0}
					/>
				}
				
			</div>
			{contribution != null && graphItem != null &&
				<div
					className='mb-4 py-3'
					style={{
						height: barHeight,
						width: barWidth,
						position: 'relative',
					}}
				>					
					<GroupedStackedBarChart
						colors={colors}
						width={barWidth}
						height={barHeight}
						data={stackedBarChartData}
						primary={'cn1'}
						secondary={'cn2'}
						tertiary={'cn3'}
						top={20}
						bottom={30}
						left={30}
						right={0}
					/>
				</div>
			}
		</div>
	);
}

export default AnalysisBarChart;